import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Info from '@material-ui/icons/InfoOutlined';


const styles = theme => ({
    container: {
        display: 'block',
        flexWrap: 'wrap',
        arginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: '50px',
        maxWidth: '420px',
    },
    wide: {
        width: '100%',
        maxWidth: '340px',
    },
    narrow: {
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    margin: {
        marginLeft: '4px',
        marginRight: '4px',
        display: 'flex',
    },
    scope: {
        width: '100%',
        maxWidth: '340px',
    },
    margin20: {
        marginBottom: '20px',
    },
    marginTop20: {
        marginTop: '20px',
    },
    togglebutton: {
        backgroundColor: '#346847'
    },
    info: {
        color: '#3cb63c',
        paddingTop: '10px',
        paddingBotton: '10px'
    }
});

const QuickOffer = (props) => {
    const {
        classes,
        user,
        projectId,
        quickOffer,
        history,
        handleChange,
        handleQuickOfferValueChange,
        handleSaveQuickOffer,
        handleCancelQuickOffer,
    } = props;

    let materialOptions = [
        {description: 'I will provider materials', selected: false},
        {description: 'Electrician will provider materials', selected: false},
    ]
    
    return (
        <Container maxWidth="lg" className={classes.container}>
            {quickOffer.update && 
                <div className={classes.info}><Info />This will update the existing offer.</div>
            }
            <div className={clsx(classes.margin20)}>
                <TextField
                    required
                    id="cost"
                    label="cost"
                    className={clsx(classes.wide, classes.margin)}
                    variant="outlined"
                    value={quickOffer.cost}
                    onChange={(event) => handleChange('cost', event)}
                    margin="normal"
                    type="number"
                    helperText={"Define total cost"}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                />
                <div className={clsx(classes.margin20)}>
                    {/* <InputLabel 
                        className={clsx(classes.wide)}
                        htmlFor="select-multiple-chip">Select Scope to apply proposal</InputLabel> */}
                    
                    <div 
                        className={clsx(classes.wide, classes.margin20)}>
                        <ToggleButtonGroup
                            value={quickOffer.material}
                            exclusive
                            // onChange={handleAlignment}
                            onChange={(event, value) => handleQuickOfferValueChange('material', value)}
                            // aria-label="text alignment"
                            >
                            <ToggleButton value={false}>
                                <span>Customer will provide materials</span>
                            </ToggleButton>
                            <ToggleButton value={true}>
                                <span>Electrician will provider materials</span>
                            </ToggleButton>
                            </ToggleButtonGroup>
                    </div>
                </div>
                <div className={clsx(classes.margin20)}>
                    <TextField
                        id="comment"
                        label="Comments"
                        variant="outlined"
                        multiline
                        maxRows="4"
                        minRows="4"
                        value={quickOffer.comment}
                        onChange={(event) => handleChange('comment', event)}
                        className={clsx(classes.wide, classes.margin)}
                        margin="normal"
                        helperText= "Write additional comments"
                    />
                </div>
            <div className={clsx(classes.wide, classes.margin20)}>        
            <Button 
                variant="contained" 
                component="span" 
                onClick={() => handleSaveQuickOffer(history, projectId)}
                className={clsx(classes.wide, 'ee-color', 'flex')}>
                Save
            </Button>
            <Button 
                variant="contained" 
                component="span" 
                onClick={() => handleCancelQuickOffer(history, projectId)}
                className={clsx(classes.wide, 'ee-color', 'flex')}>
                Cancel
            </Button>
            </div>
        </div>
    </Container>
  );
}

export default withStyles(styles)(QuickOffer);