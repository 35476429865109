export function queryToArray(queryStr) {
    const queryArr = queryStr.replace('?','').split('&');
    const queryParams = [];

    for (var q = 0, qArrLength = queryArr.length; q < qArrLength; q++) {
        var qArr = queryArr[q].split('=');
        queryParams[qArr[0]] = qArr[1];
    }

    return queryParams;
};
