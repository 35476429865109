import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { updateInboxTabValue } from '../../actions/view';
import {
    fetchDashboard, fetchAllScopes
} from '../../actions/api';
import UserActionListContainer from './UserActionListContainer';
import UserMessageFromListContainer from './UserMessageFromListContainer';
import UserMessageToListContainer from './UserMessageToListContainer';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.ee.user,
        inbox: state.view.inbox,
        history: ownProps.history,
    }
};

const mapDispatchToProps = dispatch => ({
    getDashboardData: (history) => {
        dispatch(fetchDashboard(history));
    },
    getScopeData: (history) => {
        dispatch(fetchAllScopes(history));
    },
    handleChange: (event, value) => {
        dispatch(updateInboxTabValue(value));
    },
});


class DashboardContainer extends Component {
    componentDidMount() {
        this.props.getDashboardData(this.props.history);
        if (!this.props.scopes || this.props.scopes.length === 0) {
            this.props.getScopeData(this.props.history);
        }
    }

    componentWillReceiveProps() {
        this.props.getDashboardData(this.props.history);
        if (!this.props.scopes || this.props.scopes.length === 0) {
            this.props.getScopeData(this.props.history);
        }
    }

    render() {
        const { inbox, handleChange, classes, history } = this.props;
        const value = inbox.value || 0;
        return (
            <Paper className={classes.root}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="User Action" />
                        <Tab label="Inbox" />
                        <Tab label="Sent" />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <UserActionListContainer history={this.props.history}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <UserMessageFromListContainer history={this.props.history}/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <UserMessageToListContainer history={this.props.history}/>
                </TabPanel>
            </Paper>
        );
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(DashboardContainer));