import { connect } from 'react-redux';
import { updateRegisterValue } from '../../actions/register';
import { fetchSaveRegisterProviderTypeAndAgreement } from '../../actions/api';
import RegisterProvider from '../components/RegisterProvider';

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.ee.user,
        register: state.register,
        history: ownProps.history,
    }
};

const mapDispatchToProps = dispatch => ({
    handleChange: (name, value) => {
        dispatch(updateRegisterValue(name, value));
    },
    handleRegister: (history) => {
        dispatch(fetchSaveRegisterProviderTypeAndAgreement(history));
    },
    handleTermsOfUse: () => {
        dispatch(updateRegisterValue('termsOfUse'));
    },
    handleTermsOfUseProvider: () => {
        dispatch(updateRegisterValue('termsOfUseProvider'));
    },
    handleCodeOfEthics: () => {
        dispatch(updateRegisterValue('codeOfEthics'));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterProvider);
