import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    fetchDashboard, fetchAllScopes
} from '../../actions/api';
import UserActionListContainer from '../containers/UserActionListContainer';
import ProjectListContainer from '../containers/ProjectListContainer';
import ProposalListContainer from '../containers/ProposalListContainer';
import QuickOfferListContainer from '../containers/QuickOfferListContainer';
import UserMessageFromListContainer from '../containers/UserMessageFromListContainer';
import UserMessageToListContainer from '../containers/UserMessageToListContainer';
import ReviewListContainer from '../containers/ReviewListContainer';
import ExpansionPanelComponent from '../components/ExpansionPanelComponent';

//TODO: working proposal list on dashboard
const mapStateToProps = (state, ownProps) => {
    return {
        user: state.ee.user,
        history: ownProps.history,
    }
};

const mapDispatchToProps = dispatch => ({
    getDashboardData: (history) => {
        dispatch(fetchDashboard(history));
    },
    getScopeData: (history) => {
        dispatch(fetchAllScopes(history));
    },
});

class DashboardContainer extends Component {
    componentDidMount() {
        // this.props.getDashboardData(this.props.history);
        if (!this.props.scopes || this.props.scopes.length === 0) {
            this.props.getScopeData(this.props.history);
        }
    }

    componentWillReceiveProps() {
        this.props.getDashboardData(this.props.history);
        if (!this.props.scopes || this.props.scopes.length === 0) {
            this.props.getScopeData(this.props.history);
        }
    }

    render() {
        return (
            <section id="gete-project" className="gete-project">
                {/* <ExpansionPanelComponent title="User Action" details={<UserActionListContainer history={this.props.history}/>} /> */}
                <ExpansionPanelComponent title="Project" details={<ProjectListContainer history={this.props.history}/>} />
                <ExpansionPanelComponent title="Proposal" details={<ProposalListContainer history={this.props.history}/>} />
                {/* <ExpansionPanelComponent title="Quick Offer" details={<QuickOfferListContainer history={this.props.history}/>} /> */}
                <ExpansionPanelComponent title="Reviews" details={<ReviewListContainer history={this.props.history}/>} />
                {/* <ExpansionPanelComponent title="Inbox" details={<UserMessageFromListContainer history={this.props.history}/>} />
                <ExpansionPanelComponent title="Sent" details={<UserMessageToListContainer history={this.props.history}/>} /> */}
            </section>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DashboardContainer);
