import * as types from '../constants/actionTypes';

export const updateEmail = email => ({
    type: types.UPDATE_EMAIL,
    email
});

export const updatePassword = password => ({
    type: types.UPDATE_PASSWORD,
    password
});

export const updateProjectValue = (name, value) => ({
    type: types.UPDATE_PROJECT_VALUE,
    name,
    value,
});

export const selectProposalScopeClick = (id, name) => ({
    type: types.SELECT_PROPOSAL_SCOPE,
    id,
    name,
});

export const updateScopeType = (scopeType) => ({
    type: types.UPDATE_SCOPE_TYPE,
    scopeType,
});

export const updateProposalValue = (name, value) => ({
    type: types.UPDATE_PROPOSAL_VALUE,
    name,
    value,
});

export const updateProposalScopeValue = (name, value) => ({
    type: types.UPDATE_PROPOSAL_SCOPE_VALUE,
    name,
    value,
});

export const addProposalScope = (userRate) => ({
    type: types.ADD_PROPOSAL_SCOPE, userRate
});

export const cancleProposalScope = () => ({
    type: types.CANCLE_PROPOSAL_SCOPE,
});

export const deleteProposalScope = (scopeId) => ({
    type: types.DELETE_PROPOSAL_SCOPE, scopeId,
});

export const updateProposal = () => ({
    type: types.UPDATE_PROPOSAL,
});

export const prepareEditScopeDetail = (scopeId) => ({
    type: types.PREPARE_EDIT_SCOPE_DETAIL,
    scopeId,
});

export const updateUserType = (userType) => ({
    type: types.UPDATE_USER_TYPE,
    userType,
})

export const updateReviewValue = (name, value) => ({
    type: types.UPDATE_REVIEW_VALUE,
    name,
    value,
});

export const updateProjectIdProviderId = (projectId, providerId) => ({
    type: types.UPDATE_REVIEW_PROJECTID_PROVIDERID,
    projectId,
    providerId,
});

export const selectScope = (scopeDetail) => {
    return {
        type: types.SELECT_SCOPE,
        scopeDetail,
    }
}

export const addCustomScope = () =>{
    return {
        type: types.ADD_CUSTOM_SCOPE,
    }
}

export const updateQuickOfferValue = (name, value) => ({
    type: types.UPDATE_QUICK_OFFER_VALUE,
    name,
    value,
});
