import { connect } from 'react-redux';
import {
    updateResetEmail,
} from '../../actions/view';
import { fetchResetPasswordRequest } from '../../actions/api';
import ForgotPassword from '../components/ForgotPassword';

const mapStateToProps = (state, ownProps) => {
    return {
        resetEmail: state.view.resetEmail,
        loginLinkEnabled: state.view.loginLinkEnabled,
        user: state.ee.user,
        history: ownProps.history,
    }
};

const mapDispatchToProps = dispatch => ({
    handleResetEmailChange: (event) => {
        dispatch(updateResetEmail(event.target.value));
    },
    onResetClick: (event, history) => {
        dispatch(fetchResetPasswordRequest(history));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPassword);
