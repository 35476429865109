import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%',
        overflowX: 'auto',
        // maxWidth: '425px',
        // paddingBottom: '30px'
        color: 'white',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#468847',
        borderColor: '#0063cc',
        width: '180px',
        marginRight: '16px',
    },
    formBox: {
        padding: '0px 20px 10px 20px',
    },
    wide: {
        width: '100%',
        maxWidth: '340px'
    },
    narrow: {
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    margin: {
        marginTop: '20px',
        marginBottom: '10px',
        marginRight: '4px',
        display: 'flex',
    },
    margin20: {
        marginBottom: '20px',
    },
    smallText: {
        fontSize: '12px',
        lineHeight: '1.6',
    },
    mainText: {
        fontFamily: "'Noto Sans', sans-serif",
        fontWeight: 500,
        width: '1110px',
     }
});

const mapStateToProps = (state, ownProps) => {
    return {
        user : state.ee.user,
        history: ownProps.history,
    }
};

const mapDispatchToProps = dispatch => ({
    pushHome: (history) => {
        history.push('/');
    },
});

class HomeContainer extends Component {
    componentDidUpdate() {
        const { 
            user,
            pushHome,
            history,
            classes,
        } = this.props;
    }

    render() {
        const { user, history, classes } = this.props;
        const showGetStarted = user.userType == 's' ? true : false;
        return (
            <div>
                <div className="main-body">
                    <div className={classes.mainText}>
                        <div>Your  </div>
                        <div>Electrical Services </div>
                        <div>Marketplace ™  </div>
                        <div>
                        {showGetStarted && <Button
                            href="/register/new" 
                            classes={{
                                root: classes.root, // class name, e.g. `classes-nesting-root-x`
                                label: classes.label, // class name, e.g. `classes-nesting-label-x`
                            }}
                        >
                        Get started 
                        </Button>}
                        <Button
                            href="/howitworks" 
                            classes={{
                                root: classes.root, // class name, e.g. `classes-nesting-root-x`
                                label: classes.label, // class name, e.g. `classes-nesting-label-x`
                            }}
                        >
                        How it works 
                        </Button>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <p className="display-4">Get started with getElectrician</p>
                    <p className="display-5">At GetElectrician.com our goal is to help both customers find electrical providers and electrical providers find customers for all their electrical needs. 
To learn more how to navigate our services, </p>
                    <p><a className="btn btn-lg" href="/howitworks" role="button">Learn more »</a></p>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(HomeContainer));
