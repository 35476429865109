import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { updateNotification, updateMessageDialog } from '../actions/view';

const mapStateToProps = (state, ownProps) => ({
    user : state.ee.user,
    notification: state.view.notification,
    history: ownProps.history,
});

const mapDispatchToProps = dispatch => ({
    display: (text, type) => {
        if (type === 'error') {
            toast.error(text);
        } else if (type === 'info') {
            toast.info(text);
        } else {
            toast(text);
        }
    },
    // updateNotification: (display) => {
    //     dispatch(updateNotification({
    //         display: true, 
    //         text: 'TEST',
    //         type: toast.TYPE.SUCCESS
    //     }));
    // },
    // idleNotification: (display) => {
    //     dispatch(updateNotification({
    //         display: false, 
    //         text: '',
    //         type: toast.TYPE.INFO
    //     }));
    // },
    // test: (replyDisplay, replyId) => {
    //     const message = {
    //         open: true,
    //         replyDisplay,
    //         replyId,
    //     }
    //     dispatch(updateMessageDialog(message));
    // },
});

class NotificationContainer extends Component {
    render() {
        const { 
            notification, 
            display, 
            // updateNotification, 
            // idleNotification, 
            // test,
        } = this.props;

        if (notification.display) {
            display(notification.text, notification.type);
        }
        return (
            <div>
                <ToastContainer enableMultiContainer position={toast.POSITION.TOP_CENTER} />
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NotificationContainer);
