import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import EmailField from '../../components/EmailField';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%'
    },
    wide: {
        width: '100%',
        maxWidth: '340px'
    },
    margin: {
        margin: '0 auto',
    },
    smallFont: {
        fontSize: '16px',
    },
    marginTop16: {
        marginTop: '16px',
    }
});

const ForgotPassword = (props) => {
    const {
        classes,
        user,
        history,
        resetEmail,
        loginLinkEnabled,
        handleResetEmailChange,
        onResetClick,
    } = props;

    return (
        <Container maxWidth="lg">
            <div className={clsx(classes.roots, "flex")}>
                <form className="form-signin" name="resetPassword" role="form">
                <h2 className="form-signin-heading">Reset Password<span className={clsx(classes.smallFont, "ee-color")}>GetElectrician</span></h2>
                <EmailField
                    id="outlined-email-input"
                    label="Email"
                    name="email"
                    email={resetEmail || ''}
                    onChange={handleResetEmailChange}
                    required={true}
                />
                <div>
                    <div className={clsx(classes.wide, classes.margin20)}>
                        <Button 
                            disabled={!resetEmail}
                            variant="contained" 
                            component="span" 
                            onClick={() => onResetClick(history)}
                            className={resetEmail ? clsx(classes.wide, 'ee-bg-color', 'flex') : clsx(classes.wide, 'flex')}
                        >
                            Reset
                        </Button>
                    </div>
                    <div className={clsx(classes.wide, classes.margin20, classes.marginTop16)}>
                        <a className="btn btn-default btn-secondary-ee" href="login" role="button">Cancel</a>
                    </div>
                    {loginLinkEnabled && <div className={clsx(classes.wide, classes.margin20)}>
                        <Link 
                            variant="contained" 
                            component="span" 
                            href="login"
                            // onClick={() => onResetClick(history)}
                            className={clsx(classes.wide, 'flex')}>
                            Check your email to reset password. Go to 
                            <span className={clsx('ee-color')}>&nbsp; LOGIN &nbsp;</span>page!
                        </Link>
                    </div>}
                </div>
                </form>
            </div>
        </Container>
    );
};

export default withStyles(styles)(ForgotPassword);
