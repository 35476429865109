import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updatePasswordValue, updateTokenEmail } from '../../actions/setting';
import { fetchResetPassword, fetchVerify } from '../../actions/api';
import ResetPassword from '../components/ResetPassword';
import { queryToArray } from '../../util/urlUtil';

const mapStateToProps = (state, ownProps) => {
    const token = (ownProps.match.params.token !== undefined) ? ownProps.match.params.token : '';
    return {
        token: token,
        user: state.ee.user,
        password: state.setting.password,
        history: ownProps.history,
    }
};

const mapDispatchToProps = dispatch => ({
    handleChange: (name, value) => {
        dispatch(updatePasswordValue(name, value));
    },
    handleUpdatePassword: (history) => {
        dispatch(fetchResetPassword(history));
    },
    verify: (token, queryStr) => {
        dispatch(fetchVerify(token, queryStr));
    },
    setTokenEmail: (token, queryStr) => {
        dispatch(updateTokenEmail(token, queryStr));
    }
});

class ResetPasswordContainer extends Component {
    componentDidMount() {
        const queryStr = this.props.location.search;
        const token = this.props.token;
        const queryParams = queryToArray(queryStr)
        if (queryParams.c && token) {
            this.props.verify(token, queryStr);
        }

        this.props.setTokenEmail(token, queryStr);
    }

    // componentWillReceiveProps(newProps) {
    // }

    render() {
        return (
            <section id="gete-project" className="gete-project">
                <ResetPassword
                    password={this.props.password} 
                    user={this.props.user}
                    history={this.props.history} 
                    handleChange={this.props.handleChange}
                    handleUpdatePassword={this.props.handleUpdatePassword}
                />
            </section>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPasswordContainer);

