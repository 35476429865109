import React, { Component } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

const mapStateToProps = (state, ownProps) => ({
    user : state.ee.user,
    history: ownProps.history,
    title: ownProps.title,
});

const mapDispatchToProps = dispatch => ({
    toHome: (history) => {
        history.push('/home')
    },
});

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '0px',
        background: '#3cb63c',
        // borderBottom: '1px solid rgb(218, 222, 223)',
        paddingLeft: '10%',
        paddingRight: '10%',
        paddingTop: '15px',
        paddingBottom: '15px',
        backgroundColor: '#f2f2f2',
        marginLeft: '-22px',
        marginRight: '-22px',
        marginBottom: '20px',
        justifyContent: 'space-between',
    },
    title: {
        fontSize: '28px',
        fontWeight: '500',
    },
    breadcrumb: {
        display: 'flex',
        color: '#3cb63c',
        height: '50px',
        lineHeight: '50px',
    },
    eeColor: {
        color: '#01DF01',
    },
    guideText: {
        fontWeight: 'bold',
    },
    wide: {
        width: '100%',
        maxWidth: '340px'
    },
});


class TitleContainer extends Component {
    render() {
        const { user, history, title, classes, toHome } = this.props;
        if(title) {
            return (
                <div className={clsx(classes.root)} >
                    <div className={clsx(classes.title, 'flex')} >
                        {title}
                    </div>
                    <div className={clsx(classes.breadcrumb)} onClick={() => {toHome(history)}}>
                        home
                    </div>
                </div>
            )
        }
        return '';
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(TitleContainer));

