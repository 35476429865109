import * as types from '../constants/actionTypes';
import { push } from 'react-router-redux'
import moment from 'moment';

export const SIMPLE_DAY_DATE_FORMAT_REGISTER = 'YYYY-MM-DD';

const initUser = { 
    userType: 's',
    userDisplayName: 'Stranger',
    u: '',
    verified: false,
    step: 0,
};

export const initialState = () => ({
    user: initUser,
    scopes: [],
    dashboard: {
        projects: [],
        proposals: [],
        userMessages: [],
        userMessagesTo: [],
        userActions: [],
        reviews: [],
        offers: []
    },
    project: {
        title: 'new project',
        blocked: false,
        budgetMax: 3000,
        budgetMin: 200,
        emergencyCheck: false,
        owner: 43,
        status: "DRAFT",
        supportingInfo: "",
        zipcode: "10001",
        scopes: [],
        proposals: [],
        offers: [],
        reviews: [],
        emergency: false,
        company: false,
        insurance: false,
        license: false,
        startTime: '06:00',
        endTime: '17:00',
        startDate: moment().format(SIMPLE_DAY_DATE_FORMAT_REGISTER),
        endBiddingDate: moment().format(SIMPLE_DAY_DATE_FORMAT_REGISTER),
        customScope: 'Custom Scope',
        providerList: [],
    },
    review: {
        completed: true,
        recommend: true,
        status: 'completed',
        quality: 1,
        responsiveness: 1,
        professionalism: 1,
        subjectMatterExpertise: 1,
        adherenceToCost: 1,
        adherenceToSchedule: 1,
        projectId: 1,
        providerId: 1,
    },
    proposal: {},
    quickOffer: {
        update: false,
    },
});

export default function ee(prevState = initialState(), action) {
    const state = {
        ...prevState,
    };

    switch (action.type) {
        case types.LOAD_USER:
            const userId = (action.user && action.user.u) ? action.user.u.split('.')[0] : undefined;
            if (action.user.step) {
                const user = {
                    ...action.user,
                    step: Number(action.user.step),
                    id: userId,
                }
                return {
                    ...state,
                    user: user
                };    
            }
            return {
                ...state,
                user: action.user,
                id: userId,
            };
        case types.LOGOUT:
            return {
                ...state,
                user: initUser,
            };
        case types.RECEIVED_SCOPES:
            return {
                ...state,
                scopes: action.scopes,
            }
        case types.RECEIVED_DASHBOARD:
            return {
                ...state,
                dashboard: action.dashboard, 
            }
        case types.SELECT_SAVED:
            return {
                ...state,
                project: {
                    ...state.project,
                    ...action.projectResponse.project,
                    proposals: action.projectResponse.proposals,
                }
            }
        case types.RECEIVED_PROJECT:
            return {
                ...state,
                project: {
                    ...action.projectResponse.project,
                    proposals: action.projectResponse.proposals,
                    reviews: action.projectResponse.reviews,
                    offers: action.projectResponse.offers,
                    providerList: action.projectResponse.providerList,
                }
            }
        case types.RECEIVED_PROJECT_PROJECT:
            const newProject = {
                ...action.project,
                proposals: state.project.proposals,
                reviews: state.project.reviews,
            }
            return {
                ...state,
                project: newProject,
            }
        case types.RECEIVED_PROPOSAL:
            const projectProposals = state.project.proposals;
            return {
                ...state,
                project: {
                    ...action.proposalResponse.project,
                    proposals: state.project.proposals,
                },
                proposal: action.proposalResponse.proposal,
            }
        case types.RECEIVED_QUICKOFFER:
            return {
                ...state,
                quickOffer: {
                    ...action.response,
                    update: true,
                }
            }
        case types.UPDATE_PROJECT_VALUE:
            if(action.name === 'emergencyCheck') {
                return {
                    ...state,
                    project: {
                        ...state.project,
                        [action.name]: !state.project.emergencyCheck,
                    }
                }                
            }
            if(action.name === 'company') {
                return {
                    ...state,
                    project: {
                        ...state.project,
                        [action.name]: !state.project.company,
                    }
                }                
            }
            if(action.name === 'insurance') {
                return {
                    ...state,
                    project: {
                        ...state.project,
                        [action.name]: !state.project.insurance,
                    }
                }                
            }
            if(action.name === 'license') {
                return {
                    ...state,
                    project: {
                        ...state.project,
                        [action.name]: !state.project.license,
                    }
                }
            }
            if (action.name === 'custom_scope') {
                return {
                    ...state,
                    project: {
                        ...state.project,
                        customScope: action.value,
                    }
                }    
            }
            return {
                ...state,
                project: {
                    ...state.project,
                    [action.name]: action.value,
                }
            }
        case types.ADD_CUSTOM_SCOPE:
            let scope = state.project.scopes;
            scope.push({
                'name': state.project.customScope,  
                'scopeId' : 58,
                'type': 'custom'
            }) 
            return {
                ...state,
                project: {
                    ...state.project,
                    scope: scope,
                    customScope: undefined,
                }
            }    
        case types.UPDATE_REVIEW_VALUE:
            return {
                ...state,
                review: {
                    ...state.review,
                    [action.name]: action.value,
                }
            }
        case types.UPDATE_REVIEW_PROJECTID_PROVIDERID:
            return {
                ...state,
                review: {
                    ...state.review,
                    projectId: action.projectId,
                    providerId: action.providerId,
                }
            }    
        case types.UPDATE_USER_TYPE:
            return {
                ...state,
                user: {
                    ...state.user,
                    userType: action.userType,
                }
            }
        case types.SELECT_SCOPE:
            const scopeDetail = action.scopeDetail;
            const scopes = state.project.scopes.map(scope => {
                if(scope.scopeId === scopeDetail.scopeId) {
                    scope.providerId = scopeDetail.providerId;
                    scope.proposalId = scopeDetail.proposalId;
                    scope.providerDisplayName = scopeDetail.providerDisplayName;  
                };
                return scope;
            })
            return {
                ...state,
                project: {
                    ...state.project,
                    scopes: scopes,
                }
            }
        case types.UPDATE_QUICK_OFFER_VALUE:
            return {
                ...state,
                quickOffer: {
                    ...state.quickOffer,
                    [action.name]: action.value,
                }
            }
        default:
            return {
                ...state
            };
    }
}
