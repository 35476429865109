export const PRIVACY_NOTICE_FOR_CALIFORNIA_RESIDENTS=`
    <h3>GetElectrician.com Privacy Notice for California Residents</h3>

    <h4>Last Updated: July 23, 2020</h4>

    <div>This Privacy Notice for California Residents of Xavior, Inc. (“GetElectrician”) supplements the information contained in GetElectrician's general Privacy Notice and applies solely to all visitors, users, and others who reside in the State of California (“consumers” or “you”). Any terms defined in the California Consumer Privacy Act of 2018 (“CCPA”) have the same meaning when used in this notice.</div>

    <h4>Information We Collect</h4>

    <div>This Privacy Policy describes the collection, use and disclosure of information by Xavior Inc, (“Getelectrician”, “we,” “us”) and other parties with respect to your use of all properties (Web sites, telephone service or otherwise) owned or operated by Xavior Inc and our affiliates (collectively, the "Properties"), and all products and services available from Getelectrician, providers of electrical-related services (“Service Providers”) or other third parties in connection with the Properties (individually and collectively with the Properties, the “Services”). By accessing or using the Services, you are accepting the practices described in this Privacy Policy. If you do not agree to the terms of this Privacy Policy, please do not use the Services.</div>

    <h4>Privacy Policy Updates</h4>

    <div>GetElectrician has collected the following categories of consumer personal information within the last twelve (12) months:</div>

    <div>Categories and Examples: </div>

    <ul>
        <li>A. Identifiers (A real name, postal address, unique personal identifier, Internet Protocol address, email address, phone number, date of birth, driver's license number, or other similar identifiers.)</li>
        <li>B. Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).(A name, address, telephone number, driver's license or state identification card number, insurance policy number, education, employment, employment history, or any other financial information.
        Some personal information included in this category may overlap with other categories.)</li>
        <li>C. Internet or other similar network activity. (Search history, information on a consumer's interaction with a website, application, or advertisement while using or interacting with Getelectrician’s websites and applications.)</li>
        <li>D. Geolocation data. (Physical location or movements.)</li>
        <li>E. Professional or employment-related information. (Current or past job history or performance ratings.)</li>
        <li>F. Inferences drawn from other personal information. (Profile reflecting a person's preferences, characteristics, predispositions, and behavior.)</li>
    </ul>


    <div>Personal information does not include:</div>

    <ul>
        <li>Publicly available information from government records.</li>
        <li>Deidentified or aggregated consumer information.</li>
        <li>Information excluded from the CCPA's scope, like:
            <ul>
                <li>health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data;</li>
                <li>personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994.</li>
            </ul>
        </li>
    </ul>

    <div>Personal information does not include:</div>
    <ul>
        <li>Directly from you. For example, from forms you complete or products and services you request or purchase.</li>
        <li>Indirectly from you. For example, from observing your actions on our Website and through cookies and other similar technology.</li>
        <li>From other businesses that Getelectricians works with to provide consumers services, including wholly-owned subsidiaries.</li>
        <li>From social networks.</li>
        <li>From sources generally available to the public, such as websites.</li>
    </ul>

    <h4>Use of Personal Information</h4>
    <div>We may use, disclose, or share the personal information we collect. Getelectrician works with service providers, other businesses, and third parties to serve our customers. In addition to disclosing personal information with our service providers, we may share personal information that we collect with other businesses or third parties upon your consent or request for information, products, or services offered by the other business or third party. Because the CCPA defines the sale of personal information in very broad terms and given the complexities and ambiguities of the CCPA in the absence of guidance or final implementing regulations, such sharing of personal information may be considered a “sale” under the CCPA even if no money is exchanged. Getelectrician also allows tracking technology such as cookies and pixels on our site, which allows other companies to receive data about your activity on Getelectrician that is associated with your browser or device. The other companies may use that data to serve you more relevant ads on our site and others. Sharing data for online advertising like this may also be considered a “sale” under the CCPA. We may use, disclose, or share personal information for one or more of the following business purposes:</div>
    <ul>
        <li>To fulfill or meet the reason you provided the information. For example, if you share your name and contact information to request a price quote or ask a question about our services, we will use that personal information to respond to your inquiry and, in the case of service requests, to connect you with service providers and other companies that receive or help us meet service requests. If you provide your personal information to purchase a product or service, we will use that information to process your payment and facilitate fulfillment. We may also save your information to facilitate new service requests or purchases.</li>
        <li>To provide, improve, support, personalize, and develop our website and services.</li>
        <li>To create, maintain, customize, and secure your account with us.</li>
        <li>To process your requests, purchases, transactions, and payments and prevent transactional fraud.</li>
        <li>To provide you with support, to respond to your inquiries, and to otherwise communicate with you and manage the customer relationships, including to investigate and address your concerns and monitor and improve our responses.</li>
        <li>To personalize your Website experience and to deliver content and product and service offerings relevant to your interests.</li>
        <li>To administer contests, promotions or sweepstakes.</li>
        <li>To help maintain the safety, security, and integrity of our website and services, databases and other technology assets, and business.</li>
        <li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
        <li>To detect, investigate and prevent fraud and abuse and activities that may be illegal or violate our policies, and to otherwise protect our users, service providers, and business.</li>
        <li>As described to you when collecting your personal information or as otherwise set forth in the CCPA.</li>
    </ul>
    <div>Getelectrician.com will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.</div>
    

    <h4>Your Rights and Choices</h4>
    <div>This section describes consumer CCPA rights and explains how to exercise those rights.</div>

    <div><b>Access to Specific Information and Data Portability Rights</b></div>
    <div>Consumers have the right to request that Getelectrician disclose certain information to you about our collection and use of your personal information over the past 12 months.</div>
    
    <div><b>Deletion Request Rights</b></div>
    <div>Consumers have the right to request that Getelectrician delete any of your personal information that we collected from you and retained, subject to certain exceptions.</div>

    <div><b>Exercising Rights</b></div>

    <div>To exercise the access, data portability, and deletion rights described above, please submit a verifiable consumer request to us by email to info@getelectrician.com</div>
    <div>Only you, or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of your minor child.</div>
    <div>We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you.</div>
    <div>We will only use personal information provided in a verifiable consumer request to verify the requestor's identity or authority to make the request.</div>
    <h4>Non-Descrimination</h4>
    <div>We will not discriminate against you for exercising any of your CCPA rights.</div>

    <h4>Changes to Our Privacy Notice</h4>
    <div>Getelectrician reserves the right to amend this privacy notice at our discretion and at any time. When we make changes to this privacy notice, we will post the updated notice on the Website and update the notice's effective date. <b>Your continued use of our Website following the posting of changes constitutes your acceptance of such changes</b></div>
 `;