import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%',
        overflowX: 'auto',
        padding: '0',
    },
    formBox: {
        padding: '0px 20px 10px 20px',
    },
    wide: {
        width: '100%',
        maxWidth: '340px',
    },
    narrow: {
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    margin: {
        marginLeft: '4px',
        marginRight: '4px',
    },
    margin20: {
        marginBottom: '20px',
    },
    zIndex0: {
        zIndex: 0,
    },
    subLabel: {
        fontWeight: 600, 
    },
});

const SaveLicense = (props) => {
    const {
        classes,
        user,
        register,
        history,
        isUpdate,
        handleChange,
        handleSaveLicense,
        handleCancelhandleSaveLicense
    } = props;

    const registerError = register.error;
    const license = register.license;
    const formattedIssuedAt = moment(license.issuedAt).format('YYYY-MM-DD');
    const submitDisabled = license.name == undefined || !formattedIssuedAt == undefined || !license.no == undefined || !license.issuedBy == undefined;
    const defaultExpectStartDate = moment().format("YYYY-MM-DD")

    return (
        <Container maxWidth="lg">
            <div className={classes.root} >
                <InputLabel 
                    htmlFor="phone"
                    className={clsx(classes.subLabel, 'ee-color')}
                >License</InputLabel>
                <div className={clsx(classes.margin20, classes.zIndex0)}>
                    <TextField
                        required
                        id="name"
                        label="License name"
                        value={license.name}
                        onChange={(event) => handleChange('name', event.target.value)}
                        className={clsx(classes.wide, classes.margin, classes.zIndex0)}
                        margin="normal"
                        variant="outlined"
                    />
                </div>
                <div className={clsx(classes.margin20, classes.zIndex0)}>
                    <TextField
                        required
                        id="no"
                        label="License Number"
                        value={license.no}
                        onChange={(event) => handleChange('no', event.target.value)}
                        className={clsx(classes.wide, classes.margin, classes.zIndex0)}
                        margin="normal"
                        variant="outlined"
                    />
                </div>
                <div className={clsx(classes.margin20, classes.zIndex0)}>
                    <TextField
                        required
                        id="issuedBy"
                        label="Issue By(State)"
                        value={license.issuedBy}
                        onChange={(event) => handleChange('issuedBy', event.target.value)}
                        className={clsx(classes.wide, classes.margin, classes.zIndex0)}
                        margin="normal"
                        variant="outlined"
                    />
                </div>
                <div className={clsx(classes.margin20)}>
                    <TextField
                        required
                        id="issuedAt"
                        label="Date Issued"
                        type="date"
                        variant="outlined"
                        value={formattedIssuedAt}
                        defaultValue="2017-05-24"
                        onChange={(event) => handleChange('issuedAt', event.target.value)}
                        className={clsx(classes.margin, classes.zIndex0)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                {isUpdate && 
                    <div className={clsx(classes.wide, classes.margin20)}>      
                        <Button
                            disabled={submitDisabled}
                            variant="contained" 
                            component="span"
                            onClick={() => handleSaveLicense(history)}
                            className={!submitDisabled ? clsx(classes.wide, 'ee-bg-color', 'flex') : clsx(classes.wide, 'flex')}
                        >
                            Save license
                        </Button>
                    </div>
                }
            </div>
        </Container>
    );
};

export default withStyles(styles)(SaveLicense);

