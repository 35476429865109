import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuickOffer from '../components/QuickOffer';
import { selectProposalScopeClick, 
        updateProposalValue, 
        updateQuickOfferValue,
 } from '../../actions/ee';
import { fetchGetQuickOffer, fetchSaveQuickOffer, fetchAllScopes } from '../../actions/api';

const mapStateToProps = (state, ownProps) => {
    const projectId = (ownProps.match.params.projectId !== undefined) ? ownProps.match.params.projectId : null;
    return {
        user: state.ee.user,
        projectId,
        project: state.ee.project,
        quickOffer: state.ee.quickOffer,
        history: ownProps.history,
    }
};

const mapDispatchToProps = dispatch => ({
    getQuickOfferByProjectId: (projectId) => {
        dispatch(fetchGetQuickOffer(projectId));
    },
    getScopeData: (history) => {
        dispatch(fetchAllScopes(history));
    },
    handleChange: (name, event) => {
        dispatch(updateQuickOfferValue(
            name, 
            event.target.value
        ));
    },
    handleQuickOfferValueChange: (name, value) => {
        dispatch(updateQuickOfferValue(
            name,
            value 
            // event.target.value
        ));
    },
    handleSaveQuickOffer: (history, projectId) => {
        dispatch(fetchSaveQuickOffer(history, projectId));
    },
    handleCancelQuickOffer: (history, projectId) => {
        history.push('/project/' + projectId);
    },
    onSaveClick: (history) => {
        dispatch(fetchSaveQuickOffer(history));
    }
});

class EditProposalContainer extends Component {
    componentDidMount() {
        this.props.getQuickOfferByProjectId(this.props.projectId);
    }

    componentDidUpdate(newProps) {
        if (newProps.projectId !== this.props.projectId) {
            this.props.getQuickOfferByProjectId(newProps.projectId);
        }
    }

    render() {
        return (
            <section id="gete-project" className="gete-project">
                <QuickOffer
                    projectId={this.props.projectId}
                    project={this.props.project}
                    quickOffer={this.props.quickOffer}
                    scopes={this.props.scopes}
                    user={this.props.user}
                    history={this.props.history} 
                    handleChange={this.props.handleChange}
                    handleQuickOfferValueChange={this.props.handleQuickOfferValueChange}
                    handleSaveQuickOffer={this.props.handleSaveQuickOffer}
                    handleCancelQuickOffer={this.props.handleCancelQuickOffer}
                    onSaveClick={this.props.onSaveClick}
                />
            </section>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditProposalContainer);
