import { connect } from 'react-redux';
import Contact from '../components/Contact';
import { updateContact } from '../../actions/view';
import { fetchContactSendRequest } from '../../actions/api';

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.ee.user,
        contact: state.view.contact,
        history: ownProps.history,
    }
};

const mapDispatchToProps = dispatch => ({
    handleChange: (name, event) => {
        dispatch(updateContact(
            name, 
            event.target.value
        ));
    },
    onSendRequestClick: (history) => {
        dispatch(fetchContactSendRequest(history));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Contact);
