import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { updateMessageDialog, closeMessaegDialog, updateMessageDialogText, disableMessageSubmit } from '../../actions/view';
import { fetchSendMessage } from '../../actions/api';

const mapStateToProps = (state, ownProps) => ({
    user : state.ee.user,
    message: state.view.message,
    history: ownProps.history,
});

const mapDispatchToProps = dispatch => ({
    handleMessageChange: (text) => {
        dispatch(updateMessageDialogText(text))
    },
    handleSubmit: (history) => {
        dispatch(disableMessageSubmit());
        dispatch(fetchSendMessage(history));
    },
    handleClose: () => {
        dispatch(closeMessaegDialog());
    }
});

class MessageDialogContainer extends Component {
    render() {
        const { 
            message, 
            history, 
            handleClose, 
            handleMessageChange, 
            handleSubmit, 
        } = this.props;

        const { submitDisabled } = message;
        return (
            <div>
                <Dialog
                    open={message.open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                >
                <DialogTitle id="form-dialog-title">Send message</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Send message
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="to"
                        label="To"
                        type="text"
                        value={message.replyDisplay}
                        fullWidth
                        disabled
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="message"
                        label="Message"
                        type="textarea"
                        fullWidth
                        onChange={(event) => {handleMessageChange(event.target.value)}}
                        value={message.messageText}
                    />
                </DialogContent>
                <DialogActions>
                    <Button 
                        disabled={submitDisabled}
                        onClick={handleClose} color="primary">Cancel</Button>
                    <Button 
                        disabled={submitDisabled}
                        onClick={() => handleSubmit(history)
                    } color="primary">Send</Button>
                </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MessageDialogContainer);
