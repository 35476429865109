import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProjectDetail from '../components/ProjectDetail';
import ProposalListContainer from './ProposalListContainer';
import ReviewListContainer from '../../review/containers/ReviewListContainer';
import { getProject, getProjectStatus, getCompleted } from '../../selectors/ee';
import { fetchGetProject, fetchCloseProject, fetchRemoveProject, fetchRemoveProposal, fetchAllScopes, fetchSaveSelectScopes } from '../../actions/api';
import Container from '@material-ui/core/Container';
import { dialogOpen, dialogClose, updateMessageDialog } from '../../actions/view';
import QuickOfferListContainer from '../containers/QuickOfferListContainer';
import ProviderListContainer from './ProviderListContainer';

const mapStateToProps = (state, ownProps) => {
    const projectId = (ownProps.match.params.projectId !== undefined) ? ownProps.match.params.projectId : null;
    return {
        user: state.ee.user,
        projectId,
        project: getProject(state),
        hasReview: state.ee.project.reviews ? true : false,
        history: ownProps.history,
        isSelect: getProjectStatus(state) === 'SELECTING',
        dialogOpen: state.view.dialogOpen,
        showCompleted: getCompleted(state),
    }
};

const mapDispatchToProps = dispatch => ({
    getProjectData: (projectId) => {
        dispatch(fetchGetProject(projectId));
    },
    getScopeData: (history) => {
        dispatch(fetchAllScopes(history));
    },
    handleEditProjectClick: (projectId) => {
        dispatch(fetchCloseProject(projectId));
    },
    handleCloseProjectClick: (projectId) => {
        dispatch(fetchCloseProject(projectId));
    },
    handleReopenProjectClick: (projectId, history) => {
        dispatch(fetchCloseProject(projectId, history));
    },
    handleProjectDeleteClick: (projectId, history) => {
        dispatch(fetchRemoveProject(projectId, history));
    },
    handleProposalDeleteClick: (projectId, history) => {
        dispatch(fetchRemoveProposal(projectId, history));
    },
    handleScopeSelectSave: (projectId) => {
        dispatch(fetchSaveSelectScopes(projectId));
    },
    handleDialogOpen: () => {
        dispatch(dialogOpen());
    },
    handleDialogClose: () => {
        dispatch(dialogClose());
    },
    
    handleClickMessageDialogOpen: (replyDisplay, replyId, projectId) => {
        const message = {
            open: true,
            replyDisplay,
            replyId,
            projectId,
        }
        dispatch(updateMessageDialog(message));
    },
});

class ProjectDetailContainer extends Component {
    componentDidMount() {
        this.props.getProjectData(this.props.projectId);
        if (!this.props.scopes || this.props.scopes.length === 0) {
            this.props.getScopeData(this.props.history);
        }
    }

    componentDidUpdate(newProps) {
        if (newProps.projectId !== this.props.projectId) {
            this.props.getProjectData(newProps.projectId);
        }
        if (!this.props.scopes || this.props.scopes.length === 0) {
            this.props.getScopeData(this.props.history);
        }
    }

    render() {
        return (
            <section id="gete-project" className="gete-project">
                <Container maxWidth="md">    
                    <ProjectDetail 
                        project={this.props.project} 
                        projectScopes={this.props.projectScopes}
                        user={this.props.user}
                        history={this.props.history} 
                        isSelect={this.props.isSelect}
                        dialogOpen={this.props.dialogOpen}
                        handleEditProjectClick={this.props.handleEditProjectClick}
                        handleCloseProjectClick={this.props.handleCloseProjectClick}
                        handleReopenProjectClick={this.props.handleReopenProjectClick}
                        handleProjectDeleteClick={this.props.handleProjectDeleteClick}
                        handleProposalDeleteClick={this.props.handleProposalDeleteClick}
                        handleScopeSelectSave={this.props.handleScopeSelectSave}
                        handleDialogOpen={this.props.handleDialogOpen}
                        handleDialogClose={this.props.handleDialogClose}
                        handleClickMessageDialogOpen={this.props.handleClickMessageDialogOpen}
                    />
                    {!this.props.user.userType.includes('p') && <ProviderListContainer />}
                    <ProposalListContainer history={this.props.history} />
                    {/* <QuickOfferListContainer /> */}
                    {this.props.hasReview &&                     
                        <ReviewListContainer />
                    }
                </Container>
            </section>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectDetailContainer);
