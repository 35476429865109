import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        paddingLeft:"10pt",
        paddingRight:"10pt",
        fontWeight: '400',
    },
    title: {
        paddingTop: '10px',
        paddingBottom: '10px',
        fontSize: '20px',
        fontWeight: '600',
    }
});

const Agreement = (props) => {
    const {
        classes,
        checked,
        title,
        handleCheckbox,
        content,
    } = props;

    const error = !checked;
    return (
        <Paper className={classes.root}>
            <FormControl required error={error} component="fieldset" className={classes.formControl}>
                <FormControlLabel
                    control={<Checkbox checked={checked} onChange={handleCheckbox} value="gilad" color="default"/>}
                    label={title}
                    labelPlacement='start'
                    value="title"
                />
                <FormHelperText>You need to agree {title}</FormHelperText>
            </FormControl>
            {content}
        </Paper>
    );
};

export default withStyles(styles)(Agreement);





