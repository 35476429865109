import * as types from '../constants/actionTypes';

export const updateNotification = (notification) => {
    return {
        type: types.UPDATE_DISPLAY,
        notification
    }
};

export const updateMessageDialog = (message) => {
    return {
        type: types.UPDATE_MESSAGE_DIALOG,
        message,
    }
};

export const closeMessaegDialog = () => {
    return {
        type: types.CLOSE_MESSAGE_DIALOG,
    }
}

export const updateMessageDialogText = (text) => {
    return {
        type: types.UPDATE_MESSAGE_DIALOG_TEXT,
        text,
    }
};

export const disableMessageSubmit = (text) => {
    return {
        type: types.DISABLE_MESSAGE_SUBMIT,
        text,
    }
};

export const enableMessageSubmit = (text) => {
    return {
        type: types.ENABLE_MESSAGE_SUBMIT,
        text,
    }
};

export const updateResetEmail = (resetEmail) => {
    return {
        type: types.UPDATE_RESET_EMAIL,
        resetEmail,
    }
}

export const updateContact = (name, value) => {
    return {
        type: types.UPDATE_CONTACT,
        name,
        value,
    }
}

export const initContact = () => ({
    type: types.INIT_CONTACT,
})

export const dialogOpen = () => ({
    type: types.DIALOG_OPEN,
});

export const dialogClose = () => ({
    type: types.DIALOG_CLOSE,
});

export const showSpinner = () => ({
    type: types.SHOW_SPINNER,
});

export const hideSpinner = () => ({
    type: types.HIDE_SPINNER,
})

export const updateInboxTabValue = (value) => ({
    type: types.UPDATE_INBOX_TAB_VALUE,
    value: value
})

export const updateCustomScopeField = (value) => ({
    type: types.UPDATE_CUSTOM_SCOPE_FIELD,
    value,
})
