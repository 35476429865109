import { connect } from 'react-redux';
import UserMessageList from '../components/UserMessageList';
import { getUserActions } from '../../selectors/ee';
import { fetchDeleteUserAction } from '../../actions/api';

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.ee.user,
        history: ownProps.history,
        messages: getUserActions(state),
        messageType: 'user-action',
        trs: [{
                title: 'ID',
                align: 'left'
            }, {
                title: 'Date',
                align: 'left',
            }, {
                title: 'Proejct ID',
                align: 'right',
            }, {
                title: 'Message',
                align: 'left',
            }, {
                title: 'Actions',
                align: 'right',
            }]
    }
};

const mapDispatchToProps = dispatch => ({
    handleDeleteMessageClick: (userActionId, history) => {
        dispatch(fetchDeleteUserAction(userActionId, history));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserMessageList);
