import { connect } from 'react-redux';
import ProposalList from '../components/ProposalList';
import { getProjectProposals, getProjectStatus } from '../../selectors/ee';
import { updateMessageDialog } from '../../actions/view';
import { fetchRemoveProposal, fetchSelect } from '../../actions/api';

const mapStateToProps = (state, ownProps) => {
    return {
        history: ownProps.history,
        user: state.ee.user,
        proposals: getProjectProposals(state),
        detailsTrs: ['Provider', 'Scopes', 'Cost total', 'Estimated Time', 'Start date', 'Create date', 'Update date', 'Comments', 'Actions'],
        dashboardTrs: ['Project ID', 'Scopes', 'Cost total', 'Estimated Time', 'Start date', 'Create date', 'Update date', 'Actions'],
        isDashboardView: false,
        isSelect: getProjectStatus(state) === 'SELECTING' || true, //todo: remove true
        projectStatus: getProjectStatus(state),
    }
};

const mapDispatchToProps = dispatch => ({
    handleClickMessageDialogOpen: (replyDisplay, replyId, projectId) => {
        const message = {
            open: true,
            replyDisplay,
            replyId,
            projectId,
        }
        dispatch(updateMessageDialog(message));
    },
    handleProposalDeleteClick: (proposalId) => {
        dispatch(fetchRemoveProposal(proposalId));
    },
    handleSelectSave: (history, proposalId, projectId, providerId, scopes) => {
        dispatch(fetchSelect(history, proposalId, projectId, providerId, scopes));
    }
 });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProposalList);
