import { connect } from 'react-redux';
import { saveUserHourlyRate } from '../../actions/api';
import { updateHourlyRate } from '../../actions/register';
import HourlyRate from '../components/HourlyRate';

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.ee.user,
        register: state.register,
        isUpdate: ownProps.isUpdate,
        history: ownProps.history,
    }
};

const mapDispatchToProps = dispatch => ({
    handleChange: (name, value) => {
        dispatch(updateHourlyRate(name, value));
    },
    handleSaveHourlyRate: (history) => {
        dispatch(saveUserHourlyRate(history));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HourlyRate);