import React, { Component } from 'react';
import { connect } from 'react-redux';
import RegisterCommonContainer from '../containers/RegisterCommonContainer';
import RegisterProviderContainer from '../containers/RegisterProviderContainer';
import ProfileContainer from '../containers/ProfileContainer';

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.ee.user,
        register: state.register,
        history: ownProps.history,
    }
};

const mapDispatchToProps = dispatch => ({
    // getDashboardData: () => {
    //     dispatch(fetchDashboard());
    // },
});

class RegisterContainer extends Component {
    // componentDidMount() {
    //     this.props.getDashboardData();
    // }

    // componentWillReceiveProps() {
    //     this.props.getDashboardData();
    // }

    render() {
        const registerStep = this.props.register.registerStep || this.props.user.step;
        return (
            <div>
                {(!registerStep || (registerStep < 1)) && <RegisterCommonContainer history={this.props.history}/> }
                {registerStep === 1 && <RegisterProviderContainer history={this.props.history} /> }
                {registerStep === 2 && <ProfileContainer history={this.props.history} isRegister={true}/> }
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RegisterContainer);
