import { connect } from 'react-redux';
import { updateWorkZoneState, updateWorkZoneCounty } from '../../actions/register';
import { fetchSaveWorkZone } from '../../actions/api';
import WorkZone from '../components/WorkZone';

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.ee.user,
        register: state.register,
        history: ownProps.history,
    }
};

const mapDispatchToProps = dispatch => ({
    handleWorkZoneState: (workZoneState) => {
        dispatch(updateWorkZoneState(workZoneState));
    },
    handleWorkZoneCounty: (workZoneCounty) => {
        dispatch(updateWorkZoneCounty(workZoneCounty));
    },
    handleWorkZoneCounty: (state, selectionList) => {
        dispatch(updateWorkZoneCounty(
            state,
            selectionList,
        ));
    },
    handleSaveWorkZone: (history) => {
        dispatch(fetchSaveWorkZone(history));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkZone);