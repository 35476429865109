import { connect } from 'react-redux';
import ProposalScopeList from '../components/ProposalScopeList';
import { prepareEditScopeDetail, deleteProposalScope } from '../../actions/ee';
import { updateMessageDialog } from '../../actions/view';
import { selectScope } from '../../actions/ee';
import { fetchRemoveProposal } from '../../actions/api';
import { getProjectStatus } from '../../selectors/ee';

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.ee.user,
        scopeDetailList: ownProps.scopeDetailList,
        isDashboardView: false,
        isSelect: getProjectStatus(state) === 'SELECTING',
        isEdit: ownProps.isEdit || false,
    }
};

const mapDispatchToProps = dispatch => ({
    handleClickMessageDialogOpen: (replyDisplay, replyId, projectId) => {
        const message = {
            open: true,
            replyDisplay,
            replyId,
            projectId,
        }
        dispatch(updateMessageDialog(message));
    },
    handleProposalDeleteClick: (proposalId) => {
        dispatch(fetchRemoveProposal(proposalId));
    },
    handleEditProposalScopeClick: (scopeId) => {
        dispatch(prepareEditScopeDetail(scopeId));
    },
    handleDeleteProposalScope: (scopeId) => {
        dispatch(deleteProposalScope(scopeId));
    },
    handleScopeSelectClick: (scopeDetail) => {
        dispatch(selectScope(scopeDetail));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProposalScopeList);
