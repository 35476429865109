import { connect } from 'react-redux';
import ProposalList from '../components/ProposalList';
import { getProposals } from '../../selectors/ee';
import { updateMessageDialog } from '../../actions/view';
import { fetchRemoveProposal } from '../../actions/api';

const mapStateToProps = state => {
    return {
        user: state.ee.user,
        proposals: getProposals(state),
        detailsTrs: ['Provider', 'Scopes', 'Cost total', 'Estimated Time', 'Start date', 'Create date', 'Update date', 'Comments', 'Actions'],
        dashboardTrs: ['Project ID', 'Name', 'Scopes', 'Cost total', 'Estimated Time', 'Start date', 'Create date', 'Update date', 'Actions'],
        isDashboardView: false,
    }
};

const mapDispatchToProps = dispatch => ({
    handleClickMessageDialogOpen: (replyDisplay, replyId, projectId) => {
        const message = {
            open: true,
            replyDisplay,
            replyId,
            projectId,
        }
        dispatch(updateMessageDialog(message));
    },
    handleProposalDeleteClick: (proposalId) => {
        dispatch(fetchRemoveProposal(proposalId));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProposalList);
