import { connect } from 'react-redux';
import QuickOfferList from '../../proposal/components/QuickOfferList';
import { getOffers } from '../../selectors/ee';
import { updateMessageDialog } from '../../actions/view';
import { fetchRemoveProposal, fetchAcceptOffer } from '../../actions/api';

const mapStateToProps = state => {
    return {
        user: state.ee.user,
        offers: getOffers(state),
        detailsTrs: ['Provider', 'Cost total', 'Material cost', 'Comments', 'Create date', 'Update date', 'Actions'],
        dashboardTrs: ['Project ID', 'Cost total', 'Material cost',  'Comments', 'Create date', 'Update date', 'Actions'],
        isDashboardView: false,
    }
};

const mapDispatchToProps = dispatch => ({
    handleClickMessageDialogOpen: (replyDisplay, replyId, projectId) => {
        const message = {
            open: true,
            replyDisplay,
            replyId,
            projectId,
        }
        dispatch(updateMessageDialog(message));
    },
    handleProposalDeleteClick: (offerId) => {
        dispatch(fetchRemoveProposal(offerId));
    },
    handleAcceptOffer: (offerId) => {
        dispatch(fetchAcceptOffer(offerId));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QuickOfferList);
