import { createSelector } from 'reselect';
import moment from 'moment';

const getRegisterInsurance = state => state.register.insurance;
// const getRegisterPermit = state => state.publicReducer.permitList;
const getRegisterPermit = state => {
    return state.publicReducer.permitList;
}
const getScopes = state => state.view.scopes;
const getRegister = state => state.register;
const getWorkHourList = state => state.publicReducer.workHourList;

export const getInsurance = createSelector(
    getRegisterInsurance,
    (insuranceList) => {
        return insuranceList.map(insurance => {
            return {
                id: insurance.id,
                data: [
                    insurance.name,
                    insurance.company,
                    insurance.validUntil,
                ]
            };
        })
    }
);

export const getPermit = createSelector(
    getRegisterPermit,
    (permitList) => {
        return permitList.map(permit => {
            return {
                id: permit.id,
                data: [
                    permit.name,
                    permit.issuedAt,
                    permit.issuedBy,
                ]
            };
        })
    }
);

export const getWorkHour = createSelector(
    getWorkHourList,
    (workHourList) => {
        return workHourList.map(workHour => {
            return {
                day: workHour.day,
                hour: workHour.start 
            };
        })
    }
);

