import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Select from 'react-select'
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%',
    },
    narrow: {
        width: 120,
    },
    dense: {
        marginTop: 19,
    },
    margin: {
        marginLeft: '4px',
        marginRight: '4px',
    },
    margin20: {
        display: 'flex',
        marginBottom: '20px',
    },
    link: {
        margin: theme.spacing(1),
    },
    flex: {        
        display: 'flex',
    },
    marginLeft: {
        marginLeft: '4px',
    },
    subLabel: {
        fontWeight: 600, 
    }
});

const SaveWorkZone = (props) => {
    const {
        classes,
        user,
        workHour,
        workHourList,
        history,
        handleChange,
        handleAddWorkHour,
        handleRemoveWorkHour,
    } = props;

    const dayOptions = ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'].map(option => ({ 
        value: option,
        label: option
    }));

//     const timeOptions = ["12:00 AM (midnight)", "12:30 AM", "1:00 AM", "1:30 AM", "2:00 AM", "2:30 AM", "3:00 AM",
//     "3:30 AM", "4:00 AM", "4:30 AM", "5:00 AM", "5:30 AM", "6:00 AM", "6:30 AM", "7:00 AM", "7:30 AM", "8:00 AM", "8:30 AM", "9:00 AM",
//     "9:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM", "12:00 PM", "12:30 PM", "1:00 PM", "1:30 PM", "2:00 PM", "2:30 PM", "3:00 PM",
//     "3:30 PM", "4:00 PM", "4:30 PM", "5:00 PM", "5:30 PM", "6:00 PM", "6:30 PM", "7:00 PM", "7:30 PM", "8:00 PM", "8:30 PM", "9:00 PM",
//     "9:30 PM", "10:00 PM", "10:30 PM", "11:00 PM", "11:30 PM"].map(option => ({
//         value: option.split(" ")[0],
//         label: option
//     }));
    const timeOptions = ["00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30",
    "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30",
    "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30",
    "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30"].map(option => ({
        value: option,
        label: option
    }));
    const workHourday = workHour ? {
        value: workHour.day,
        label: workHour.day,
    } : undefined;
    const workHourStart = workHour ? {
        value: workHour.start,
        label: workHour.start,
    } : undefined;
    const workHourEnd = workHour ? {
        value: workHour.end,
        label: workHour.end,
    } : undefined;

    return (
        <Container maxWidth="lg">
            <div className={classes.root} >
            <InputLabel 
                htmlFor="workhour"
                className={clsx(classes.subLabel, 'ee-color')}
            >Work Hour</InputLabel>    
            {workHourList && workHourList.map(workhour => {
                return (<div>
                    {workhour.day} | {workhour.start} ~ {workhour.end} 
                    <Link href="#" onClick={() => {handleRemoveWorkHour(workhour.day)}} className={classes.link}>
                        Remove
                    </Link>
                </div>);
            })}
            <div className={clsx(classes.margin20)}>
                <Select
                    required
                    name="day"
                    label="Day"
                    value={workHourday}
                    options={dayOptions}
                    className={classes.select}
                    classNamePrefix="select"
                    onChange={(event) => handleChange('day', event.value)}
                    className={clsx(classes.narrow, classes.margin)}
                />
                <Select
                    required
                    name="start"
                    label="Start time"
                    value={workHourStart}
                    options={timeOptions}
                    className={classes.select}
                    classNamePrefix="select"
                    onChange={(event) => handleChange('start', event.value)}
                    className={clsx(classes.narrow, classes.margin)}
                />
                <Select
                    required
                    name="end"
                    label="End time"
                    value={workHourEnd}
                    options={timeOptions}
                    className={classes.select}
                    classNamePrefix="select"
                    onChange={(event) => handleChange('end', event.value)}
                    className={clsx(classes.narrow, classes.margin)}
                />
                <Button 
                    variant="outlined" 
                    component="span" 
                    color="green"
                    onClick={() => handleAddWorkHour(history)}
                    className={clsx(classes.narrow, 'flex')}>
                    Add
                </Button>
            </div>
        </div>
        </Container>
    );
};

export default withStyles(styles)(SaveWorkZone);

