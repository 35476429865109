import { connect } from 'react-redux';
import ProjectList from '../components/ProjectList';
import { fetchRemoveProject } from '../../actions/api'


const mapStateToProps = (state, ownProps) => {
    return {
        user: state.ee.user,
        projects: (state.ee.dashboard && state.ee.dashboard.projects) ? state.ee.dashboard.projects : [],
        trs: ['ID', 'Title', 'Create date', 'Update date', 'Bidding date', 'Proposal #', 'Actions'],
        history: ownProps.history,
    }
};

const mapDispatchToProps = dispatch => ({
    handleClickRemoveProject: (projectId, history) => {
        dispatch(fetchRemoveProject(projectId, history));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectList);
