import { connect } from 'react-redux';
import { updatePasswordValue } from '../../actions/setting';
import { fetchUpdatePassword } from '../../actions/api';
import PasswordSetting from '../components/PasswordSetting';

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.ee.user,
        password: state.setting.password,
        // helperMessage: state.view.helperMessage,
        // history: ownProps.history,
    }
};

const mapDispatchToProps = dispatch => ({
    handleChange: (name, value) => {
        dispatch(updatePasswordValue(name, value));
    },
    handleUpdatePassword: (history) => {
        dispatch(fetchUpdatePassword(history));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PasswordSetting);
