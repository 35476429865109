//view
export const LOAD_USER = 'LOAD_USER';
export const LOGOUT = 'LOGOUT';
export const ENABLE_LOGIN_LINK = 'ENABLE_LOGIN_LINK';
export const UPDATE_RESET_EMAIL = 'UPDATE_RESET_EMAIL';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const SELECT_SCOPE = 'SELECT_SCOPE';
export const DIALOG_OPEN = 'DIALOG_OPEN';
export const DIALOG_CLOSE = 'DIALOG_CLOSE';
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const UPDATE_TITLE = 'UPDATE_TITLE';
export const UPDATE_INBOX_TAB_VALUE = 'UPDATE_INBOX_TAB_VALUE';
export const UPDATE_CUSTOM_SCOPE_FIELD = 'UPDATE_CUSTOM_SCOPE_FIELD';

//contact
export const INIT_CONTACT = 'INIT_CONTACT';

export const RECEIVED_DASHBOARD = 'RECEIVED_DASHBOARD';
export const RECEIVED_PUBLIC_DASHBOARD = 'RECEIVED_PUBLIC_DASHBOARD';
export const RECEIVED_PROFILE_IMAGE = 'RECEIVED_PROFILE_IMAGE';
export const RECEIVED_SCOPES = 'RECEIVED_SCOPES';
export const RECEIVED_PROJECT = 'RECEIVED_PROJECT';
export const SELECT_SAVED = 'SELECT_SAVED';
export const RECEIVED_PROJECT_PROJECT = 'RECEIVED_PROJECT_PROJECT';
export const RECEIVED_PROPOSAL = 'RECEIVED_PROPOSAL';
export const RECEIVED_QUICKOFFER = 'RECEIVED_QUICKOFFER'
export const RECEIVED_WORKZONE = 'RECEIVED_WORKZONE';
export const RECEIVED_PROFILE = 'RECEIVED_PROFILE';
export const RECEIVED_WORKHOUR = 'RECEIVED_WORKHOUR';
export const RECEIVED_PHONE = 'RECEIVED_PHONE';
export const RECEIVED_HOURLY_RATE = 'RECEIVED_HOURLY_RATE';

export const UPDATE_DISPLAY = 'UPDATE_DISPLAY';

export const UPDATE_MESSAGE_DIALOG = 'UPDATE_MESSAGE_DIALOG';
export const CLOSE_MESSAGE_DIALOG = 'CLOSE_MESSAGE_DIALOG';
export const UPDATE_MESSAGE_DIALOG_TEXT = 'UPDATE_MESSAGE_DIALOG_TEXT';
export const DISABLE_MESSAGE_SUBMIT = 'DISABLE_MESSAGE_SUBMIT';
export const ENABLE_MESSAGE_SUBMIT = 'ENABLE_MESSAGE_SUBMIT';

export const UPDATE_PROJECT_VALUE = 'UPDATE_PROJECT_VALUE';
export const ADD_CUSTOM_SCOPE = 'ADD_CUSTOM_SCOPE';

export const UPDATE_PROPOSAL_VALUE = 'UPDATE_PROPOSAL_VALUE';
export const UPDATE_PROPOSAL_SCOPE_VALUE = 'UPDATE_PROPOSAL_SCOPE_VALUE'
export const UPDATE_SCOPE_DETAIL = 'UPDATE_SCOPE_DETAIL';
export const PREPARE_EDIT_SCOPE_DETAIL = 'PREPARE_EDIT_SCOPE_DETAIL';
export const SELECT_PROPOSAL_SCOPE = 'SELECT_PROPOSAL_SCOPE';
export const UPDATE_SCOPE_TYPE = 'UPDATE_SCOPE_TYPE';
export const ADD_PROPOSAL_SCOPE = 'ADD_PROPOSAL_SCOPE';
export const CANCLE_PROPOSAL_SCOPE = 'CANCLE_PROPOSAL_SCOPE';
export const DELETE_PROPOSAL_SCOPE = 'DELETE_PROPOSAL_SCOPE';
export const EDIT_PROPOSAL_SCOPE = 'EDIT_PROPOSAL_SCOPE';
export const UPDATE_PROPOSAL = 'UPDATE_PROPOSAL';
export const UPDATE_QUICK_OFFER_VALUE = 'UPDATE_QUICK_OFFER_VALUE';

export const UPDATE_REGISTER_VALUE = 'UPDATE_REGISTER_VALUE';
export const UPDATE_STEP = 'UPDATE_STEP';
export const UPDATE_USER_TYPE = 'UPDATE_USER_TYPE';
export const UPDATE_COMPANY_VALUE = 'UPDATE_COMPANY_VALUE';
export const UPDATE_USER_COMPANY = 'UPDATE_USER_COMPANY';
export const UPDATE_LICENSE_VALUE = 'UPDATE_LICENSE_VALUE';
export const UPDATE_USER_LICENSE = 'UPDATE_USER_LICENSE';
export const UPDATE_WORK_ZONE_STATE = 'UPDATE_WORK_ZONE_STATE';
export const UPDATE_WORK_ZONE_COUNTY = 'UPDATE_WORK_ZONE_COUNTY';
export const UPDATE_HOURLY_RATE = 'UPDATE_HOURLY_RATE';
export const UPDATE_ADDRESS_VALUE = 'UPDATE_ADDRESS_VALUE';
export const UPDATE_PHONE_VALUE = 'UPDATE_PHONE_VALUE';
export const UPDATE_WORKHOUR_VALUE = 'UPDATE_WORKHOUR_VALUE';
export const UPDATE_INSURANCE_VALUE = 'UPDATE_INSURANCE_VALUE';
export const UPDATE_PERMIT_VALUE = 'UPDATE_PERMIT_VALUE';
export const ADD_WORKHOUR = 'ADD_WORKHOUR';
export const UPDATE_PROFILE_IMAGE_FILE_VALUE = 'UPDATE_PROFILE_IMAGE_FILE_VALUE';

export const UPDATE_REVIEW_VALUE = 'UPDATE_REVIEW_VALUE';
export const UPDATE_REVIEW_PROJECTID_PROVIDERID = 'UPDATE_REVIEW_PROJECTID_PROVIDERID';

export const UPDATE_ACCOUNT_ACTIVATION_STATUS = 'UPDATE_ACCOUNT_ACTIVATION_STATUS';
export const UPDATE_PASSWORD_VALUE = 'UPDATE_PASSWORD_VALUE';
export const INIT_UPDATE_PASSWORD = 'INIT_UPDATE_PASSWORD';
export const UPDATE_TOKEN_EMAIL = 'UPDATE_TOKEN_EMAIL';