import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MessageIconSvg from '../../icons/MessageIconSvg';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%',
        overflowX: 'auto',
        padding: '10px 16px 10px 16px',
    },
    table: {
        width: "100%",
        minWidth: 700,
    },
    th: {
        paddingLeft: '2px',
        paddingRight: '2px',
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    project: {
        cursor: 'pointer',
        paddingLeft: '2px',
        paddingRight: '2px',
        fontWeight: '500',
    },
    heavy: {
        fontWeight: 600,
    },   
    offer: {
        color: "#3cb63c",
        backgroundColor: "#e6e4e4",
        padding: '5px',
        borderRadius: '8%',
    }
});

const QuickOfferList = (props) => {
    const {
        classes,
        user,
        offers,
        dashboardTrs,
        history,
        handleClickMessageDialogOpen,
        handleAcceptOffer,
    } = props;

    return (
        <div className={classes.root}>
            <label className={clsx(classes.heavy, 'pt-20')}>Quick Offer</label> 
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        {dashboardTrs.map(tr => (
                            <TableCell className={classes.th} >{tr}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {offers.map(row => {
                            if (user.userType.includes('p')) {
                                return(       
                                    <TableRow className={classes.row} key={row.id}>
                                        <TableCell 
                                            className={classes.project} 
                                            align="left"
                                            onClick={() => { history.push(`/project/${row.project}`) }}
                                        >{row.project}</TableCell>
                                        <TableCell className={classes.th} align="left">{row.cost}</TableCell>
                                        <TableCell className={classes.th} align="left">{row.material}</TableCell>
                                        <TableCell className={classes.th} align="left">{row.comment}</TableCell>
                                        <TableCell className={classes.th} align="left">{row.createdDate}</TableCell>
                                        <TableCell className={classes.th} align="center">{row.updateDate}</TableCell>
                                        <TableCell className={classes.th} align="center">
                                            <IconButton aria-label="Message" className={classes.tableIcon} onClick={() => handleClickMessageDialogOpen(row.providerDisplayName, row.providerId, row.project)}>
                                                <MessageIconSvg />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>)
                            }
                            return (
                                <TableRow className={classes.row} key={row.id}>
                                    <TableCell 
                                        className={classes.project} 
                                        align="left"
                                        onClick={() => { history.push(`/project/${row.project}`) }}
                                    >{row.project}</TableCell>
                                    <TableCell className={classes.th} align="left">{row.cost}</TableCell>
                                    <TableCell className={classes.th} align="left">{row.material}</TableCell>
                                    <TableCell className={classes.th} align="left">{row.comment}</TableCell>
                                    <TableCell className={classes.th} align="left">{row.createdDate}</TableCell>
                                    <TableCell className={classes.th} align="left">{row.updateDate}</TableCell>
                                    <TableCell className={classes.th} align="left">
                                        <IconButton aria-label="Message" className={classes.tableIcon} onClick={() => handleClickMessageDialogOpen(row.providerDisplayName, row.providerId, row.project)}>
                                            <MessageIconSvg />
                                        </IconButton>
                                        <Link
                                            className={clsx(classes.heavy, classes.offer)}
                                            component="button"
                                            variant="body2"
                                            onClick={() => {handleAcceptOffer(row.id)}}
                                            >
                                            Accept Offer
                                        </Link>

                                    </TableCell>
                                </TableRow>
                            )
                        }
                    )}
                    </TableBody>
                </Table>
        </div>
    );
};

export default withStyles(styles)(QuickOfferList);

