import * as types from '../constants/actionTypes';
import { toast } from 'react-toastify';
import { parseProposal } from '../selectors/ee';
import { scopes } from '../constants/scopes'

const initAddScope = {
    type: {
        value: 'fixed_price',
        label: 'Fixed price',
    },
    hour: 10,
    material: 0,
    comment: '',
    unit: 0,
    cost: 0,
    showSpinner: false,
};

export const initialView = () => ({
    login: {
        email: '',
        password: '',
    },
    scopes: [],
    tableBody: {
        project: false,
        proposal: false,
    },
    notification: {
        display: false,
        text: '',
        type: toast.TYPE.INFO,
    },
    message: {
        open: false,
        replyDisplay: 'Get Electrician',
        replyId: 1,
        text: '',
        submitDisabled: false,
    },
    project: {
        customScopeFieldEnabled: false,
    },
    proposal: {
        selectedScopes: [],
        unSelectedScopes: [],
        addScope: initAddScope,
        scopes:[],
        scopeDetailList: [],
    },
    resetEmail: undefined,
    loginLinkEnabled: false,
    finalSelectedScope: {},
    dialogOpen: false,
    contact: {
        subject: undefined,
        comment: undefined,
    },
    title: undefined,
    inbox: {},
});

const initUnselectScope = (state, action, cleanProposal) => {
    const project = action.proposalResponse.project;
    let unSelectedScopes = [];
    project.scopes.forEach( function (scope) {
        const projectScope = {
            scopeId: scope.scopeId, 
            description: scope.name,
            selected: false,
        }
        unSelectedScopes.push(projectScope);
    });
    return {
        ...state,
        project: project,
        proposal: {
            ...cleanProposal,
            selectedScopes: [],
            unSelectedScopes: unSelectedScopes,
        }
    }

}

const getNewScopeValue = (state, userRate) => {
    const scopes = state.scopes;
    let addScope = state.proposal.addScope;
    if(addScope.type.value == 'fixed_price' || addScope.type == 'FP') {
        return {
            ...addScope,
            type: {
                label: 'Fixed price',
                value: 'fixed_price',
            },
            description: addScope.description || scopes[addScope.id].name,
            hour: Number(addScope.hour),
            cost: Number(addScope.cost) || Number(addScope.costTotal),
            unit: Number(addScope.unit),
            scopeTotalCost: addScope.cost * addScope.unit,
        }
    } else if(addScope.type.value == 'hourly' || addScope.type == 'HY') {
        return {
            ...addScope,
            type: {
                label: 'Hourly',
                value: 'Hourly',
            },
            description: addScope.description || scopes[addScope.id].name,
            hour: Number(addScope.hour),
            material: Number(addScope.material),
            scopeTotalCost: userRate * Number(addScope.hour) + Number(addScope.material),
        }
    }
    return {
        ...addScope,
    }
}

const deleteProposalScope = (state, scopeId, addScope = initAddScope) => {
    let selectedScopes = state.proposal.selectedScopes;
    let unSelectedScopes = state.proposal.unSelectedScopes;

    const targetScope = selectedScopes.filter(scope => scope.scopeId === scopeId)[0];

    for(var i=0; i<selectedScopes.length; i++) {
        if(selectedScopes[i].scopeId == scopeId) {
            selectedScopes.splice(i, 1);
        }
    }

    unSelectedScopes.push(targetScope);

    return {
        ...state,
        proposal: {
            ...state.proposal,
            selectedScopes: selectedScopes,
            unSelectedScopes: unSelectedScopes,
            scopes: selectedScopes,
            addScope: addScope,
        },
    }
}

const clickScope = (state, scopeId, scopeName) => {
    let unSelectedScopes = state.proposal.unSelectedScopes
    let selected = false;

    // const addScopeScopeId = state.proposal.addScope.scopeId; //why???
    unSelectedScopes = unSelectedScopes.map((scope) => {
        // if(scope.scopeId === addScopeScopeId || scope.scopeId == scopeId) {  //why?
        if(scope.scopeId == scopeId) {
            scope.selected = !scope.selected;
            selected = scope.selected;    
        } else {
            scope.selected = false;
        }
        return scope;
    });

    if(selected) {
        return {
            ...state,
            proposal: {
                ...state.proposal,
                unSelectedScopes: unSelectedScopes,
                addScope: {
                    ...state.proposal.addScope,
                    key: scopeName, 
                    scopeId: scopeId,
                    id: scopeId,
                    description: scopeName,
                }
            },
        }
    }
    
    return {
        ...state,
        proposal: {
            ...state.proposal,
            unSelectedScopes:unSelectedScopes,
            addScope: initAddScope,
        },
    }
}

const pushAddScopeToSelectedScopes = (state, addScopeList, isAction) => {
    let selectedScopes = state.proposal.selectedScopes;
    let unSelectedScopes = state.proposal.unSelectedScopes;
    addScopeList.map((addScopeValue) => {
        let scopeId;
        if (!addScopeValue.scopeId) {
            addScopeValue.scopeId = addScopeValue.id;
        } 
        scopeId = addScopeValue.scopeId;
        if (scopeId) {
            for(var i=0; i<unSelectedScopes.length; i++) {
                if(unSelectedScopes[i].scopeId == scopeId) {
                    unSelectedScopes.splice(i, 1);
                }
            }
            selectedScopes.push(addScopeValue);
        }
    });  

    if(isAction) {
        return {
            ...state,
            proposal: {
                ...state.proposal,
                selectedScopes: selectedScopes,
                unSelectedScopes:unSelectedScopes,
                addScope: initAddScope,
                scopes: selectedScopes,
            }
        }    
    }
    return {
        ...state,
        proposal: {
            ...state.proposal,
            selectedScopes: selectedScopes,
            unSelectedScopes:unSelectedScopes,
            addScope: initAddScope,
        }
    }
}

const getAddScopeFromScopeDetail = (state, scopeId) => {
    let selectedScopes = state.proposal.selectedScopes;
    let addScope = selectedScopes.filter(scope => scope.scopeId === scopeId)[0];
    addScope.type = (addScope.type === 'Fixed price') ? {label: 'Fixed price', value: "fixed_price"} : {label: 'Hourly', value: "hourly"}; 
    return addScope;
}

const updateScopeDetail = (state) => {
    //TODO: update here
}

export default function view(prevState = initialView(), action) {
    const state = {
        ...prevState,
    };
    let selectedScopes;
    let newState;
    let cleanProposal;  
    switch (action.type) {
        case types.UPDATE_EMAIL:
            return {
                ...state,
                login : {
                    ...state.login,
                    email: action.email,
                }
            };
        case types.UPDATE_PASSWORD:
            return {
                ...state,
                login : {
                    ...state.login,
                    password: action.password,
                }
            };
        case types.UPDATE_RESET_EMAIL:
                return {
                    ...state,
                    resetEmail : action.resetEmail,
                };
        case types.ENABLE_LOGIN_LINK:
            return {
                ...state,
                loginLinkEnabled: true,
            }
        case types.RECEIVED_SCOPES:
            return {
                ...state,
                scopes: action.scopes,
            }
        case types.UPDATE_DISPLAY:
            return {
                ...state,
                notification: action.notification,
            }
        case types.UPDATE_MESSAGE_DIALOG:
            return {
                ...state,
                message: action.message,
            }
        case types.CLOSE_MESSAGE_DIALOG:
            return {
                ...state,
                message: initialView().message,
            }
        case types.UPDATE_MESSAGE_DIALOG_TEXT:
            return {
                ...state,
                message: {
                    ...state.message,
                    text: action.text,
                }
            }
        case types.DISABLE_MESSAGE_SUBMIT :
            return {
                ...state,
                message: {
                    ...state.message,
                    submitDisabled: true,
                }
            }
        case types.ENABLE_MESSAGE_SUBMIT :
            return {
                ...state,
                message: {
                    ...state.message,
                    submitDisabled: false,
                }
            }
        case types.RECEIVED_PROPOSAL:
            const actionProposal = action.proposalResponse.proposal ? action.proposalResponse.proposal : undefined;
            if(state.scopes && state.scopes.length > 0 && actionProposal) {
                cleanProposal = parseProposal(actionProposal, state.scopes, action.proposalResponse.project);
                newState = initUnselectScope(state, action, cleanProposal);
                return pushAddScopeToSelectedScopes(newState, newState.proposal.scopeDetailList);    
            } else {
                return initUnselectScope(state, action, state.proposal);
            }
        case types.SELECT_PROPOSAL_SCOPE:
            return clickScope(state, action.id, action.name);
        case types.ADD_PROPOSAL_SCOPE:
            const addScopeList = getNewScopeValue(state, action.userRate)
            const isAction = true;
            newState = pushAddScopeToSelectedScopes(state, [addScopeList], isAction);
            cleanProposal = parseProposal(newState.proposal, state.scopes);
            return {
                ...state,
                proposal: {
                    ...cleanProposal,
                    addScope: initAddScope,
                }
            }
        case types.DELETE_PROPOSAL_SCOPE:
            newState = deleteProposalScope(state, action.scopeId);
            cleanProposal = parseProposal(newState.proposal, state.scopes);
            return {
                ...state,
                proposal: {
                    ...cleanProposal,
                }
            }
        case types.CANCLE_PROPOSAL_SCOPE:
            return clickScope(state, undefined, undefined);
        case types.PREPARE_EDIT_SCOPE_DETAIL:
            const addScope = getAddScopeFromScopeDetail(state, action.scopeId);
            newState = deleteProposalScope(state, action.scopeId, addScope);
            cleanProposal = parseProposal(newState.proposal, state.scopes);
            return {
                ...state,
                proposal: {
                    ...cleanProposal,
                }
            }
        case types.UPDATE_SCOPE_DETAIL:
            return updateScopeDetail(state);
        case types.UPDATE_SCOPE_TYPE:
            return {
                ...state,
                proposal: {
                    ...state.proposal,
                    addScope: {
                        ...state.proposal.addScope,
                        type: action.scopeType,
                    }
                }
            }
        case types.UPDATE_PROPOSAL_VALUE:
            return {
                ...state,
                proposal: {
                    ...state.proposal,
                    [action.name]: action.value,
                }
            }
        case types.UPDATE_PROPOSAL_SCOPE_VALUE:
            return {
                ...state,
                proposal: {
                    ...state.proposal,
                    addScope: {
                        ...state.proposal.addScope,
                        [action.name]: action.value,
                    }
                }
            }
        case types.DIALOG_OPEN:
            return {
                ...state,
                dialogOpen: true,
            }
    
        case types.DIALOG_CLOSE:
            return {
                ...state,
                dialogOpen: false,
            }
        case types.SHOW_SPINNER:
            return {
                ...state,
                showSpinner: true,
            };
        case types.HIDE_SPINNER:
            return {
                ...state,
                showSpinner: false,
            };
        case types.UPDATE_CONTACT:
            return {
                ...state,
                contact: {
                    ...state.contact,                    
                    [action.name]: action.value,
                }
            }
        case types.UPDATE_TITLE:
            return {
                ...state,
                title: action.title,
            };
        case types.INIT_CONTACT:
            return {
                ...state,
                contact: {
                    subject: undefined,
                    comment: undefined,
                }
            }
        case types.UPDATE_INBOX_TAB_VALUE:
            return {
                ...state,
                inbox: {
                    value: action.value,
                }
            }
        case types.UPDATE_CUSTOM_SCOPE_FIELD:
            return {
                ...state,
                project: {
                    customScopeFieldEnabled: action.value,
                }
            }
        default:
            return { ...state };
    }
}
