import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import PasswordField from '../../components/PasswordField';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%',
        overflowX: 'auto',
        padding: '0',
        font: '14px',
    },
    margin: {
        marginTop: '16px',
        marginBottom: '20px',
    },
    marginBottom: {
        marginBottom: '16px',
    },
    zIndex0: {
        zIndex: 0,
    },
    lightGrey: {
        color: '#c2c2c2',
    },
    green: {
        color: '#01DF01',
    }, 
    wide: {
        width: '100%',
        maxWidth: '340px',
    },
    minWidth: {
        minWidth: '450px',
    }
});

const PasswordSetting = (props) => {
    const {
        classes,
        user,
        history,
        password,
        handleChange,
        handleUpdatePassword,
    } = props;

    const enabled = password.currentPassword && password.newPassword && password.verifyNewPassword && (password.newPassword === password.verifyNewPassword)
    return (
        <Container maxWidth="lg">
            <div className={clsx(classes.root, "flex")}>
                <form className="form-signin" name="loginFrom" role="form">
                    <h3 className="form-signin-heading">Password <span className={clsx(classes.smallFont, "ee-color")}>GetElectrician</span></h3>
                    <div className="container" id="updatePassword">
                        <br />
                        <div className={clsx(classes.marginBottom)}> 
                            <PasswordField
                                fieldId="currentPassword"
                                label="Old password"
                                type="password"
                                variant="outlined"
                                password={password.currentPassword || ''}
                                showStrength={false}
                                required={true}
                                onChange={(event) => handleChange('currentPassword', event.target.value)}
                            />
                        </div>
                        <PasswordField
                            id="newPassword"
                            label="New Password"
                            type="password"
                            margin="normal"
                            variant="outlined"
                            password={password.newPassword || ''}
                            description="To conform with our Strong Password policy, you are required to use a sufficiently strong password. Password must be more than 7 characters."
                            showStrength={true}
                            required={true}
                            onChange={(event) => handleChange('newPassword', event.target.value)}
                        />
                        <PasswordField
                            id="verifyNewPassword"
                            label="Verify your new password"
                            type="password"
                            margin="normal"
                            variant="outlined"
                            password={password.verifyNewPassword || ''}
                            showStrength={false}
                            required={true}
                            hasErrors={password.newPassword !== password.verifyNewPassword}
                            errors={password.newPassword !== password.verifyNewPassword ? ['password is not matched'] : undefined}
                            onChange={(event) => handleChange('verifyNewPassword', event.target.value)}
                        />

                        <div >
                            <div className={clsx(classes.margin, "form-actions")}>
                                <Button 
                                    disabled={!enabled}
                                    variant="contained" 
                                    component="span" 
                                    onClick={() => handleUpdatePassword(history)}
                                    className={!enabled ? clsx(classes.wide) : clsx(classes.wide, 'ee-bg-color')}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Container>
    );
};

export default withStyles(styles)(PasswordSetting);
