import React, { Component } from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
    return {
        user : state.view.user,
        history: ownProps.history,
    }
};

// const mapDispatchToProps = dispatch => ({
// });

class HowItWorksContainer extends Component {
    componentDidUpdate() {
        const { user } = this.props;
    }

    render() {
        return (
            <div className="container">
                <div className="flex">
                    <div className="container">
                        <div className="row">
                            <div className="">
                                <h4>Are you an electrical provider?</h4>
                                <div className="pl3">Go to see `How it works` for electrical provider</div>
                                <div className="pl3"><a className="btn btn-default btn-ee" href="howitworks_provider" role="button">View details »</a></div>
                            </div>
                        </div>
                        <div className="pt3">
                            <h2>For customers looking to hire electrical providers</h2>
                        </div>
                        <hr className="featurette-divider" />
                        <div className="row featurette">
                            <div className="col-md-7">
                                <h2 className="featurette-heading">Step 1
                                </h2>
                                <p className="lead">Post your electrical project</p>
                            </div>
                            <div className="col-md-5">
                                <img className="featurette-image img-responsive center-block" data-src="/../img/customer/1.png" alt="500x500" src="/../img/customer/1.png" data-holder-rendered="true" />
                            </div>
                        </div>
                        <hr className="featurette-divider" />
                        <div className="row featurette">
                            <div className="col-md-7 col-md-push-5">
                                <h2 className="featurette-heading">Step 2.
                                </h2>
                                <p className="lead">Await electrician's proposals</p>
                            </div>
                            <div className="col-md-5 col-md-pull-7">
                                <img className="featurette-image img-responsive center-block" data-src="/../img/customer/2.png" alt="500x500" src="/../img/customer/2.png" data-holder-rendered="true" />
                            </div>
                        </div>
                        <hr className="featurette-divider" />
                        <div className="row featurette">
                            <div className="col-md-7">
                                <h2 className="featurette-heading">Step 3.
                                </h2>
                                <p className="lead">Compare price estimates, electrician's profile and any customer review</p>
                            </div>
                            <div className="col-md-5">
                                <img className="featurette-image img-responsive center-block" data-src="/../img/customer/3.png" alt="500x500" src="/../img/customer/3.png" data-holder-rendered="true" />
                            </div>
                        </div>
                        <hr className="featurette-divider" />
                        <div className="row featurette">
                            <div className="col-md-7 col-md-push-5">
                                <h2 className="featurette-heading">Step 4.
                                </h2>
                                <p className="lead">Select provider and communicate with electrical providers.</p>
                            </div>
                            <div className="col-md-5 col-md-pull-7">
                                <img className="featurette-image img-responsive center-block" data-src="/../img/customer/4.png" alt="500x500" src="/../img/customer/4.png" data-holder-rendered="true" />
                            </div>
                        </div>
                        <hr className="featurette-divider" />
                        <div className="row featurette">
                            <div className="col-md-7">
                                <h2 className="featurette-heading">Step 5.
                                </h2>
                                <p className="lead">Have your project completed by most qualified and best priced electrical provider.</p>
                            </div>
                            <div className="col-md-5">
                                <img className="featurette-image img-responsive center-block" data-src="/../img/customer/5.png" alt="500x500" src="/../img/customer/5.png" data-holder-rendered="true" />
                            </div>
                        </div>
                        <hr className="featurette-divider" />
                        <div className="row featurette">
                            <div className="col-md-7 col-md-push-5">
                                <h2 className="featurette-heading">Step 6.
                                </h2>
                                <p className="lead">Post reviews about completed job.</p>
                            </div>
                            <div className="col-md-5 col-md-pull-7">
                                <img className="featurette-image img-responsive center-block" data-src="/../img/customer/6.png" alt="500x500" src="/../img/customer/6.png" data-holder-rendered="true" />
                            </div>
                        </div>
                        <hr className="featurette-divider" />
                        <footer>
                            <p className="pull-right"><a href="#howitworks">Back to top</a></p>
                        </footer>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    null,
)(HowItWorksContainer);
