import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import DeleteIconSvg from '../../icons/DeleteIconSvg';
import EditIconSvg from '../../icons/EditIconSvg';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%',
        overflowX: 'auto',
        padding: '10px 10px 10px 20px',
        background: '#f2f5f2'
    },
    listItem: {
        display: 'block',
    },
    table: {
        width: "100%",
        minWidth: 700,
    },
    th: {
        paddingLeft: '2px',
        paddingRight: '2px',
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    scope: {
        backgroundColor:'#F2F2F2',
        margin: theme.spacing.unit,
        fontSize: '12px',
        
    },
    tableActions: {
        display: 'flex',
    },
    tableIcon: {
        padding: '2px',
        margin: theme.spacing.unit,
    },  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  panelDetails: {
    padding: '0px 10px 10px 20px',
  },
  subLabel: {    
        fontWeight: 700,
        paddingRight: '6px',
        fontSize: '13px',
  },
  button: {
    padding: '0',
    marginRight: '2px',
    marginLeft: '6px'
  },
  select: {
    display: 'inline-flex',
    position: 'absolute',
    right: '40px',
    fontSize: '18px',
    border: 'solid 1px #c2c2c2',
    borderRadius: '18px',
    backgroundColor: '#3cb63c',
    height: '45px',
  },
  selectContent: {
    padding: '6px 25px 6px 25px',
  },
  selected:{
    fontSize: '11px',
    borderRadius: '20px',
    background: '#73AD21',
    padding: '2px',
    marginLeft: '5px'
  },
});

const ProposalScopeList = (props) => {
    const {
        classes,
        user,
        scopeDetailList,
        isSelect,
        isEdit,
        projectStatus,
        handleEditProposalScopeClick,
        handleDeleteProposalScope,
        handleScopeSelectClick,
    } = props;

    const abledDelete = projectStatus !== 'SELECTING' && projectStatus !== 'CLOSED' 
                            && projectStatus !== 'SELECTED' && projectStatus !== 'ARCHIVED' && projectStatus !== 'COMPLETED' ;
    const abledEdit = (isEdit && abledDelete)? true : false;
    return (
        <div className={classes.root}>
            <label>Scope Details</label>
                <List component="nav" aria-label="main mailbox folders">
                    {scopeDetailList.map(scopeDetail => (
                        <ListItem button className={classes.listItem}>
                            <div>
                                <span>{scopeDetail.description}
                                    <span className={clsx(classes.sm, classes.selected)}>{ scopeDetail.status == "SELECTED" ? 'SELECTED' : ''}</span>                
                                </span>
                                {abledEdit && user.userType !== 'c' && <IconButton 
                                    className={classes.button} 
                                    aria-label="Edit proposal scope" 
                                    onClick={() => handleEditProposalScopeClick(scopeDetail.scopeId)}
                                    id="icon"
                                >
                                    <EditIconSvg />
                                </IconButton>}
                                {abledEdit && user.userType !== 'c' && <IconButton 
                                    aria-label="Delete proposal scope" 
                                    className={classes.button} 
                                    onClick={() => handleDeleteProposalScope(scopeDetail.scopeId)}
                                    id="icon"
                                >
                                    <DeleteIconSvg />
                                </IconButton>}
                                {isSelect && <div className={classes.select}>
                                    <div className={classes.selectContent} onClick={() => handleScopeSelectClick(scopeDetail)}>Select</div>
                                </div>}
                            </div>
                            <div>
                                <label className={classes.subLabel}>Estimated work time </label>
                                <span>{scopeDetail.hour} hour(s)</span>
                            </div>
                            <div>
                                <label className={classes.subLabel}>Cost </label>
                                $ <b>{scopeDetail.scopeTotalCost}</b>
                            </div>
                            <div>
                                <label className={classes.subLabel}>Cost Detail </label>
                                <span>{scopeDetail.type} </span>
                                {scopeDetail.type !== 'Hourly' &&
                                    <span><strong>$ {scopeDetail.scopeTotalCost} = {scopeDetail.cost} * {scopeDetail.unit} (unit) </strong>
                                </span>}
                                {scopeDetail.type === 'Hourly' && <span>Labor <strong>$ {scopeDetail.scopeTotalCost} = {user.rate}/hour * ${scopeDetail.hour}</strong>
                                    + Meterial cost: <strong> ${scopeDetail.material} </strong> 
                                </span>}
                            </div>
                            <div>
                                <label className={classes.subLabel}>Comments </label>
                                <span>{scopeDetail.comment}</span>
                            </div>
                        </ListItem>
                    ))}
                    <Divider />
                </List>
        </div>
    );
};

export default withStyles(styles)(ProposalScopeList);

