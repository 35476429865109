import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { ButtonGroup } from '@material-ui/core';
import ExpansionPanelComponent from '../../components/ExpansionPanelComponent';
import Agreements from './Agreements';
import { CODE_OF_ETHICS } from './CodeOfEthics';
import { TERMS_OF_USE } from './termsOfUse';
import { TERMS_OF_USE_PROVIDER } from './termsOfUseProvider';
import parse from 'html-react-parser';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        display: 'flex',
        flexWrap: 'wrap',
        padding: '0',
        width: '100%',
    },
    formBox: {
        padding: '0px 20px 10px 20px',
    },
    margin20: {
        marginBottom: '20px',
    },
    eeColor: {
        color: '#01DF01',
    },
    guideText: {
        fontWeight: 'bold',
    },
    wide: {
        width: '100%',
        maxWidth: '340px',
    },
});

const RegisterProvider = (props) => {
    const {
        classes,
        user,
        register,
        history,
        handleChange,
        handleRegister,
        handleTermsOfUse,
        handleTermsOfUseProvider,
        handleCodeOfEthics,
    } = props;

    const providerTypeOptions = {
        licensedCompany: 'Licensed Electrical Company',
        licensedIndividual: 'Licensed Electrical Individual',
        unlicensedIndividual:'Unlicensed Electrical Individual',
    };

    const registerDisabled = !register.agreement.termsOfUse || !register.agreement.termsOfUseProvider || !register.agreement.codeOfEthics || !register.providerType;

    return (
        <div className={classes.root} >
            <h4 className="title-ee">Complete your <span className={classes.eeColor}> GetElectrician </span> profile</h4>
            <form className={classes.formBox} name="registerStep2" noValidate autoComplete="off">
                <div>
                    You’re one step closer to have your Professional Account created and acquire new customers!
                    ”Complete Profile” button will be grey out until you fill out all required fields.
                </div>
                <div className={clsx(classes.margin20)}>
                    <div className={classes.guideText} data-toggle="tooltip" data-placement="top" title="Tooltip on top">Please select Professional Account Type below by selecting box which best describes you
                    </div>

                    <Grid item xs={12}>
                        <ButtonGroup fullWidth aria-label="full width outlined button group">
                            {Object.entries(providerTypeOptions).map(([type, typeDescription]) => (
                                <Button
                                    key={type}
                                    variant={type ===  register.providerType ? 'contained' : 'outlined'} 
                                    className={type ===  register.providerType ? 'ee-bg-color' : ''} 
                                    onClick={() => handleChange('providerType', type)}
                                >{typeDescription}
                                </Button>
                            ))}
                        </ButtonGroup>
                    </Grid>
                    <div>
                        <ExpansionPanelComponent 
                            title="Terms Of Use" 
                            details={<Agreements 
                                title="I have read and agree to the Terms Of Use" 
                                checked={register.agreement.termsOfUse}
                                handleCheckbox={handleTermsOfUse} 
                                content={parse(TERMS_OF_USE)} 
                                history={history}/>} />
                        <ExpansionPanelComponent 
                            title="Service Provider Terms Of Use" 
                            details={<Agreements 
                                title="I have read and agree to the Service Provider Terms Of Use" 
                                checked={register.agreement.termsOfUseProvider}
                                handleCheckbox={handleTermsOfUseProvider} 
                                content={parse(TERMS_OF_USE_PROVIDER)} history={history}/>} />
                        <ExpansionPanelComponent 
                            title="Code of Ethics" 
                            details={<Agreements 
                                title="I have read and agree to the Code of Ethics" 
                                checked={register.agreement.codeOfEthics}
                                handleCheckbox={handleCodeOfEthics} 
                                content={parse(CODE_OF_ETHICS)} 
                                history={history}/>} />
                    </div>
                </div> 
                <div className={clsx(classes.wide, classes.margin20)}>      
                    <Button 
                        disabled={registerDisabled}
                        variant="contained" 
                        component="span" 
                        color="green"
                        onClick={() => handleRegister(history)}
                        className={registerDisabled ? clsx(classes.wide) : clsx(classes.wide, 'ee-bg-color')}
                    >
                        Save
                    </Button>
                </div>
            </form>
        </div>

        // <form ng-show="steps[3]" name = "registerStep4" ng-submit="saveRegister(3)" novalidate>
        //     You’re almost there {{user.userDisplayName}}
        //     Please provide few additional pieces of information so that you can enjoy full benefits of your professional account with us
        //     <hr>
        //     <ng-include src="'html/addInsurance.html'"></ng-include>
        //     <ng-include src="'html/addPermit.html'"></ng-include>

        //     <code ng-show="showMessage">{{message}}</code>
        //     <code ng-if="vm.errorMsg">{{vm.errorMsg}}</code>
        //     <code ng-if="validateError">{{validateError}}</code>
        // </form>
    );
};

export default withStyles(styles)(RegisterProvider);
