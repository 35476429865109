import { connect } from 'react-redux';
import { updateLicenseValue } from '../../actions/register';
import { fetchSaveLicense } from '../../actions/api';
import License from '../components/License';

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.ee.user,
        register: state.register,
        history: ownProps.history,
    }
};

const mapDispatchToProps = dispatch => ({
    handleChange: (name, value) => {
        dispatch(updateLicenseValue(name, value));
    },
    handleSaveLicense: (history) => {
        dispatch(fetchSaveLicense(history));
    },
    handleCancelhandleSaveLicense: () => {
        console.log('cancel license'); //TODO
    },
    handleRemoveLicense: () => {
        console.log('remove license'); //TODO
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(License);
