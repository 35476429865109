import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import SimpleList from '../../components/SimpleList';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%',
        overflowX: 'auto',
        padding: '0',
        font: '14px',
    },
    formBox: {
        padding: '0px 20px 10px 20px',
    },
    wide: {
        width: '100%',
        maxWidth: '340px',
    },
    narrow: {
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    margin: {
        marginLeft: '4px',
        marginRight: '4px',
    },
    margin20: {
        marginBottom: '20px',
    },
    zIndex0: {
        zIndex: 0,
    },
    lightGrey: {
        color: '#c2c2c2',
    },
    green: {
        color: '#01DF01',
    }, 
    publicTopDay: {
        float: 'right',
        padding: '1rem',
        bottom: '0',
        backgroundColor: '#F0F0F0',
    },
    publicTopLeft: {
        padding: '5px',
        margin: '5px',
    },    
    publicTopRight: {
        float: 'right',
        padding: '1rem',
        display: 'flex',
    },
    profileImage: {
        width: '200px',
        height: '200px',
    },
    top: {
        display: 'flex',
    }
});

const Public = (props) => {
    const {
        classes,
        user,
        history,
        publicReducer,
        permitList,
        insuranceList,
        providerId,
        handleClickMessageDialogOpen,
    } = props;

    const { 
        workHourList,
        company,
        location,
        since,
        finishedJob,
        workingJob,
        reviewCount,
        userCompany,
        profileImage,
        name,
    } = publicReducer;
    // const formattedIssuedAt = moment(permit.issuedAt).format('YYYY-MM-DD');
    // const submitDisabled = permit.name == undefined || !formattedIssuedAt == undefined || !permit.company == undefined;

    return (
    	<div className={classes.root} >
            <div className={classes.top}> 
                <div className={clsx(classes.profileImage, classes.publicTopLeft)}>
                    <img className="profile-image" src={profileImage} />
                </div>
                <div className={classes.publicTopLeft}>
                    <div ng-show="!showCompany">
                        <h3><strong>{name}</strong>, <span className={classes.lightGrey}>{location}</span></h3>
                    </div>
                    {userCompany && publicReducer.userType == 'plec' && <div>
                        <div><strong>{userCompany.name}</strong>, <span className={classes.lightGrey}>{location}</span></div>
                        <div>Compnay ID: {userCompany.companyId}, <span className={classes.lightGrey}>registered at {userCompany.registeredAt}</span></div>
                    </div>}
                    <div className={classes.green}>since {since} in GetElectrician</div>
                    <div><strong>{publicReducer.completedProjectCount} finished job, {publicReducer.workingProjectCount} working job,  {publicReducer.reviewCount} reviews</strong></div>
                    <div>
                        <button type="button" className="btn btn-default"><a href onClick={() => handleClickMessageDialogOpen(name, providerId)}> ASK Question </a></button>
                        <button type="button" className="btn btn-default"><a href onClick={() => handleClickMessageDialogOpen(name, providerId)}> Send Mail </a></button>
                    </div>
                </div>
                <div className={classes.publicTopRight}>
                    <div>
                        <div className={classes.publicTopDay}>
                            {workHourList.map(workHour => (
                                <div key={workHour.id}>{workHour.day} : {workHour.start} ~ {workHour.end}</div>
                            ))}
                    </div>
                </div>
            </div>            
        </div>
    	<hr />
	    <div>
            Review list
        </div>
        <hr />
        {insuranceList && insuranceList.length > 0 &&
            <section id="gete-project" className="gete-project">
                <div>Insurance</div>
                <SimpleList
                    rowData={insuranceList}
                    trs={['Name', 'Company', 'Valid by']}
                    history={history}
                />
            </section>}
        {permitList && permitList.length > 0 &&
            <section id="gete-project" className="gete-project">
            <div>Permit</div>
            <SimpleList
                rowData={permitList}
                trs={['Name', 'Issued At', 'Issued by']}
                history={history}
            />
        </section>}
        <div>
        </div>
        </div>
    );
};

export default withStyles(styles)(Public);
