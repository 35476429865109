import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateMessageDialog } from '../../actions/view';
import { fetchGetActivationStatus, fetchSaveAccountActivation } from '../../actions/api';
import AccountActivation from '../components/AccountActivation';


const mapStateToProps = (state, ownProps) => {
    return {
        user: state.ee.user,
        accountActivation: state.setting.accountActivation,
        history: ownProps.history,
    }
};

const mapDispatchToProps = dispatch => ({
    getActivationStatus: () => {
        dispatch(fetchGetActivationStatus());
    },

    handleSaveAccountActivationAction: (type) => {
        dispatch(fetchSaveAccountActivation(type));
    },

    handleClickMessageDialogOpen: (replyDisplay, replyId) => {
        const message = {
            open: true,
            replyDisplay,
            replyId,
        }
        dispatch(updateMessageDialog(message));
    },
});

class AccountActivationContainer extends Component {
    componentDidMount() {
        this.props.getActivationStatus();
    }
    
    componentWillReceiveProps(newProps) {
    }

    render() {
        return (
            <AccountActivation 
                user={this.props.user}
                accountActivation={this.props.accountActivation}
                handleSaveAccountActivationAction={this.props.handleSaveAccountActivationAction}
                history={this.props.history}/>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AccountActivationContainer);