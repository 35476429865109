import '@babel/polyfill';
import React from 'react';
import { render } from 'react-dom';
import 'tachyons/css/tachyons.css';
import 'bootstrap/dist/css/bootstrap.css';
import configureStore from './util/configureStore';
import Root from './components/Root';
import { fetchUser } from './actions/api';
import './gete.scss';
import * as serviceWorker from './serviceWorker';

const store = configureStore();
// store.dispatch(loadEnv());
store.dispatch(fetchUser())

document.addEventListener('DOMContentLoaded', () => {
    render(
        <Root store={store} />,
        document.getElementById('app')
    );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
