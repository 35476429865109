export const TERMS_OF_USE_PROVIDER=`
    <h3>GetElectrician</h3>

    <h4>These Service Provider Terms of Use were last modified on March 13, 2017.</h4>

    <div>
        The below Service Provider Terms of Use apply to those electrical service providers who register on our Web site and/or Mobile App to provide electrical services. The Web site and Mobile App are provided by Xavior, Inc., d/b/a GetElectrician, a New York corporation with a principal place of business located at 40 First Avenue, #15C, New York, NY 10009.
    </div>

    <h4>SECTION I: DEFINITIONS</h4>

    <h5>
        References to “Dispute” mean any claim, conflict, controversy, or disagreement between the Parties arising out of, or related in any way to, these Terms (or any Terms, supplement or amendment contemplated by these Terms,) including, without limitation, any action in tort, contract or otherwise, at equity or at law, or any alleged breach, including, without limitation, any matter with respect to the meaning, effect, validity, performance, termination, interpretation or enforcement of these Terms or any terms contemplated by the Terms.
    </h5>
    <h5>
        References to a “Client” mean an individual and/or entity who seeks the services of a Service Provider via a Listing on the Web site and/or the Mobile App.
    </h5>
    <h5>
        References to a “Listing” means a description of project needing the services of a Service Provider.
    </h5>
    <h5>
        References to a “Material Breach” mean any breach of these Terms upon the occurrence of which a reasonable person in the position of the non-breaching Party would wish to immediately terminate these Terms because of that breach.
    </h5>
    <h5>
        References to the “Mobile App” mean the mobile software application by the name of “eelectricians,” available on both the Google Play and iTunes stores.
    </h5>
    <h5>
        References to “you,” “your” or “Service Provider” mean any person who offers electrician services via the Web site and/or the Mobile App.
    </h5>
    <h5>
        References to the “Terms” and/or “Agreement,” mean this, these Terms of Use, as set forth herein.
    </h5>
    <h5>
        References to “us,” “we,” “our,” and/or “GetElectrician,” means Xavior, Inc., d/b/a GetElectrician, and its parents, subsidiaries, and assigns.
    </h5>
    <h5>
        References to the “Web site” mean the Web site bearing the URL www.getelectrician.com.
    </h5>
    <h5>
        References to “User” mean the user of the Web site and/or the Mobile App, either as a Client or Service Provider.
    </h5>
    <h4>
        SECTION II: GENERAL PROVISIONS
    </h4>
    <h5>
        2.1. 	Agreement to be Bound.
    </h5>
    <div>
        The following Service Provider Terms of Use, together with the relevant information set out on the Web site and the Mobile App, including any features and services available, such as RSS feeds, podcasts, video and photographs, publications, and other materials, are subject to the terms and conditions set forth below. Please read them carefully as any of use of the Web site and/or the Mobile App as a Service Provider constitutes an agreement, without acceptance, to be bound thereby. By using the Web site or the Mobile App, and, upon registration therefor as a Service Provider, by clicking that you agree to these Terms, you represent that you are at least eighteen (18) years old, have read and understand the Service Provider Terms of Use, and that you agree to be bound by these Service Provider Terms of Use as set forth below.
    </div>
    <div>
        These Service Provider Terms of Use are subject to the Privacy Policy and our general Terms of Use, which also govern your use of the Web site and the Mobile App.
    </div>

    <h5>
        2.2. 	Modifications and Changes to Service Provider Terms of Use.
    </h5>

    <div>
        We may modify, add to, suspend or delete these Service Provider Terms of Use or other agreements, in whole or in part, in our sole discretion at any time, with such modifications, additions or deletions being immediately effective upon their posting to the Web site and the Mobile App. Your use of the Web site and/or the Mobile App after modification, addition or deletion of these Service Provider Terms of Use shall be deemed to constitute acceptance by you of the modification, addition or deletion. Such modifications may include but shall not be limited to pricing and Plan level structuring.
    </div>

    <h4>
        SECTION III: SERVICE PROVIDER PLANS
    </h4>

    <div>
        In addition to those terms and conditions governing accounts, generally, as set for in Section III of the general Terms of Use, the following Plan level terms shall further apply.
    </div>

    <h5>
        2.1.	Plan Levels.
    </h5>
    <div>
        Upon registration for an Account (as defined in the general Terms of Use,) Service Provider shall select one (1) of three (3) Service Provider plan levels (“a Plan.”) These Plan levels are defined as follows and are subject to change in our sole and exclusive discretion: (1) licensed electrical company; (2) licensed electrician, individually; (3) unlicensed electrician, tradesman, or “handy-man,” individually.
    </div>
    <h5>
        3.2. 	License Verification.
    </h5>
    <div>
        Though we are under no obligation to verify the identity of any User of our Web site and/or Mobile App, we may, in our sole and exclusive discretion, request verification of the licensure of any Service Provider who registers as a member. In the event verification is requested, Service Provider must provide a copy of his, her or its electrician’s license, together with a copy of the Service Provider’s business license (if applicable,) and another form of identification (such as a driver’s license, state-issued photo id, or passport,) within forty-eight (48) calendar hours of such request. Failure to provide such information may result in suspension or termination of an Account, without recourse or refund.
    </div>

    <h5>
        3.3. 	Upgrades and Downgrades.
    </h5>
    Service Provider may elect to opt for a lower-priced Plan (“downgrade”) or a higher-priced Plan (“upgrade”) at any time. In addition to the further payment terms contained herein, should you upgrade your plan, you shall pay the difference between the amount you paid and the then-current plan price for the upgraded plan for the remainder of the billing period. Should you elect to downgrade your plan you shall not be entitled to a full or partial refund for any reason whatsoever.

    <h5>
    3.4.	Compliance with Laws.
    </h5>
    In addition to the other requirements to comply with laws, regulations, and terms set forth herein, Service Provider shall, at all times, comply with and obey any and all local, state, and federal regulations and laws regarding the provision of electrical (or other, trade-related) services and, if applicable, business operation and maintenance of Service Provider’s electrical license.

    <h5>
    3.5. 	Cancellation.
    </h5>
    Service Providers may cancel their Accounts at any time by writing to us at customerservice@getelectrician.com. Requests for cancellation received fewer than thirty (30) calendar days’ prior to the next Billing Cycle shall not take effect until the following Billing Cycle.

    <h4>
        SECTION IV: FEES
    </h4>

    <h5>
        4.1. 	Free Trial.
    </h5>

    As of the effective date of these Service Provider Terms of Use, the use of our Web site and Mobile App is free for all Users, including Service Providers. On or around August 1, 2017, the use of the Web site and Mobile App shall no longer be free for Service Providers, and, instead, a Membership Fee, as set forth in Section 4.2., shall be due and owing unless Service Provider cancels his, her or its Plan no later than July 1, 2017.

    <h5>
        4.2. 	Plan Fee.
    </h5>

    In exchange for participation in our marketplace and the ability to view Listings, apply for Listings, and complete projects, Service Provider agrees to pay to us a fee corresponding to the Plan selected by Service Provider upon Account registration, (“Plan Fee.”) This Plan Fee shall be in an amount set forth at the time of Account registration and at all times on the Web site. The Plan Fee may be paid on a monthly, quarterly, or annual basis, (the “Billing Cycle,”) as selected by Service Provider upon Account registration. We reserve the right to change the Plan Fee, or any other applicable fees and charges and to institute new charges and Plan Fees at the end of each Billing Cycle, upon thirty (30) calendar days’ prior notice to Service Provider (which may be sent by email).

    <h5>
        4.3. 	Pre-Payment Discount.
    </h5>

    In the event Service Provider elects to pre-pay the Plan Fee on a quarterly or annual basis, Service Provider may be entitled to a pre-payment discount as set forth at the time of Account registration. Pre-payment discounts are given in our sole and exclusive discretion.

    <h5>
    4.4. 	Automatic Billing.
    </h5>
    Except for in the event of a free trial, all Plan Fees as set forth herein shall be pre-paid, in full as per the selected Billing Cycle on the first (1st) day of the Billing Cycle, where the first (1st) day of the first (1st) Billing Cycle shall be the date upon which Service Provider registers his, or its Account. Any and all free trials that are not cancelled by Service Provider prior to August 1, 2017, shall be automatically billed, in full for the Plan Fee as per a monthly Billing Cycle, on August 1, 2017, which shall be treated as the first (1st) day of the first (1st) Billing Cycle for such Users.

    <h5>
    4.5. 	Form of Payment.
    </h5>
    Payment shall be made by via our payment processor by automatic debit of the major credit card provided by Service Provider upon Account registration or as later updated by Service Provider.

    <h5>
    4.6.	Project Fees.
    </h5>
    Each project completed via the Web site and/or the Mobile App shall result in the payment of a fee from the Client to the Service Provider, where said fee is processed through our payment processor and/or escrow service, if applicable. In addition to a Plan Fee, Service Provider agrees to grant to us a commission on any and all such project fees, (a “Project Fee.”) The amount of such Project Fee shall be reflected as a percentage (%) of the total project price in the amount set forth on the Web site and at the time Service Provider proposes to a Client to provide service.

    <h5>
        4.7. 	Chargebacks and Revocations.
    </h5>
    If the completion or non-completion of a project results in a chargeback, revocation or other refund, Service Provider agrees that we may deduct the sum charged back, together with any fee paid to our service provider and bank, from any sum due to Service Provider, at or after that time. If, in our sole and exclusive discretion, we believe that Service Provider’s use of the Web site and/or the Mobile App results in a significant number of chargebacks and/or disputes, or if we believe Service Provider is in breach of these Service Provider Terms of Use or the general Terms of Use, we are free to hold back payments to Service Provider until we are satisfied that disputes have been settled and/or breaches rectified.

    <h5>
        4.8. 	Withholding of Funds.
    </h5>
    Where we, in our reasonable opinion, are required to do so by law, we reserve the right to withhold funds that we reasonably deem suspicious with regard to money laundering, Unauthorized Business (as defined in Section 4.2 of the general Terms of Use,) fraud or other illegal activities, or in case of any other chargebacks or revocations as set forth in Section 4.7 hereof.

    <h5>
        4.9. 	Payouts.
    </h5>
    When you create your Service Provider Account, you shall have the opportunity to designate a deposit account into which you wish to be paid out. Once funds related to a project have been cleared, any and all funds due and owing shall be available for withdraw to your designated deposit account, less any Project Fees and ancillary fees, if applicable, or unless withheld as per Sections 4.7 and 4.8 hereof. You may view all pending transactions, settlement data, fees, and revenues via your Account. Except as required by law, you shall remain solely and exclusively responsible for retaining permanent records of all transactions related to or arising from your use of the Mobile App and/or the Web site.

    <h5>
    4.10.	Responsibility for Accurate Pay Out Information.
    </h5>
    You agree to provide current, complete and accurate deposit account information for payout. You agree to promptly update your account and other information, including your address, email address and bank account information so that we can complete your transactions and contact you as needed.

    <h5>
        4.11.	Payout Schedule.
    </h5>
    Accrued compensation in the amount of One Hundred U.S. Dollars and No/Cents ($100.00) or more shall be automatically paid out into your designated deposit account on the first (1st) business day of the month following accrual. Accrued compensation in an amount less than One Hundred U.S. Dollars and No/Cents ($100.00) shall not be automatically paid out and must be requested by the Service Provider via Service Provider’s Account. Payouts may be subject to bank transfer fees levied by a Service Provider’s financial institution for which Service Provider shall be solely and uniquely responsible. Compensation shall not be deemed to have accrued until such projected-related fees are received by us, which may take, in some instances, thirty (30) calendar days or more.

    <h5>
    4.12. 	Right to Set-Off; Collection Rights.
    </h5>
    We are entitled to set-off any and all claims against amounts payable to you. In addition, to the maximum extent provided by law, we may collect any and all obligations due and owing by you to us by deducting them from your pending transactions in the deposit account. Fees shall be assessed at the time of transaction processing and will be deducted from the funds received. Your failure to pay all amounts due and owning shall be deemed an immediate breach of this Agreement for which you will be liable. You further agree to pay all fees and costs, including but not limited to attorneys’ fees and costs, incurred by or on our behalf arising from or related to the collection of any unpaid obligations by you.
    <h5>
        4.13.	Payment of Taxes, Benefits.
     </h5>
    You agree and acknowledge that we do not provide state or federal withholdings from commission checks nor supply any other benefits customarily affiliated with an employer-employee relationship.  We will supply, in accordance with IRS regulations, customary IRS Form 1099 each year as to Service Provider’s commission earnings.  You agree and acknowledge you are solely responsible for the required payment of all state and federal income taxes, self-employment taxes, and any other tax levied by any state, federal, regulatory, or taxing agency arising by virtue of my activities under this Agreement and under penalties of perjury, you certify that, the social security or employer identification number shown on you W-9 form is your correct taxpayer identification number.
     <h5>
        4.14. 	Payment of Fees Earned in the Event of Account Suspension or Termination.
    </h5>
    In the event your Account is suspended or terminated, the payment of any and all earned fees shall continue to be paid, whether or not cleared prior to the suspension and/or termination date. In such an event, we may elect to make payment to you via paper check to the address you provided upon Account registration or as otherwise updated by you.

    <h5>
        4.15.  	Refunds.
    </h5>

    Due to the electronic nature of the services that we provide, in no event other than material breach of these Terms by GetElectrician shall a refund be granted, in whole or in part, for any reason or no reason whatsoever, including but not limited to Service Provider’s cancellation of a Plan prior the end of the Billing Cycle, Service Provider’s failure to utilize the Web site and/or Mobile App, and/or Service Provider’s failure to obtain any project via the Web site and/or Mobile App.

    <h4>
        SECTION V: DISCLAIMERS; LIMITATIONS OF LIABILITY; INDEMNIFICATION
    </h4>

    <h5>
        5.1. 	Disclaimer of Warranty; Limitation of Liability.
    </h5>

    <ul>
        <li>
            (A) YOU AGREE THAT USE OF THE WEB SITE AND/OR THE MOBILE APP IS AT YOUR SOLE RISK. NEITHER US nor our AFFILIATES NOR ANY RESPECTIVE DIRECTORS, OFFICERS, USERS, EMPLOYEES, CONTRACTORS, PARENTS, SUBSIDIARIES, AGENTS, THIRD PARTY CONTENT PROVIDERS or LICENSORS, WARRANT THAT THE use of the Web site OR THE SERVICE PROVIDER SHALL BE UNINTERRUPTED OR ERROR FREE; NOR DO we MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE WEB SITE AND/OR THE MOBILE APP OR AS TO THE ACCURACY, RELIABILITY OR CONTENT OF ANY INFORMATION PROVIDED.
        </li>
        <li>
            (B) ANY DOWNLOADABLE SOFTWARE, PRODUCTS OR OTHER MATERIALS, WITHOUT LIMITATION, IS PROVIDED ON AN "AS IS" AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE AGAINST INFRINGEMENT, OTHER THAN THOSE WARRANTIES WHICH ARE IMPLIED BY AND INCAPABLE OF EXCLUSION, RESTRICTION OR MODIFICATION UNDER THE LAWS APPLICABLE TO THESE TERMS OF USE.
        </li>
        <li>
            (C) ALTHOUGH ALL INFORMATION AND MATERIALS CARRIED ON THE WEB SITE ARE BELIEVED TO BE RELIABLE, WE MAKE NO REPRESENTATIONS, NEITHER EXPRESSLY NOR IMPLIEDLY, AS TO THE ACCURACY, COMPLETENESS, TIMELINESS OR RELIABILITY OF THE WEB SITE.
        </li>
        <li>
            (D) IN NO EVENT SHALL WE, our AFFILIATES NOR ANY RESPECTIVE DIRECTORS, OFFICERS, USERS, EMPLOYEES, CONTRACTORS, PARENTS, SUBSIDIARIES, AGENTS, THIRD PARTY CONTENT PROVIDERS or LICENSORS BE LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSS OR DAMAGES WHATSOEVER, INCLUDING BUT NOT LIMITED TO ANY DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL, INCIDENTAL, PUNITIVE, OR OTHER DAMAGES, INCLUDING BUT NOT LIMITED TO EXEMPLARY, RELIANCE, OR CONSEQUENTIAL DAMAGES, LOSS OF PROFITS, DAMAGES RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION, personal injury or death, property damage, EXEMPLARY REPUTATIONAL HARM, OR LOSS OF INFORMATION OR DATA, ARISING OUT OF OR RELATED TO THE USE OF OR INABILITY TO USE THE WEB SITE AND/OR THE MOBILE APP, ANY INTERACTIONS WITH A USER, AND/OR ANY employment and/or employee/Client relationships facilitated by or through the web site AND/OR THE MOBILE APP.
        </li>
        <li>
            (E) WE DISCLAIM ANY AND ALL LIABILITY OF ANY KIND FOR ANY UNAUTHORIZED ACCESS TO OR USE OF YOUR PERSONALLY-IDENTIFIABLE INFORMATION. BY utilizing THE WEB SITE AND/OR THE MOBILE APP YOU ACKNOWLEDGE AND AGREe TO OUR DISCLAIMER OF ANY SUCH LIABILITY. IF YOU DO NOT AGREE, YOU SHOULD NOT ACCESS OR OTHERWISE Utilize THE WEB SITE or the mobile app.
        </li>
        <li>
            (F) GetElectrician' LIABILITY, AND (AS APPLICABLE) THE LIABILITY OF our AFFILIATES AND ANY RESPECTIVE DIRECTORS, OFFICERS, USERS, EMPLOYEES, CONTRACTORS, PARENTS, SUBSIDIARIES, AGENTS, THIRD PARTY CONTENT PROVIDERS AND LICENSORS TO YOU OR ANY THIRD PARTIES IN ANY CIRCUMSTANCE IS LIMITED TO THE GREATER OF (A) THE AMOUNT OF FEES YOU PAY TO GetElectrician IN THE TWELVE (12) MONTHS PRIOR TO THE ACTION GIVING RISE TO LIABILITY, OR (B) ONE HUNDRED U.S. DOLLARS ($100.00,) WHICHEVER IS GREATER.
        </li>
    </ul>

    SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO CERTAIN USERS.

    The above limitations shall survive these Terms and inure to the benefit of us and our affiliates and respective directors, officers, Users, employees, contractors, parents, subsidiaries, agents, Third party content providers and licensors.

    <h5>
        5.2. 	INDEMNIFICATION.
    </h5>
    You agree to defend, indemnify and hold us harmless, as well as our AFFILIATES NOR ANY RESPECTIVE DIRECTORS, OFFICERS, USERS, EMPLOYEES, CONTRACTORS, PARENTS, SUBSIDIARIES, AGENTS, THIRD PARTY CONTENT PROVIDERS or LICENSORS, from and against all claims, SUITS, and expenses, including attorneys' fees, arising out of OR RELATED TO (a) YOUR USE OF THE WEB SITE AND/OR THE MOBILE APP; (B) your noncompliance with or breach of this Agreement; (C) PROVISION OR NON-PROVISION OF SERVICES RELATED TO OR ARISING FROM A LISTING; (D) YOUR VIOLATIONS OF ANY THIRD PARTY RIGHTS, INCLUDING THIRD PARTY INTELLECTUAL PROPERTY RIGHTS; (E) the unauthorized use of the Web site AND/OR THE MOBILE APP by any other person using your information; AND (E) YOUR RELATIONSHIP WITH ANY CLIENT OR OTHER USER. GetElectrician shall provide notice to you promptly of any such claim, suit, or proceeding and may assist you, at your expense, in defending any such claim, suit or proceeding.

    <h4>
        SECTION VI: NON-CIRCUMVENTION
    </h4>

    Service Provider expressly recognizes the valuable service provided to his, her or it by GetElectrician, and, therefore, expressly agree to not-circumvent this agreement and/or otherwise seek to conduct an electrician, trade worker, or “handy-man” relationship with any Client via any means other than through the Web site and/or Mobile App for a period of two (2) years, (the “Non-Circumvention Period,”) following the date on which Service Provider first identifies or is identified by any Client via the Web site and/or Mobile App. During this Non-Circumvention Period, Service Provider agrees that he, she or it must request, make and receive all payments for projects, directly or indirectly, through the Web site and/or Mobile App.  In the event this provision is breached, Service Provider agrees that he, she, or it shall be liable for any and all lost E-Electrician Project Fees for the duration of this Non-Circumvention period, calculated as the average monthly Project Fee earned by us based on the services of the Service Provider, over a period of the previous twelve (12) months multiplied by the remaining term of the Non-Circumvention Period, pro rata.

    <h4>
        SECTION VII: GOVERNING LAW; ARBITRATION
    </h4>

    <h5>
    7.1. 	Governing Law.
    </h5>

    These Service Provider Terms of Use shall be governed and construed in accordance with the laws of the state of Michigan without regard to its conflicts of law provisions. You agree to submit to the personal jurisdiction of the courts located in New York County, New York, and any cause of action that relates to or arises from these Terms, Web site and/or the Mobile App must be filed therein unless subject to the binding arbitration provisions of Section 7.2, infra.

    <h5>
        7.2. 	Arbitration.
    </h5>

    The Parties agree that any dispute concerning, relating, or referring to these Service Provider Terms of Use, Web site, and/or the Mobile App shall be resolved exclusively by binding arbitration in accordance with the substantive laws of the state of New York and shall be brought for arbitration in New York County, New York, pursuant to the Commercial Arbitration Rules of the American Arbitration Association. The arbitrator and not any federal, state, or local court or agency shall have exclusive authority to resolve any dispute relating to the interpretation, applicability, enforceability, conscionability, or formation of this contract, including but not limited to any claim that all or any part of this contract is void or voidable. Nothing herein prevents either Party from seeking any interim injunction it deems necessary in order to preserve the status quo prior to the resolution of any dispute, in any jurisdiction. Either or both Parties may initiate the arbitration proceedings, and both shall share the expenses and fees of the arbitration equally.

    <h4>
        SECTION VIII: MISCELLANEOUS
    </h4>
    <h5>
        8.1. 	Authority.
    </h5>

    Each Party represents and warrants to the other that it has full power and authority to enter into this Agreement and that it is binding upon such Party and enforceable in accordance with its Terms.

    <h5>
        8.2. 	Waiver.
    </h5>

    Any waiver of a right under these Terms of Use shall only be effective if agreed or declared in writing. No waiver of any term of this Agreement shall be deemed a further or continuing waiver of such term or any other term and shall not prevent a Party from exercising that right in the future. The rights and remedies herein provided are cumulative and not exclusive of any rights and remedies provided by law.

    <h5>
        8.3. 	Force Majeure.
    </h5>

    We shall not be bound to meet any obligation if prevented from doing so as a consequence of acts of god or force majeure, including but not limited to measures taken or imposed by any government or public authority or in case of any other event beyond our control, including but not limited to natural disasters (such as storm, hurricane, fire, flood, earthquake), war, civil unrest, terrorist activities, states of emergency, government sanctions, embargos, nationalizations, strikes and breakdowns of public utilities (such as of electricity or telecommunication services). We shall use all reasonable efforts to notify you of the circumstances causing the delay and to resume performance as soon as possible, both without undue delay.

    <h5>
        8.4. 	Assignment.
    </h5>

    We shall have the right to assign and/or transfer these Terms of Use and our rights and obligations hereunder to any third party after notifying you as provided for herein. You agree and acknowledge that you shall not assign or transfer its rights or sub-contract or delegate the performance of any of your obligations under these Terms of Use without our prior written consent in our sole and exclusive discretion or as provided for herein.

    <h5>
        8.5. 	Rights of Third Parties.
    </h5>

    These Terms do not give any right to any third party unless explicitly stated herein.

    <h5>
        8.6. 	Relationship of the Parties.
    </h5>

    The Parties are independent contractors under these Terms, and nothing herein shall be construed to create an employment agreement, partnership, joint venture, franchise relationship or agency relationship between them. Neither Party has authority to enter into Terms of any kind in the name of the other Party. You are an independent contractor responsible for controlling your own time and actions. GetElectrician is not to be considered a Client, and GetElectrician will not be responsible in any way for any employment decision made by any User and/or Client.

    <h5>
        8.7. 	Severability.
    </h5>

    If any part of this Agreement is determined to be invalid or unenforceable by applicable law, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of this Agreement will continue in effect.

    <h5>
        8.8.	Notices.
    </h5>

    Except as explicitly stated otherwise, any notices shall be given by postal mail to Xavior, Inc., d/b/a GetElectrician, a New York corporation with a principal place of business located at 40 First Avenue, #15C, New York, NY 10009, or, in the case of any User, to the email address you provide to us (either during the registration process or when your email address changes.) Notice shall be deemed given twenty-four (24) hours after email is sent, unless the sending party is notified that the email address is invalid.  Alternatively, we may give you notice by certified mail, postage prepaid and return receipt requested, to the address provided to us upon Account registration. In such case, notice shall be deemed given three (3) days after the date of mailing.

    <h5>
        8.9. 	Entire Agreement.
    </h5>

    This Agreement constitutes the entire Agreement between the Parties as to the subject matter hereof, and no other representations or promises of any kind shall be binding between the same. If any portion of this Agreement is held to be invalid, the balance of this Agreement shall remain in full force and effect.
    `;