import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import  { Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import PasswordField from '../components/PasswordField';
import EmailField from '../components/EmailField';
import clsx from 'clsx';

//if user is defined redirect to dashboard
const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%'
    },
    wide: {
        width: '100%',
        maxWidth: '340px'
    },
    margin: {
        margin: '0 auto'
    },
    smallFont: {
        fontSize: '16px',
    }
});

const Login = (props) => {
    const {
        classes,
        login,
        user,
        history,
        handleEmailChange,
        handlePasswordChange,
        onSubmitClick,
    } = props;

    if(user && user.userType !== 's') {
        return <Redirect to='/dashboard' />
    }

    const enabled = login.email && login.password;
    return (
        <Container maxWidth="lg">
            <div className={clsx(classes.root, "flex")}>
                <form className="form-signin" name="loginFrom" role="form">
                    <h2 className="form-signin-heading">Log in <span className={clsx(classes.smallFont, "ee-color")}>GetElectrician</span></h2>
                    <EmailField
                        id="outlined-email-input"
                        label="Email"
                        name="email"
                        email={login.email}
                        onChange={handleEmailChange}
                        required={true}
                    />
                    <PasswordField
                        fieldId="password"
                        label="Password"
                        type="password"
                        margin="normal"
                        variant="outlined"
                        showStrength={false}
                        required={true}
                        password={login.password || ''}
                        onChange={handlePasswordChange}
                    />
                    <div className="submit-ee">
                        <Button 
                            disabled={!enabled}
                            variant="contained" 
                            component="span" 
                            onClick={(event) => onSubmitClick(event, history)}
                            className={enabled ? clsx(classes.wide, 'ee-bg-color', 'flex') : clsx(classes.wide, 'flex')}
                        >
                            Submit
                        </Button>
                        <a className="btn btn-link" href="/register/new">Register</a>
                        <a href="forgotPassword"><span><span className="highlight"><u>forgot password?</u></span></span></a>
                    </div>
                </form>
            </div>
        </Container>
    );
};

Login.propTypes = {
    handleEmailChange: PropTypes.func.isRequired,
    handlePasswordChange: PropTypes.func.isRequired,
};

Login.defaultProps = {
    displayColorPicker: {}
};

export default withStyles(styles)(Login);