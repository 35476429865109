import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import TitleContainer from '../../containers/TitleContainer';

const styles = theme => ({
    container: {
        display: 'block',
        flexWrap: 'wrap',
        arginLeft: 'auto',
        marginRight: 'auto',
    },
    wide: {
        width: '100%',
        maxWidth: '340px'
    },
    narrow: {
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    margin: {
        margin: 'auto',
        display: 'flex',
    },
    scope: {
        width: '100%',
        maxWidth: '340px'
    },
    margin20: {
        marginBottom: '20px',
    },
    flex: {
        display: 'flex',
    }
});

const EditProject = (props) => {
    const {
        classes,
        user,
        contact,
        history,
        handleChange,
        onSendRequestClick,
    } = props;

    const disabled = !contact.subject || !contact.comment;
    return (
        <Container maxWidth="lg">
            <TitleContainer 
                history={history} 
                title="Get help with getElectrician" />

            <div className={clsx(classes.margin20)}>
                <TextField
                    required
                    id="subject"
                    label="Subject"
                    className={clsx(classes.wide, classes.margin)}
                    variant="outlined"
                    value={contact.subject}
                    onChange={(event) => handleChange('subject', event)}
                    margin="normal"
                    // defaultValue="subject"
            />
            </div>
        <div className={clsx(classes.margin20)}>
            <TextField
                id="comment"
                label="How can we help"
                variant="outlined"
                multiline
                rowsMax="8"
                minRows="8"
                value={contact.comment}
                onChange={(event) => handleChange('comment', event)}
                className={clsx(classes.wide, classes.margin)}
                margin="normal"
                helperText= "Please describe exactly what you are trying to accomplish..."
            />
        </div>
        <div className={clsx(classes.wide, classes.margin, classes.margin20)}>
            <Button 
                disabled={disabled}
                variant="contained" 
                component="span" 
                onClick={() => onSendRequestClick(history)}
                className={disabled ? clsx(classes.wide, 'flex') : clsx(classes.wide, 'ee-bg-color', 'flex')}
            >
                Send Request
            </Button>
        </div>
    </Container>
    );
}

export default withStyles(styles)(EditProject);