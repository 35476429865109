import * as types from '../constants/actionTypes';

export const updateRegisterValue = (name, value) => ({
    type: types.UPDATE_REGISTER_VALUE,
    name,
    value,
});

export const updateStep = (step) => ({
    type: types.UPDATE_STEP,
    step,
})

export const updateCompanyValue = (name, value) => ({
    type: types.UPDATE_COMPANY_VALUE,
    name,
    value,
});

export const updateLicenseValue = (name, value) => ({
    type: types.UPDATE_LICENSE_VALUE,
    name,
    value,
});

export const updateUserCompany = (userCompany) => ({
    type: types.UPDATE_USER_COMPANY,
    userCompany,
})

export const updateUserLicense  = (userLicense) => ({
    type: types.UPDATE_USER_LICENSE,
    userLicense,
})

export const updateWorkZoneState = (workZoneState) => ({
    type: types.UPDATE_WORK_ZONE_STATE,
    workZoneState,
})

export const updateWorkZoneCounty = (state, workZoneCounties) => ({
    type: types.UPDATE_WORK_ZONE_COUNTY,
    state,
    workZoneCounties,
})

export const updateAddressValue = (name, value) => ({
    type: types.UPDATE_ADDRESS_VALUE,
    name,
    value,
});

export const receivedProfile = (profile) => ({
    type: types.RECEIVED_PROFILE,
    profile,
})

export const updateWorkHourValue = (name, value) => ({
    type: types.UPDATE_WORKHOUR_VALUE,
    name,
    value,
});

export const updateInsuranceValue = (name, value) => ({
    type: types.UPDATE_INSURANCE_VALUE,
    name,
    value,
});

export const updatePermitValue = (name, value) => ({
    type: types.UPDATE_PERMIT_VALUE,
    name,
    value,
});

export const updatePhoneValue = (value) => ({
    type: types.UPDATE_PHONE_VALUE,
    value,
});

export const updateProfileImageFile = (value) => ({
    type: types.UPDATE_PROFILE_IMAGE_FILE_VALUE,
    value,
});

export const updateHourlyRate = (name, value) => ({
    type: types.UPDATE_HOURLY_RATE,
    name,
    value,
});
