import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import DeleteIconSvg from '../../icons/DeleteIconSvg';
import MessageIconSvg from '../../icons/MessageIconSvg';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        width: "100%",
        minWidth: 700,
    },
    th: {
        paddingLeft: '2px',
        paddingRight: '2px',
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    project: {
        cursor: 'pointer',
        paddingLeft: '2px',
        paddingRight: '2px',
        fontWeight: '600',
        color: '#3cb63c',
    },
});

const ProposalList = (props) => {
    const {
        classes,
        user,
        proposals,
        projectId,
        dashboardTrs,
        history,
        handleClickMessageDialogOpen,
        handleProposalDeleteClick,
    } = props;

    if (user.userType === 'c') {
        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                    <TableRow>
                        {dashboardTrs.map(tr => (
                            <TableCell className={classes.th} >{tr}</TableCell>
                        ))}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {proposals.map(row => (
                            <TableRow className={classes.row} key={row.id}>
                                <TableCell 
                                    className={classes.th} 
                                    align="center"
                                >{row.projectId}</TableCell>
                                <TableCell 
                                    className={classes.th} 
                                    align="center"
                                >{row.companyName ? row.companyName : row.providerDisplayName}</TableCell>
                                <TableCell className={classes.project} align="left"
                                    onClick={() => { history.push(`/project/${row.projectId}`) }}
                                >
                                    {row.scopes.map(scope => (
                                        <TableRow>
                                            {scope.description}
                                        </TableRow>
                                    ))}    
                                </TableCell>
                                <TableCell className={classes.th} align="right">{row.total}</TableCell>
                                <TableCell className={classes.th} align="right">{row.estimatedTime}</TableCell>
                                <TableCell className={classes.th} align="right">{row.startDate}</TableCell>
                                <TableCell className={classes.th} align="right">{row.createdDate}</TableCell>
                                <TableCell className={classes.th} align="right">{row.updateDate}</TableCell>
                                <TableCell className={classes.th} align="right"> 
                                    <IconButton aria-label="Message" className={classes.tableIcon} onClick={() => handleClickMessageDialogOpen(row.providerDisplayName, row.providerId, projectId)}>
                                        <MessageIconSvg />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
            </Paper>
        );
    
    } else {
        // Provider doesn't need the following columns
        const hiddenColumns = ['Name'];

        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                    <TableRow>
                        {dashboardTrs.filter(col => {
                            if (hiddenColumns.indexOf(col.trim()) !== -1) return false;
                            return true;
                        }).map(tr => (
                            <TableCell className={classes.th} >{tr}</TableCell>
                        ))}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {proposals.map(row => (
                            <TableRow className={classes.row} key={row.id} onClick={() => { history.push(`/project/${row.projectId}`) }}>
                                <TableCell className={classes.project}>{row.projectId}</TableCell>
                                <TableCell className={classes.th}>
                                    {row.scopes.map(scope => scope.description)}
                                </TableCell>
                                <TableCell className={classes.th}>{row.total}</TableCell>
                                <TableCell className={classes.th}>{row.estimatedTime}</TableCell>
                                <TableCell className={classes.th}>{row.startDate}</TableCell>
                                <TableCell className={classes.th}>{row.createdDate}</TableCell>
                                <TableCell className={classes.th}>{row.updateDate}</TableCell>
                                <TableCell className={classes.th}>
                                    <Fab
                                        size="small"
                                        aria-label="Delete"
                                        className={classes.icon}
                                        onClick={() => handleProposalDeleteClick(row.id)}
                                        id="icon"
                                    >
                                        <DeleteIconSvg />
                                    </Fab>
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
            </Paper>
        );    
    }
};

export default withStyles(styles)(ProposalList);

