import React from 'react';
import zxcvbn from 'zxcvbn';
import clsx from 'clsx';
import { validate } from 'isemail';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%',
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 200,
        },
    },
    wide: {
        width: '100%',
        maxWidth: '340px'
    },
    margin: {
        marginBottom: '4px !important',
        marginTop: '4px !important',
    }
});

const EmailField = (props) => {
    const {
        classes,
        email,
        onChange,
        errors,
        required,
        fieldId,
        label,
        placeholder,
        description,
    } = props;
    const hasErrors = !validate(email);

    return (
        <div>
            {description && 
                <span className="d-block form-hint">{description}</span> 
            }
            <TextField
                id={fieldId}
                label={label}
                variant="outlined"
                type="email"
                name="email"
                margin="normal"
                error={hasErrors}
                helperText={hasErrors ? "Invalid email" : "Enter your email"}
                required={required}
                placeholder={placeholder}
                autoComplete="email"
                value={email}
                onChange={onChange}
                className={clsx(classes.wide, classes.margin)}
            />
        </div>
    );
};

export default withStyles(styles)(EmailField);