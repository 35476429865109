export const CODE_OF_ETHICS=`
    <h3>getElectrician</h3>

    <h4>This Ethics Policy was last modified on April 17, 2020.</h4>

    The following Code of Ethics applies to all Service Providers who offer their services through our Web site and/or Mobile App. This Code of Ethics contains the legal and ethical business practice standards that are required for such Service Providers and is meant to be read alongside the general Terms of Use and our Service Provider Terms of Use, which themselves contain many ethics rules and policies. We view these ethical rules as fundamental standards underlying our approach to doing business, and we expect all Service Providers to adhere to these standards as well. Please review this Code of Ethics frequently and consider providing it to your Clients.

    <h4>Reputation</h4>

    A good reputation is difficult to earn, easy to lose and almost impossible to regain. Not only shall Service Provider endeavor to always work to maintain and improve his, her or its own reputation, but, by providing services via the Web site and/or Mobile App, Service Provider also plays a role in impacting E-Electrician’s reputation as well as that of the industry as a whole. In all events and circumstances, Service Provider must conduct himself, herself or itself in a way that does no damage to the reputation and goodwill of getElectrician and help to maintain its place as a market leader for electrical services.

    <h4>Grievance Management</h4>

    In the event of any grievance, Service Provider shall follow the dispute resolution provisions contained in the Terms of Use and as may be otherwise required by the licensing commission. At all times, Service Provider shall defend the integrity of the trade and its members while also accepting responsibility for truthful grievances and promptly working to correct such issues.

    <h4>Compliance with Laws</h4>

    We expect that each and every Service Provider will conduct his, her or its business in an ethical and proper manner at all times and in full compliance with all local, state, and federal laws, rules, ordinance and regulations.

    <h4>Bribery, Gifts Prohibited</h4>

    Service Provider shall act with integrity of the highest standard in all circumstances. In no event shall a Service Provider accept a bribe or otherwise participate in corruption. This means that no Service Provider shall engage in any form of corruption, bribery, kickback, extortion, embezzlement or money laundering. This also shall be read to prohibit the acceptance of gifts, regardless of monetary value. In the event Service Provider is offered a bribe, gift, kickback, or solicited for any other form of corruption by a Client, Service Provider is encouraged to promptly notify getElectrician in writing at customerservice@getElectrician.com.

    <h4>Public Safety</h4>

    Service Provider must work to maintain his, her or its own safety as well as the safety of contractors, Clients, and the general public. This shall be done by ensuring that all standards of workmanship are beyond reproach and that all work performed strictly complies with the applicable licensing commission regulations.

    Service Provider shall immediately bring to the attention of the proper authorities the existence of any electrical conditions which are unsafe to life and property.

    <h4>Continued Education</h4>

    Service Provider shall never quit learning. By staying abreast of new technologies and processes, participating in industry associations, and attending business-related meetings and functions Service Provider will be able to provide the most energy- and cost-efficient solutions to Clients.

    <h4>Communication</h4>

    All communication with Clients shall be professional, punctual and documented. This applies whether the communication is through the Web site or Mobile App, telephone, email, or in-person. All phone calls, emails, and messages shall be returned within one (1) business hour in the event of an emergency and no later than one (1) business day in non-emergency circumstances.

    <h4>Advertisement of Services, Skills</h4>

    No Service Provider shall falsely advertise himself, herself or itself as having skills appropriate to a specific project. Such misrepresentations hurt not only the reputation of Service Provider but of getElectrician and the industry as a whole. Service Provider shall only take on projects for which he, she or it is qualified and shall let a Client know if a project exceeds such qualifications. All advertisements should be fair, truthful, and in compliance with all governing regulations.

    <h4>Guarantee of Workmanship</h4>

    Service Provider shall stand behind his, her or its work and provide a minimum twelve- (12-) months workmanship guarantee.

    <h4>Staff & Employment</h4>

    Any and all staff and apprentices shall receive training of the highest level. Service Provider shall provide encouragement to and support apprentices and staff to improve work practices, work professionally, and generally raise the standard of the work performed in the electrician trade.

    Service Provider shall in no event discriminate in his, her or its, recruitment, hiring, employment, or retention practices on the basis of race, national origin, gender, age, sexual orientation, citizenship, marital status, disability, veteran status or religion. Service Provider shall provide a workplace free from unlawful harassment.

    Service Provider shall comply with all applicable laws and regulations regarding employment, including but not limited to such laws and regulations related to minimum wage, maximum work hours, overtime and benefits and shall only employ individuals who are above the minimum working age as set forth in applicable laws and regulations. Service Provider shall not engage in, facilitate or use forced or involuntary labor or otherwise facilitate slavery or human trafficking.

    Service Provider shall only employ workers with a legal right to work and shall validate the eligibility to work by reviewing relevant documents, as permitted by applicable laws and regulations.

    <h4>Client Confidentiality</h4>

    Service Provide shall agree to keep any and all Client information strictly confidential. This means that names, address, emails and other private information must not be disclosed except in exceptional circumstances. For more information, please review to the general Terms of Use and our Privacy Policy.

    <h4>On-Site Conduct</h4>

    All work shall be performed safely, sanitarily, efficiently and professionally. Leave any workplace as you found it, in a clean, tidy, and safe condition. While on site, Service Provider shall properly identify himself or herself as an electrician (if applicable) and wear identifying insignia, such as a company-branded polo shirt or cap (if applicable.) Service Provider shall wear at all times appropriate protective equipment and otherwise ensure compliance with applicable environmental, health and safety laws, rules and regulations.

    <h4>Environmental Considerations</h4>

    Service Provider shall at all times act in an environmentally-responsible manner. We recognize the importance of our environment and natural resources, and encourage Service Provider to embrace the responsibility to society for prudently using natural resources and preventing harm to the environment. Every Service Provider shall comply with applicable environmental laws and regulations, including but not limited to obtaining required environmental permits, providing required environmental reports, properly controlling air, land and water emissions and properly disposing of chemicals and waste products.

    <h4>Accounting</h4>

    It is our policy to comply with accepted accounting rules, controls and disclosures at all times and to ensure that all work performed is accurately reported via the Web site and/or Mobile App. No Service Provider shall knowingly participate in any scheme to avoid such accounting rules, controls or disclosures or to otherwise avoid paying a Project Fee as defined in the Service Provider Terms of Use. Service Provider shall not encourage any Client to circumvent his, her, or its relationship with getElectrician.

    <h4>Intellectual Property</h4>

    Every Service Provider shall respect the intellectual property rights of others and shall comply with applicable laws and regulations related to patents, copyrights, trademarks and trade secrets.

    <h4>Conflicts of Interest</h4>

    Service Provider shall use all reasonable measures to ensure no conflict of interest arises.

    <h4>Questions? Comments?</h4>

    In the event a Service Provider has an ethical question, he, she or it is encouraged to contact its regulatory commission and/or E-Electrician’s customer service before deciding on a course of action.
`;