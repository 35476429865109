import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TERMS_OF_USE } from '../register/components/termsOfUse';
import parse from 'html-react-parser';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        padding: '10px',
    },
});


const TermsOfUseContainer = (props) => {
    const {
        classes,
    } = props;

    return (
        <div class={classes.root}>
            {parse(TERMS_OF_USE)}
        </div>
    )
}

export default withStyles(styles)(TermsOfUseContainer);