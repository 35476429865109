import { connect } from 'react-redux';
import {
    updateEmail,
    updatePassword,
} from '../actions/ee';
import Login from '../components/Login';
import { login } from '../actions/api';

const mapStateToProps = (state, ownProps) => {
    return {
        login: state.view.login,
        user: state.ee.user,
        history: ownProps.history,
    }
};

const mapDispatchToProps = dispatch => ({
    handleEmailChange: (email) => {
        dispatch(updateEmail(email.target.value));
    },
    handlePasswordChange: (password) => {
        dispatch(updatePassword(password.target.value));
    },
    onSubmitClick: (event, history) => {
        dispatch(login(history));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
