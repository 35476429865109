import React, { Component } from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
    return {
        user : state.view.user,
        history: ownProps.history,
    }
};

// const mapDispatchToProps = dispatch => ({
// });

class HowItWorksProviderContainer extends Component {
    componentDidUpdate() {
        const { user } = this.props;
    }

    render() {
        return (
            <div className="container">
                <div className="flex">
                    <div className="container">
                        <div className="row">
                            <div className="">
                                <h4>Are you looking for electrical providers?</h4>
                                <div className="pl3">Go to see `How it works` for customer</div>
                                <div className="pl3"><a className="btn btn-default btn-ee" href="howitworks" role="button">View details »</a></div>
                            </div>
                        </div>
                        <div className="pt3">
                            <h2>For electrical providers looking for new customers</h2>
                        </div>
                        <hr className="featurette-divider" />
                        <div className="row featurette">
                            <div className="col-md-7">
                                <h2 className="featurette-heading">Step 1
                                </h2>
                                <p className="lead">rofessional profile</p>
                            </div>
                            <div className="col-md-5">
                                <img className="featurette-image img-responsive center-block" data-src="/../img/provider/1.png" alt="500x500" src="/../img/provider/1.png" data-holder-rendered="true" />
                            </div>
                        </div>
                        <hr className="featurette-divider" />
                        <div className="row featurette">
                            <div className="col-md-7 col-md-push-5">
                                <h2 className="featurette-heading">Step 2.
                                </h2>
                                <p className="lead">Receive project requests from customers seeking electrical providers in your area</p>
                            </div>
                            <div className="col-md-5 col-md-pull-7">
                                <img className="featurette-image img-responsive center-block" data-src="/../img/provider/2.png" alt="500x500" src="/../img/provider/2.png" data-holder-rendered="true" />
                            </div>
                        </div>
                        <hr className="featurette-divider" />
                        <div className="row featurette">
                            <div className="col-md-7">
                                <h2 className="featurette-heading">Step 3.
                                </h2>
                                <p className="lead">Submit proposals and communicate directly with customers</p>
                            </div>
                            <div className="col-md-5">
                                <img className="featurette-image img-responsive center-block" data-src="/../img/provider/3.png" alt="500x500" src="/../img/provider/3.png" data-holder-rendered="true" />
                            </div>
                        </div>
                        <hr className="featurette-divider" />
                        <div className="row featurette">
                            <div className="col-md-7 col-md-push-5">
                                <h2 className="featurette-heading">Step 4.
                                </h2>
                                <p className="lead">Complete electrical project</p>
                            </div>
                            <div className="col-md-5 col-md-pull-7">
                                <img className="featurette-image img-responsive center-block" data-src="/../img/provider/4.png" alt="500x500" src="/../img/provider/4.png" data-holder-rendered="true" />
                            </div>
                        </div>
                        <hr className="featurette-divider" />
                        <div className="row featurette">
                            <div className="col-md-7">
                                <h2 className="featurette-heading">Step 5.
                                </h2>
                                <p className="lead">Get paid for completed project</p>
                            </div>
                            <div className="col-md-5">
                                <img className="featurette-image img-responsive center-block" data-src="/../img/provider/5.png" alt="500x500" src="/../img/provider/5.png" data-holder-rendered="true" />
                            </div>
                        </div>
                        <hr className="featurette-divider" />

                        <footer>
                            <p className="pull-right"><a href="#howitworks_provider">Back to top</a></p>
                        </footer>

                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    null,
)(HowItWorksProviderContainer);
