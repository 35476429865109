import { createSelector } from 'reselect';
import moment from 'moment';

export const SIMPLE_DAY_DATE_FORMAT_REGISTER = 'YYYY-MM-DD';

 //export const getScopes = ({scopes});
const getDashboardProposals = state => state.ee.dashboard.proposals;
const getScopes = state => state.view.scopes;
const getDashboardFromUserMessages = state => state.ee.dashboard.userMessages;
const getDashboardToUserMessages = state => state.ee.dashboard.userMessagesTo;
const getDashboardUserActions = state => state.ee.dashboard.userActions;
const getDashboardReviews = state => state.ee.dashboard.reviews;
const getProjectRawData = state => state.ee.project;
const getProposalRawData = state => state.view.proposal;
const getDashboardOfferData = state => state.ee.dashboard.offers;
const getProjectOfferData = state => state.ee.project.offers;
export const getProjectStatus = state => state.ee.project.status;

export const parseOffer = (offer) => {
    return {
        ...offer,
        project: offer.projectId,
        providerId: offer.providerId,
        providerDisplayName: offer.providerDisplayName,
        cost: '$' + offer.cost,
        material: '$' + offer.material,
        createdDate: moment(offer.createdDate).format('LL'),
        creation: moment(offer.createdDate).format('LLL'),
        updateDate: moment(offer.updateDate).format('LL'),
        updation: moment(offer.updateDate).format('LLL'),
        comment: offer.comment,
    }  
}

export const parseOffers = (offers) => {
    const parsedOffers = offers.map((offer) => {
        return parseOffer(offer);
    });
    return parsedOffers;
}

export const getOffers = createSelector(
    getDashboardOfferData,
    (offers) => {
        const parsedOffers = parseOffers(offers);
        return parsedOffers;
    }
);

export const getProjectOffers = createSelector(
    getProjectOfferData,
    (offers) => {
        const parsedOffers = parseOffers(offers);
        return parsedOffers;
    }
);

export const getProposals = createSelector(
    getDashboardProposals,
    getScopes,
    (proposals, scopes) => {
        const parsedProposals = parseProposals(proposals, scopes);
        return parsedProposals;
    }
);

export const getProjectProposals = createSelector(
    getProjectRawData,
    getScopes,
    (project, scopes) => {
        const parsedProposals = parseProposals(project.proposals, scopes, project);
        return parsedProposals;
    }
);

export const parseProposal = (proposal, scopes, project) => {
    let scopeList = [];
    let scopeDetailList = [];
    let total = 1;
    if(proposal && proposal.scopes) {
        proposal.scopes.forEach( function (scope) {
            let scopeDescription;
            if (scope.description) {
                scopeDescription = scope.description
            } else if(!scope.description && scopes && scopes.length > 0 ) {
                scopeDescription = scopes[scope.scopeId].name; 
            } else {
                scopeDescription = 'Scope Description'; 
            }
            scopeList.push(scopeDescription);
            let scopeDetail = {};
            scopeDetail = scope;
            scopeDetail.proposalId = proposal.id;
            scopeDetail.providerId = proposal.providerId;
            scopeDetail.providerDisplayName = proposal.providerDisplayName;
            scopeDetail.description = scopeDescription;
            if (scope.type === 'FP' || scope.type.label === 'Fixed price') {
                scopeDetail.type = 'Fixed price';
                scopeDetail.cost =  Number(scope.cost) || Number(scope.costTotal);
                scopeDetail.scopeTotalCost = scope.cost * scope.unit;
            } else if (scope.type === 'HY' || scope.type.label === 'Hourly') {
                scopeDetail.type = 'Hourly';
                if(!scopeDetail.scopeTotalCost) {
                    scopeDetail.scopeTotalCost = (scope.hour * Number(proposal.userRate) + Number(scope.material));
                }
            }

            total += scopeDetail.scopeTotalCost;
            scopeDetail.providerId = proposal.providerId;
            scopeDetail.providerDisplayName = proposal.providerDisplayName;
            scopeDetail.comment = scope.comment;
            let selected = false;
            const projectStatus = (project && project.status) ? project.status : undefined;
            if (projectStatus && projectStatus === 'SELECTING') {
                selected = false;
            } else if (projectStatus && projectStatus === 'SELECTED') {
                if (scope.status === 'SELECTED') {
                    selected = true;
                } else {
                    selected = false;
                }
            }
            scopeDetail.selected = selected;
            scopeDetailList.push(scopeDetail);
        });
        return {
            ...proposal,
            title: proposal.title,
            project: proposal.project,
            providerId: proposal.providerId,
            providerDisplayName: proposal.providerDisplayName,
            estimatedTime: proposal.expectTime + 'day(s)',
            createdDate: moment(proposal.createdDate).format('LL'),
            creation: moment(proposal.createdDate).format('LLL'),
            updateDate: moment(proposal.updateDate).format('LL'),
            updation: moment(proposal.updateDate).format('LLL'),
            comment: proposal.comment,
            scopeDetailList: scopeDetailList,
            total: '$' + total,
        }    
    } else if (project) {
        return {
            ...proposal,
            title: "new project",
            project: project.id,
            estimatedTime: proposal.expectTime + 'day(s)',
            createdDate: moment().format('LL'),
            creation: moment().format('LLL'),
            updateDate: moment().format('LL'),
            updation: moment().format('LLL'),
            scopeDetailList: scopeDetailList,
            total: '$' + total,
        }    
    }
}

export const parseProposals = (proposals, scopes, project) => {
    const parsedProposals = proposals.map((proposal) => {
        return parseProposal(proposal, scopes, project);
    });
    return parsedProposals;
}

export const getProjectReviews = createSelector(
    getProjectRawData,
    getScopes,
    (project, scopes) => {
        const parsedReviews = project.reviews.map((review) => {
            let processed = {};
            processed.id = review.id;
            processed.displayName = review.displayName;
            // processed.scope = scopes[review.scopeId].name;
            processed.recommend = review.recommend;
            processed.quality = review.quality;
            processed.responsiveness = review.responsiveness;
            processed.professionalism = review.professionalism;
            processed.subjectMatterExpertise = review.subjectMatterExpertise;
            processed.adherenceToCost = review.adherenceToCost;
            processed.adherenceToSchedule = review.adherenceToSchedule;
            processed.comment = review.comment;
            processed.overall = review.overall;
            processed.date = moment(review.createdDate).format('LL');
            processed.completed = review.completed;
            return processed;
        });
        return parsedReviews;
    }
);

export const getFromUserMessages = createSelector(
    getDashboardFromUserMessages,
    (userMessages) => {
        const parsedUserMessages = userMessages.map((userMessage) => {
            let processed = {};
            processed.id = userMessage.id;
            processed.replyName = userMessage.fromDisplay;
            processed.replyId = userMessage.fromId;
            processed.text = userMessage.text;
            processed.projectId = userMessage.projectId;
            processed.date = moment(userMessage.date).format('LL');
            return processed;
        });
        return parsedUserMessages;
    }
);

export const getToUserMessages = createSelector(
    getDashboardToUserMessages,
    (userMessages) => {
        const parsedUserMessages = userMessages.map((userMessage) => {
            let processed = {};
            processed.id = userMessage.id;
            processed.replyName = userMessage.toDisplay;
            processed.replyId = userMessage.toId;
            processed.text = userMessage.text;
            processed.projectId = userMessage.projectId;
            processed.date = moment(userMessage.date).format('LL');
            return processed;
        });
        return parsedUserMessages;
    }
);

export const getUserActions = createSelector(
    getDashboardUserActions,
    (userActions) => {
        const parsedUserActions = userActions.map((userAction) => {
            let processed = {};
            // processed.key = key;
            processed.id = userAction.id;
            processed.replyName = userAction.sentByDisplay;
            processed.replyId = userAction.sentBy;
            processed.text = userAction.text;
            processed.projectId = userAction.projectId;
            processed.proposalId = (userAction.proposalId)? userAction.proposalId : 'Undefined'
            processed.text = userAction.text;
            processed.date = moment(userAction.date).format('LL');
            processed.readCheck = userAction.readCheck;
            processed.blocked = (userAction.folder === 'blocked') ? true : false;
            processed.type = userAction.type;
            return processed;
        });
        return parsedUserActions;
    }
);

export const getReviews = createSelector(
    getDashboardReviews,
    (reviews) => {
        const parsedReviews = reviews.map((review) => {
            let processed = {};
            // processed.key = key;
            processed.id = review.id;
            processed.displayName = review.displayName;
            processed.projectId = review.projectId;
            processed.scopeId = review.scopeId;
            processed.recommend = review.recommend;
            processed.quality = review.quality;
            processed.responsiveness = review.responsiveness;
            processed.professionalism = review.professionalism;
            processed.subjectMatterExpertise = review.subjectMatterExpertise;
            processed.adherenceToCost = review.adherenceToCost;
            processed.adherenceToSchedule = review.adherenceToSchedule;
            processed.comment = review.comment;
            processed.overall = review.overall;
            processed.date = moment(review.date).format('LL');
            return processed;
        });

        return parsedReviews;
    }
);

export const getProjectScopes = createSelector(
    getProjectRawData,
    (project) => {
        let processedScopes = project.scopes.map((projectScope) => {
            let addedScope = {};
            addedScope['id'] = projectScope.scopeId || projectScope.id;
            addedScope['name'] = projectScope.name;
            return addedScope;
        });
        return processedScopes;
    }
);

export const getProject = createSelector(
    getProjectRawData,
    (project) => {
        let processed = project;
        processed.emergency = project.emergencyCheck;
        processed.startDate = moment(project.startDate).format(SIMPLE_DAY_DATE_FORMAT_REGISTER);
        processed.startTime = project.startTime;
        processed.endTime = project.endTime;
        processed.createdDate = project.createdDate ? moment(project.createdDate).format('LL') : '';
        processed.updatedDate = project.updatedDate ? moment(project.updatedDate).format('LL') : '';
        processed.endBiddingDate = moment(project.endBiddingDate).format(SIMPLE_DAY_DATE_FORMAT_REGISTER);
        processed.day = [
            {
                day: 'sun',
                value: project.sun === true ? true:false
            },
            {
                day: 'mon',
                value: project.mon === true ? true:false
            },
            {
                day: 'tue',
                value: project.tue === true ? true:false
            },
            {
                day: 'wed',
                value: project.wed === true ? true:false
            },
            {
                day: 'thu',
                value: project.thu === true ? true:false
            },
            {
                day: 'fri',
                value: project.fri === true ? true:false
            },
            {
                day: 'sat',
                value: project.sat === true ? true:false
            },
        ];
        // processed.blocked = project.blocked;

        processed.scopes = project.scopes.map((projectScope) => {
            let addedScope = {};
            addedScope['id'] = projectScope.scopeId || projectScope.id;
            addedScope['name'] = projectScope.name;
            addedScope['providerDisplayName'] = projectScope.providerDisplayName;
            return addedScope;
        });
        return processed;
    }
);

export const getCompleted = createSelector(
    getProjectReviews,
    (reviews) => {
        const length = reviews.length;
        let completed = 0;
        reviews.map((review) => {
            completed++;
            return review;
        });
        if(completed == length && completed !== 0) return 'COMPLETED'
        if(completed < length && completed !== 0) return 'PARTIALLY COMPLETED'
        return 'INCOMPLETED';
    }
);
