import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Container from '@material-ui/core/Container';
import EmailField from '../../components/EmailField';
import PasswordField from '../../components/PasswordField';
import { isAlpha } from 'validator';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%',
        overflowX: 'auto',
        maxWidth: '425px',
        paddingBottom: '30px'
    },
    formBox: {
        padding: '0px 20px 10px 20px',
    },
    wide: {
        width: '100%',
        maxWidth: '340px',
    },
    narrow: {
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    margin: {
        marginTop: '20px',
        marginBottom: '10px',
        marginRight: '4px',
        display: 'flex',
    },
    margin20: {
        marginBottom: '20px',
    },
    smallText: {
        fontSize: '12px',
        lineHeight: '1.6',
    }
});

const RegisterCommon = (props) => {
    const {
        classes,
        user,
        register,
        history,
        handleChange,
        handleRegister,
        handleCancelRegister,
    } = props;

    const userTypeOptions = {
        customer: 'Customer in need of an Electrical Provider? (Member Account)',
        provider: 'Electrical provider looking for customers? (Professional Account)'
    };

    const registerDisabled = register.firstName === undefined || !register.lastName || !register.password || !register.verifyPassword || !register.type;
    return (
        <Container className={classes.root} >
            <form className={classes.formBox} noValidate autoComplete="off">
                <div className={clsx(classes.margin20)}>
                    <TextField
                        id="firstName"
                        label="First Name"
                        variant="outlined"
                        value={register.firstName}
                        onChange={(event) => handleChange('firstName', event.target.value)}
                        className={clsx(classes.wide, classes.margin)}
                        margin="normal"
                        error={!isAlpha(register.firstName || '')}
                        helperText={!isAlpha(register.firstName || '') ? "Invalid text(Only Alpabet is allowed" :  "Enter your first name"}
                        required
                    />
                    <TextField 
                        // registerError={registerError && registerError.lastName ? registerError.lastName : undefined}
                        id="lastName"
                        label="Last Name"
                        variant="outlined"
                        value={register.lastName}
                        onChange={(event) => handleChange('lastName', event.target.value)}
                        className={clsx(classes.wide, classes.margin)}
                        margin="normal"
                        error={!isAlpha(register.lastName || '')}
                        helperText={!isAlpha(register.lastName || '') ? "Invalid text(Only Alpabet is allowed" :  "Enter your lsst name"}
                        required
                    />
                    <EmailField
                        id="register-email-input"
                        label="Email"
                        name="email"
                        email={register.email || ''}
                        onChange={(event) => handleChange('email', event.target.value)}
                        required={true}
                    />
                    <TextField
                        // registerError={registerError && registerError.displayName ? registerError.displayName : undefined}
                        id="displayName"
                        label="Display Name"
                        variant="outlined"
                        value={register.displayName || register.firstName}
                        onChange={(event) => handleChange('displayName', event.target.value)}
                        className={clsx(classes.wide, classes.margin)}
                        margin="normal"
                        helperText= "Enter your display name(default is your first name.)"
                    />
                    <PasswordField
                        fieldId="password"
                        label="Password"
                        type="password"
                        margin="normal"
                        variant="outlined"
                        password={register.password || ''}
                        description="To conform with our Strong Password policy, you are required to use a sufficiently strong password. Password must be more than 7 characters."
                        showStrength={true}
                        required={true}
                        onChange={(event) => handleChange('password', event.target.value)}
                    />
                    <PasswordField
                        id="verifyPassword"
                        label="Verify your password"
                        type="password"
                        margin="normal"
                        variant="outlined"
                        password={register.verifyPassword || ''}
                        showStrength={false}
                        required={true}
                        hasErrors={register.password !== register.verifyPassword}
                        errors={register.password !== register.verifyPassword ? ['password is not matched'] : undefined}
                        onChange={(event) => handleChange('verifyPassword', event.target.value)}
                    />
                </div>
                <div className={clsx(classes.margin20)}>
                    <InputLabel 
                        className={clsx(classes.wide)}
                        htmlFor="select-multiple-chip">Select an account type you wish to create:</InputLabel>
                    <FormControl 
                        className={clsx(classes.wide, classes.margin20)}>
                        {Object.entries(userTypeOptions).map(([type, typeDescription]) => (
                            <Button 
                                key={type}
                                variant={type ===  register.type ? 'contained' : 'outlined'} 
                                size="small" 
                                color="primary"
                                onClick={() => handleChange('type', type)}
                                className={classes.type}>
                                {typeDescription}
                            </Button>
                        ))}
                    </FormControl>
                </div>
            <div className={clsx(classes.wide, classes.margin20)}>      
            <div className={clsx(classes.margin30, classes.smallText)}>
                <p>
                    By signing in or signing up, I agree to GetElectrician.com's <a target="_blank" href="termsOfUse">Terms of Service</a> and 
                    <a target="_blank" href="privacyPolicy">Privacy Policy</a>, confirm that I am 18 years of age or older, 
                    and consent to receiving email communication.
                </p>
            </div>
            <Button 
                disabled={registerDisabled}
                variant="contained" 
                component="span" 
                onClick={() => handleRegister(history)}
                className={registerDisabled ? clsx(classes.wide, 'flex') : clsx(classes.wide, 'ee-bg-color', 'flex')}
            >
                Register
            </Button>
            </div>
        </form>
    </Container>
    );
};

export default withStyles(styles)(RegisterCommon);

