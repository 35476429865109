import { connect } from 'react-redux';
import UserMessageList from '../components/UserMessageList';
import { getToUserMessages } from '../../selectors/ee';
import { updateMessageDialog } from '../../actions/view';
import { fetchDeleteMessage } from '../../actions/api';

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.ee.user,
        messages: getToUserMessages(state),
        messageType: 'user-message',
        history: ownProps.history,
        trs: [{
                title: 'ID',
                align: 'center'
            }, {
                title: 'Send to',
                align: 'left'                
            }, {
                title: 'Date',
                align: 'left',
            }, {
                title: 'Proejct ID',
                align: 'center',
            }, {
                title: 'Message',
                align: 'left',
            }, {
                title: 'Actions',
                align: 'right',
            }]
    }
};

const mapDispatchToProps = dispatch => ({
    handleClickMessageDialogOpen: (replyDisplay, replyId, projectId) => {
        const message = {
            open: true,
            replyDisplay,
            replyId,
            projectId,
        }
        dispatch(updateMessageDialog(message));
    },
    handleDeleteMessageClick: (messageId, history) => {
        dispatch(fetchDeleteMessage(messageId, history));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserMessageList);
