import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%',
        overflowX: 'auto',
        padding: '0',
    },
    formBox: {
        padding: '0px 20px 10px 20px',
    },
    wide: {
        width: '100%',
        maxWidth: '340px',
    },
    narrow: {
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    margin: {
        marginLeft: '4px',
        marginRight: '4px',
    },
    margin20: {
        marginBottom: '20px',
    },
    zIndex0: {
        zIndex: 0,
    },
    subLabel: {
        fontWeight: 600,
    },
});

const HourlyRate = (props) => {
    const {
        classes,
        user,
        register,
        history,
        isUpdate,
        handleChange,
        handleSaveHourlyRate
    } = props;

    const userRate = register.rate;
    const submitDisabled = (userRate === undefined || userRate === "" || isNaN(userRate) || userRate < 0);

    return (
        <Container maxWidth="lg">
            <div className={classes.root}>
                <InputLabel
                    htmlFor="rate"
                    className={clsx(classes.subLabel, 'ee-color')}
                >Hourly Rate</InputLabel>
                <div className={clsx(classes.margin20, classes.zIndex0)}>
                    <TextField
                        required
                        id="rate"
                        label="US Dollar"
                        value={userRate}
                        onChange={(event) => handleChange('rate', event.target.value)}
                        className={clsx(classes.wide, classes.margin, classes.zIndex0, classes.flex)}
                        margin="normal"
                        variant="outlined"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0 }}
                    />
                </div>
                {isUpdate && <div className={clsx(classes.wide, classes.margin20)}>
                    <Button
                        disabled={submitDisabled}
                        variant="contained"
                        component="span"
                        onClick={() => handleSaveHourlyRate(history)}
                        className={!submitDisabled ? clsx(classes.wide, 'ee-bg-color', 'flex') : clsx(classes.wide, 'flex')}
                    >
                        Save Hourly Rate
                    </Button>
                </div>}
            </div>
        </Container>
    );
};

export default withStyles(styles)(HourlyRate);