import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import MessageIconSvg from '../../icons/MessageIconSvg';
import DeleteIconSvg from '../../icons/DeleteIconSvg';

const styles = theme => ({
  root: {
    fontFamily: "'Noto Sans', sans-serif",
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    width: "100%",
    minWidth: 700,
  },
  head: {
      backgroundColor: '#c2c2c2',
  },
  th: {
      paddingLeft: '3px',
      paddingRight: '2px',
  },
  title: {
    cursor: 'pointer',
    paddingLeft: '2px',
    paddingRight: '2px',
    fontWeight: '500',
  },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    }
});

const UserMessageList = (props) => {
    const {
        classes,
        user,
        history,
        messageType,
        messages,
        trs,
        handleClickMessageDialogOpen,
        handleDeleteMessageClick,
    } = props;


    return (
        <Paper className={classes.root}>
            <Table className={classes.table}>
                <TableHead className={classes.head}>
                <TableRow>
                    {trs.map(tr => (
                        <TableCell className={classes.th} align={tr.align}>{tr.title}</TableCell>
                    ))}
                </TableRow>
                </TableHead>
                <TableBody>
                    {messages.map(row => (
                        <TableRow className={classes.row} key={row.id}>
                            <TableCell className={classes.th} align="center">{row.id}</TableCell>
                            {messageType !== 'user-action' &&
                                <TableCell className={classes.th} align="right">{row.replyName}</TableCell>}
                            <TableCell className={classes.th} align="left">{row.date}</TableCell>
                            {/* <TableCell className={classes.th} align="center">{row.projectId}</TableCell> */}
                            <TableCell 
                                className={classes.title} 
                                align="center"
                                hover
                                onClick={() => { history.push(`/project/${row.projectId}`) }}
                                ><a>{row.projectId}</a></TableCell>
                            <TableCell className={classes.th} align="left">{row.text}</TableCell>
                            <TableCell className={classes.th} align="right">
                                {messageType !== 'user-action' &&
                                    <IconButton aria-label="Delete" className={classes.margin} onClick={() => handleClickMessageDialogOpen(row.replyName, row.replyId, row.projectId)}>
                                        <MessageIconSvg />
                                    </IconButton>}
                                    <IconButton aria-label="Delete" className={classes.margin} onClick={() => handleDeleteMessageClick(row.id, history)}>
                                        <DeleteIconSvg />
                                    </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
        </Paper>
    );
};

export default withStyles(styles)(UserMessageList);

