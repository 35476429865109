import * as types from '../constants/actionTypes';

const workZoneStates = ["NJ", "NY"]
const workZoneCounties = {
    NJ : ['Bergen', 'Burlington', 'Camden', 'Cape May', 'Cumberland', 'Essex', 'Gloucester', 'Hudson', 'Hunterdon', 'Mercer', 'Middlesex',
    'Monmouth', 'Morris', 'Ocean', 'Passaic', 'Salem', 'Somerset', 'Sussex' , 'Union'],
    NY: ['New York','Bronx', 'Kings', 'Queens', 'Richmond']
};

const states = ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY",
"LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND",
 "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"];

export const initialState = () => ({
    name: undefined,
    location: undefined,
    since: undefined,
    rateAverage: undefined,
    workingProjectCount: 0,
    completedProjectCount: 0,
    projectCount: 0,
    reviewsCount: 0,
    reviews: [],
    userCompany: {},
    licenseList: [],
    permitList: [],
    insuranceList: [],
    workHourList: [],
    profileImage: undefined,
});

export default function publicReducer(prevState = initialState(), action) {
    const state = {
        ...prevState,
    };

    switch (action.type) {
        case types.RECEIVED_PUBLIC_DASHBOARD:
            return {
                ...action.dashboard, 
            }
        case types.RECEIVED_PROFILE_IMAGE:
            return {
                ...state,
                profileImage: action.profileImage,
            }
        default:
            return {
                ...state
            };
    }
}
