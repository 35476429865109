import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updatePermitValue } from '../../actions/register';
// import { fetchSaveLicense } from '../../actions/api';
import Public from '../components/Public';
import { updateMessageDialog } from '../../actions/view';
import { fetchPublicDashboard, fetchProfileImage } from '../../actions/api';
import { getPermit, getInsurance } from '../../selectors/public';


const mapStateToProps = (state, ownProps) => {
    const providerId = (ownProps.match.params.pid !== undefined) ? ownProps.match.params.pid : null;

    return {
        user: state.ee.user,
        publicReducer: state.publicReducer,
        permitList: getPermit(state),
        insuranceList: getInsurance(state),
        providerId: providerId,
        history: ownProps.history,
    }
};

const mapDispatchToProps = dispatch => ({
    getPublicDashboardData: (providerId) => {
        dispatch(fetchPublicDashboard(providerId));
    },
    getProfileImage: (providerId) => {
        dispatch(fetchProfileImage(providerId));
    },
    handleClickMessageDialogOpen: (replyDisplay, replyId) => {
        const message = {
            open: true,
            replyDisplay,
            replyId,
        }
        dispatch(updateMessageDialog(message));
    },
});

class PublicDashboardContainer extends Component {
    componentDidMount() {
        const providerId = this.props.providerId;
        this.props.getPublicDashboardData(providerId);
        this.props.getProfileImage(providerId);
    }
    
    componentWillReceiveProps(newProps) {
        if (newProps.providerId !== this.props.providerId) {
            const providerId = this.props.providerId;
            this.props.getPublicDashboardData(providerId);
            this.props.getProfileImage(providerId);
        }
    }

    render() {
        const providerId = this.props.providerId;
        return (
            <section id="gete-project" className="gete-project">
                {/* <div>   </div> */}
                <Public 
                    user={this.props.user}
                    publicReducer={this.props.publicReducer}
                    permitList={this.props.permitList}
                    insuranceList={this.props.insuranceList}
                    providerId={providerId}
                    history={this.props.history}/>
            </section>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PublicDashboardContainer);