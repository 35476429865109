import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SaveCompanyContainer from '../containers/SaveCompanyContainer';
import WorkZoneContainer from '../containers/WorkZoneContainer';
import WorkHourContainer from '../containers/WorkHourContainer';
import AddressContainer from '../../containers/AddressContainer';
import PhoneContainer from '../../containers/PhoneContainer';
import TextField from '@material-ui/core/TextField';
import LicenseContainer from '../containers/LicenseContainer';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import ProfileImageContainer from '../../profile/containers/ProfileImageContainer';
import HourlyRateContainer from '../containers/HourlyRateContainer';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        display: 'flex',
        flexWrap: 'wrap',
        padding: '0',
        width: '100%',
    },
    formBox: {
        padding: '0px 20px 10px 20px',
    },
    margin20: {
        marginBottom: '20px',
    },
    eeColor: {
        color: '#01DF01',
    },
    guideText: {
        fontWeight: 'bold',
    },
    wide: {
        width: '100%',
        maxWidth: '340px',
    },
    padding: {
        paddingLeft: '24px',
    },
    subLabel: {
        fontWeight: 600, 
    },
});

const Profile = (props) => {
    const {
        classes,
        user,
        register,
        history,
        isUpdate,
        handleChange,
        handleRegister,
        handleCancelRegister,
    } = props;

    const providerTypeOptions = {
        licensedCompany: 'Licensed Electrical Individual',
        licensedIndividual: 'Unlicensed Electrical Individual',
        unlicensedIndividual:'Unlicensed Electrical Individual',
    };

    const registerError = register.error;
    const userType = user.userType;
    let showLicense = false;
    let showCompany = false;
    const isProvider = (userType === 'plei' || userType === 'puei' || userType === 'plec')? true : false;
    if(userType === 'plei' || userType === 'plec') {
        showLicense = true;
    }
    if(userType === 'plec') {
        showCompany = true;
    }
    const companyDisabled = showCompany && !register.company && (!register.company.name || !register.company.registeredAt  || !register.company.companyId );
    const licenseDisabled = showLicense && (!register.license.name || !register.license.no  || !register.license.issuedAt  || !register.license.issuedBy );
    const registerDisabled = !register.phone || companyDisabled || licenseDisabled;
    return (
        <Container maxWidth="lg">
            <div className={classes.root}>
                <h4 className="title-ee"><span className={classes.eeColor}> GetElectrician </span> profile</h4>
                <div className={classes.formBox} name="registerStep2" noValidate autoComplete="off">
                    <div>
                    <span className={classes.eeColor}>{user.userDisplayName}</span>.
                        Please provide few additional pieces of information so that you can enjoy full benefits of your professional account with us
                    </div>
                    <div className={clsx(classes.margin20)}>
                        <ProfileImageContainer />
                    </div>
                    <div className={clsx(classes.margin20)}>
                        <AddressContainer isUpdate={isUpdate}/>
                        <PhoneContainer isUpdate={isUpdate}/>
                    </div>
                    {isProvider && <div className={clsx(classes.margin20)}>
                        <WorkHourContainer />
                    </div>}
                    {isProvider &&<WorkZoneContainer isUpdate={isUpdate}/>}
                    {isProvider &&<HourlyRateContainer isUpdate={isUpdate}/>}
                    {isProvider && <div className={clsx(classes.margin20, classes.zIndex0, classes.padding)}>
                        <InputLabel 
                            htmlFor="website"
                            className={clsx(classes.subLabel, 'ee-color')}
                        >Website</InputLabel>  
                        <TextField
                            required
                            id="website"
                            label="Website"
                            value={register.website ? register.website : ''}
                            onChange={(event) => handleChange('website', event.target.value)}
                            className={clsx(classes.wide, classes.margin, classes.zIndex0)}
                            margin="normal"
                            variant="outlined"
                        />
                    </div>}
                    {showCompany && <SaveCompanyContainer isUpdate={isUpdate}/>}
                    {showLicense && <LicenseContainer isUpdate={isUpdate}/>}

                    {!isUpdate && <div className={clsx(classes.wide, classes.margin20)}>      
                        <Button 
                            disabled={registerDisabled}
                            variant="contained" 
                            component="span"
                            onClick={() => handleRegister(history)}
                            className={clsx(classes.wide, 'flex')}>
                            Save
                        </Button>
                        <Button 
                            variant="contained" 
                            component="span"
                            onClick={() => handleCancelRegister(history)}
                            className={clsx(classes.wide, 'flex')}>
                            Cancel
                        </Button>
                    </div>}
                </div>
            </div>
        </Container>
    );
};

export default withStyles(styles)(Profile);
