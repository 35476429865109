import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%',
        overflowX: 'auto',
        padding: '0px 20px 10px 20px',
    },
    table: {
        width: "100%",
        minWidth: 700,
    },
    th: {
        paddingLeft: '2px',
        paddingRight: '2px',
    },
    heavy: {
        fontWeight: 600,
    }, 
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    scope: {
        backgroundColor:'#F2F2F2',
        margin: theme.spacing.unit,
        fontSize: '12px',
        
    },
    tableActions: {
        display: 'flex',
    },
    tableIcon: {
        padding: '2px',
        margin: theme.spacing.unit,
    },  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  panelDetails: {
    padding: '0px 10px 10px 20px',
  },
  panelDetailsTypography: {
    width: '100%',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  subLabel: {    
        fontWeight: 700,
        paddingRight: '6px',
  },
  sm: {
    fontSize: '11px',
  },
  rating: {
    fontSize: '20px',
  },
  recom: {
    borderRadius: '20px',
    background: '#73AD21',
    padding: '2px',
    marginLeft: '5px'
  }
});

const ReviewList = (props) => {
    const {
        classes,
        user,
        reviews,
        handleClickMessageDialogOpen,
    } = props;

    const showReviews = reviews.length > 0 ? true: false;
    if(!showReviews) {
        return '';
    }
    return (
        <div className={classes.root}>
            <label className={clsx(classes.heavy)}>Reviews</label>
                <div>
                    {reviews.map(review => (
                        <div className="block">
                            <div align="left">{review.displayName}
                            <span className={clsx(classes.sm, classes.recom)}>{review.recommend == "1" ? 'Recommend!' : ''}</span>
                            </div>
                            <div align="left">{review.projectId}</div>
                            <Box component="fieldset" borderColor="transparent" >
                                <Rating  
                                    className={classes.rating}
                                    name="overall"
                                    value={review.overall}
                                />
                            </Box>
                            
                            <div align="left">{review.comment}</div>
                            <div align="left" className={classes.sm} >{review.date}</div>
                        </div>
                    ))}
                </div>
        </div>
    );
};

export default withStyles(styles)(ReviewList);

