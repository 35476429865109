export const PRIVACY_POLICY=`
    <h3>GetElectrician Privacy Policy</h3>

    <h4>Last Updated: July 28, 2020</h4>

    <div>Your privacy is important to us and we are committed to protecting your personal information in accordance with the terms of this Privacy Policy. If you are a California resident, please see our Privacy Notice for California Residents (located here) regarding your rights under the California Consumer Protection Act of 2018.</div>

    <h4>Overview</h4>

    <div>This Privacy Policy describes the collection, use and disclosure of information by Xavior Inc, (“Getelectrician”, “we,” “us”) and other parties with respect to your use of all properties (Web sites, telephone service or otherwise) owned or operated by Xavior Inc and our affiliates (collectively, the "Properties"), and all products and services available from Getelectrician, providers of electrical-related services (“Service Providers”) or other third parties in connection with the Properties (individually and collectively with the Properties, the “Services”). By accessing or using the Services, you are accepting the practices described in this Privacy Policy. If you do not agree to the terms of this Privacy Policy, please do not use the Services.</div>

    <h4>Privacy Policy Updates</h4>

    <div>We may update this Privacy Policy at any time by posting a revised version. Any changes to this Privacy Policy will be effective immediately upon posting. Please review this Privacy Policy each time you use the Services, as your continued use of the Services after such changes will constitute your acceptance and agreement to such changes.</div>

    <h5>Information You Provide </h5>
    
    <div>Information You Submit.  You will need to open an account if you would like to get a quote from electrical providers, if you’d like to access the dashboard, or if you are a Service Provider who would like to join the Getelectrician portal. When using the Services, whether as a User or as a Service Provider, you may elect to provide information about yourself, such as name, address, telephone number, email address, payment details and information about your use of the Services or your experiences with Getelectrician, Service Providers or Users. You may provide this information, for example, when you create an account, post User Submissions, request or purchase products or services, communicate with us, a Service Provider or a User, “Like” us on Facebook or otherwise connect us to your social networks, or complete other forms. If you are a Service Provider, you may provide additional information about yourself or your company, including for example, when you provide details to complete your account, respond to service requests from customers, and provide information for background/reference checks.
    In connection with the Services, you may also elect to provide reviews, comments, feedback, notes, photos, videos, know-how, techniques, or other information relating to you, your project, your household, your business, your real or personal property or otherwise.
    Social Networks. If you use the social networking connection functions offered through the Services, we may access any of your social profile information that you have made available to be shared and to use it in accordance with this Privacy Policy. Please refer to your social network policies to manage information that is shared through that account.</div>

    <h5>Information We Collect Automatically</h5>

    <div>Cookies and Web Beacons. We, our service providers and our advertising networks use cookies, web beacons (sometimes called single-pixel gifs or action tags) or similar technologies on the Properties, in emails and in advertisements to collect information about your online activities. Cookies (small data files that your Internet browser stores on your hard disk or in your browser's memory) are used by the Services to connect your activity on the Services with other information we store about you in your account profile or from prior usage of the Services. Web beacons are electronic images that assist in delivering cookies, provide additional information (such as which ad you clicked on to arrive at the Properties) and allow us to compile aggregated statistics. For example, the use of cookies allows us to improve our Services by tracking saved searches, understanding your and other site visitors’ use of various features and functionality, delivering targeted ads that are more relevant to you, avoiding ad duplication, measuring the effectiveness of advertising and otherwise creating a consistent user experience for you. Collected information may include the content you view, the date and time you view content, and the referring web site or advertisement. This information may be associated with your unique browser, device identifier or IP address.</div>
    
    <div>At any time, you may adjust your browser settings to delete existing cookies (and the data that web beacons add to the cookie), or refuse new cookies, according to the method permitted by your browser. If you choose to delete cookies, you will need to log in again on your next visit to the Properties and re-enter any preferences you have set. If you choose to disable cookies, you may not be able to access or use some portions and features of the Services and may not get the best experience possible.</div>
    
    <div>Your browser may offer you a "Do Not Track" option, which allows you to signal to operators of websites and other properties that you do not wish such operators to track certain online activities. Our Services do not support Do Not Track requests at this time.</div>
    
    <div>Log Usage Data. Our servers automatically record usage log data created when you use the Services. Usage log data may include your IP address, browser type, operating system, referring web page, visited web pages, location, mobile carrier, computer or mobile device type, search terms, cookie information and other information.</div>
    
    <div>Location Data. If you provide location information during the account registration process or at any other time via your account settings, or if location information is included as part of the information we collect (for example, your IP address may provide an approximate geo-location), we may store that information in connection with your account. If you are using mobile Services, we may collect location data directly from your mobile device if your device allows us to do so. Your mobile device manufacturer or mobile service provider may provide you with choices about how and whether location data is shared with us.</div>
    
    <h5>Other Information We Collect</h5>

    <div>Telephone Calls. When you call us, or when we call you, we may monitor those calls for quality assurance and customer satisfaction purposes. </div>
    
    <div>Email and Mobile Content Programs. You can choose to receive promotional emails from us. Like most e-commerce sites, we include software code in our emails to monitor whether and when you opened the email, whether you have clicked on the images and/or links in the email, and whether your computer or mobile device is capable of receiving HTML-based email. You may also choose to access our content through a mobile application and to receive text (SMS) messages on your mobile device. You may opt out from email and mobile programs as described below under "Opting Out.”</div>
    
    <h5>Information Collected by Third Parties</h5>
    
    <div>Offline Activity. Getelectrician and Service Providers may collect data, text, photos, videos, measurements, comments, reviews, feedback, notes, ideas, know-how, techniques, or other information of any nature whatsoever relating to you, your household, your real or personal property or otherwise, in connection with the Services provided by Getelectrician or the services provided by Service Providers.</div>

    <h5>Aggregate Information</h5>

    <div>We collect non-personal, statistical information about the use of the Services, such as how many visitors visit a specific page, how long they stay, which links they click on, and how, when and how often Users order particular Services. We may also group and de-personalize (that is, anonymize) information into aggregated data in order to describe and analyze Services usage. Aggregated, anonymized data is not subject to this Privacy Policy, and may be used by us for any purpose.</div>
    
    <div>Information collected on the Properties may be combined with other personal or aggregated information, including information we collect by other means (for example, if you call or email us). The combined information will also be subject to this Privacy Policy.</div>    

    <h4>Use of Information</h4>
    <div>Any information we collect from you may be used in the following ways:</div>
    <ul>
    <li>To provide the Services;</li>
    <li>To improve the Services (for example, to personalize your experience to make it smoother and more relevant to you);</li>
    <li>To provide and improve customer service, to communicate with you about your account or changes in our policies, and to otherwise manage our customer relationships;</li>
    <li>To connect Users with Service Providers;</li>
    <li>To connect you with Service Providers, to help Service Providers select whether they want to work with you and to provide Service Providers with more opportunities to receive service requests that might be of interest to them;</li>
    <li>To provide you with information about products and services from Getelectrician that may be of interest to you;</li>
    <li>To provide you with complementary products and services that may be of interest to you, including discounts and other benefits from our partners;</li>
    <li>To administer contests and promotions;</li>
    <li>To detect, investigate and prevent fraud, abuse and activities that may be illegal or violate our policies, to address technical and security issues, to comply with applicable laws and legal process, and to otherwise protect Users, Service Providers, and our business;</li>
    <li>To select, develop and deliver our products and services and analyze their effectiveness, distribution and reach; and</li>
    <li>To perform other functions described at the time the information is collected.</li>
    </ul>

    <h4>Disclosure of Information</h4>
    <div>Information that you choose to provide in a public area of the Services (such as your profile page or via User Submissions), including personally identifiable information, will not be protected by this Privacy Policy because you have chosen to make that information public. Otherwise, your personally identifiable information will be shared with third parties only in accordance with this Privacy Policy, as follows:</div>
    <h5>Consent</h5>
    <div>We may share your personally identifiable information when you consent or direct Getelectrician to do so. For example, if a User seeks a Service Provider through the Services, we may provide the User’s personal information to the Service Provider and Service Provider information to the User. The method of consent will be appropriate for the type of personal information involved and the purposes for which it is collected, used or disclosed. Depending on the circumstances, consent may be express (i.e., you specifically agree either verbally, in writing or electronically) or implied.</div>
    
    <b><i>Please note that, when you submit a request for products or services, you are requesting to be (and you expressly consent to be) contacted by Getelectrician, Service Providers and companies that help connect you with Service Providers (or, if you are a Service Provider, by Getelectrician and companies that help connect you with customers), as the case may be. You consent to be contacted by these parties by telephone, email, mail, text (SMS) messaging, fax, or other reasonable means at any of the residential, mobile or fax phone numbers or addresses you provide, even if they are listed on a national "do not call" or “do not contact” list. You agree that these communications may include prerecorded, artificially voiced or autodialed telemarketing messages, and that they may be monitored and recorded for quality assurance and other reasons. You agree that these parties may contact you for the purposes of providing the products or services you have requested, marketing, addressing complaints, billing or other account-related matters, or other purposes reasonably related to the services you have requested. Your consent to receive marketing communications is not required as a condition of purchasing any goods or services.</i></b>

    <h5>Compliance with Laws and Policies; Protection of Rights</h5>
    <div>We may release your information (including personally identifiable information) and aggregated information when we believe that doing so is appropriate to comply with applicable laws, regulations, subpoenas, warrants or court orders, or other legal requirements; to cooperate with government or law enforcement; to resolve complaints or disputes; to avoid legal liability; to comply with, enforce or investigate potential violations of our agreements, Terms of Use, program-specific terms, Privacy Policy or other policies; to bill and collect amounts owed to us; to protect our rights, reputation, safety or property or that of Users or others; and to address fraud, security or technical issues.</div>
    <h5>Aggregated Data; Non-Personal Data</h5>
    <div>Aggregated, anonymized data and other data that is not personally identifiable is not subject to this Privacy Policy, and may be used by us for any purpose. For example, we may share aggregated, non-personal data with service providers, advertisers, or existing or potential business partners.</div>

    <h4>Opting Out; Editing Your Personal Information</h4>
    <h5>Changing Information; Closing Account</h5>
    <div>To change or delete your personal information, you may login to your account and modify or remove specified information that resides in your account. If you want to delete your account, please contact us at info@GetElectrician.com. We will remove your account within a reasonable time after receiving your request. Please note that, even after your account is deleted, information that you chose to provide in a public area of the Services (such as reviews and projects) will remain visible to the public and may remain in the accounts of Users and Service Providers with whom such information has been shared, and all of your information (including your personal information) will remain in Getelectrician’s backup/archival records. Getelectrician may retain and use information in our records as described in the Terms of Use and this Privacy Policy, including the section above entitled “Disclosure of Information/Compliance with Laws and Policies; Protection of Rights.”</div>
    <div>You may also make requests regarding your personal information, such as requests to access information or requests to update or correct inaccurate information, by emailing us at info@getelectrician.com or writing us at 40 1st Avenue, Suite 15C, New York, NY 10009.</div>

    <h4>Your California Privacy Rights</h4>
    <div>Section 1798.83 of the California Civil Code permits California residents to request from a business, with whom the California resident has an established business relationship, certain information about the types of personal information the business has shared with third parties for those third parties' direct marketing purposes and the names and addresses of the third parties with whom the business has shared such information during the immediately preceding calendar year. You may make one request each year by emailing us at info@getelectrician.com or writing us at 40 1st Ave, New York, NY 10009.</div>
    
    <div>See our Privacy Notice for California Residents (located here) regarding your rights under the California Consumer Protection Act of 2018.</div>

    <h4>Security</h4>
    <div>The security of your personal information is important to us. We maintain industry-standard physical, electronic, and procedural safeguards, such as firewalls and encryption technology, to protect against the loss, misuse, and alteration of the information under our control. For example, when you enter a credit card number on the Properties, we encrypt the transmission of that information using secure socket layer (SSL) technology. However, no method of transmitting information over the Internet or storing information is completely secure. Accordingly, we cannot guarantee the protection of any information shared with us.</div>

    <div>If you create an account, you may be asked to choose a password to protect your account and account information. We recommend that you do not disclose your password to any other person. You are responsible for maintaining the confidentiality of your password and account, and are fully responsible for all activities that occur under your password or account.</div>

    <h4>General Matters</h4>
    <h5>Children</h5>
    <div>The Services are not directed to children under 13, and we do not knowingly collect or store any personally identifiable information about persons under the age of 13. If we learn that we have collected personally identifiable information of a child under 13, we will make commercially reasonable efforts to delete such information from our files. If you are under the age of 13, please do not submit any personal information through the Properties.</div<

    <h5>Governing Law; Visitors Residing Outside the United States</h5>
    <div>All of the Services are hosted in the United States. This Privacy Policy and the privacy practices of our Site will be subject exclusively to the laws of the State of New York within the United States of America, without regard to conflict of law principles. If you reside outside of the United States, you are responsible for complying with local laws in connection with your use of the Services, and you consent to the collection, transfer, storage, and use of your personal information in the United States of America in accordance with this Privacy Policy.</div>

    <h5>Getelectrician Terms of Use</h5>
    <div>Your access or use of the Services is subject to Getelectrician’s Terms of Use (located here).</div>

    <h5>Contacting Us</h5>
    <div>If you have any questions or suggestions regarding our Privacy Policy, please contact us by email at info@getelectrician.com or in writing at 40 First Ave Suite 15C, New York, NY, 10009</div>
`;