export const TERMS_OF_USE=`
    <h3>GetElectrician</h3>

    <h4>These Terms of Use were last modified on April 17, 2020.</h4>

    <div>The Web site and Mobile App are provided by Xavior, Inc., d/b/a GetElectrician, a New York corporation with a principal place of business located at 40 First Avenue, #15C, New York, NY 10009.</div>

    <h4>SECTION I: DEFINITIONS, AGREEMENT TO BE BOUND</h4>

    <h5>1.1 	Definitions.</h5>

    <div>References to “Dispute” mean any claim, conflict, controversy, or disagreement between the Parties arising out of, or related in any way to, these Terms (or any Terms, supplement or amendment contemplated by these Terms,) including, without limitation, any action in tort, contract or otherwise, at equity or at law, or any alleged breach, including, without limitation, any matter with respect to the meaning, effect, validity, performance, termination, interpretation or enforcement of these Terms or any terms contemplated by the Terms.</div>

    <div>References to a “Client” mean an individual and/or entity who seeks the services of a Services Provider via a Listing on the Web site and/or the Mobile App.</div>

    <div>References to a “Listing” means a description of project needing the services of a Service Provider.</div>

    <div>References to a “Material Breach” mean any breach of these Terms upon the occurrence of which a reasonable person in the position of the non-breaching Party would wish to immediately terminate these Terms because of that breach.</div>

    <div>References to the “Mobile App” mean the mobile software application by the name of “eelectricians,” available on both the Google Play and iTunes stores.</div>

    <div>References to a “Service Provider” mean any person who offers electrician services via the Web site and/or the Mobile App.</div>

    <div>References to the “Terms” and/or “Agreement,” mean this, these Terms of Use, as set forth herein.</div>

    <div>References to “us,” “we,” “our,” and/or “GetElectrician,” means Xavior, Inc., d/b/a GetElectrician, and its parents, subsidiaries, and assigns.</div>

    <div>References to the “Web site” mean the Web site bearing the URL www.getelectrician.com.</div>

    <div>References to “you,” and/or “User” mean the user of the Web site and/or the Mobile App.</div>

    <h5>1.2. 	Agreement to be Bound.</h5>

    <div>The following Terms of Use, together with the relevant information set out on the Web site and the Mobile App, including any features and services available, such as RSS feeds, podcasts, video and photographs, publications, and other materials, are subject to the Terms of Use set forth below. Please read them carefully as any of use of the Web site and/or the Mobile App constitutes an agreement, without acceptance, to be bound thereby by the User. By using the Web site or the Mobile App, and, upon registration therefor, by clicking that you agree to these Terms, you represent that you are at least eighteen (18) years old, have read and understand the Terms of Use, and that you agree to be bound by these Terms of Use as set forth below.</div>

    <div>These Terms of Use are subject to the Privacy Policy and our Service Provider Terms of Use, which also govern your use of the Web site and the Mobile App.</div>

    <h4>SECTION II: GENERAL PROVISIONS</h4>

    <h5>2.1. 	About Us; GetElectrician not Vendor; No Endorsement.</h5>

    <div>GetElectrician acts as a marketplace to allow Users to post, search, recommend, and staff electrical jobs through an online marketplace. As an online service provider, we do not endorse or promote any Clients or Service Providers, and we have no control over the quality, legitimacy, accuracy, safety, morality or legality of any aspect of any Service Provider listing or job posting, the truth or accuracy of the same, the ability of any Service Provider to provide the services required by any Listing or any Client’s ability to pay for such services, or the identity of any person or entity. If you rely on any of the information provided by or on the Web site, you do so solely at your own risk.</div>

    <div>You acknowledge and agree that we do not sell, offer to sell, invite to sell, make or solicit any job offers. IN ALL INSTANCES, ALL OFFERS OF EMPLOYMENT ARE ADVERTISED, SOLICITED, OFFERED, ACCEPTED, MADE AND DELIVERED BY AND BETWEEN CLIENTS AND SERVICE PROVIDER WHO CONTRACT DIRECTLY. AT NO TIME WILL AN EMPLOYER/EMPLOYEE RELATIONSHIP EXIST BETWEEN US. In all instances, any solicitation, invitation, offer, advertisement or communication is void where prohibited by law.</div>

    <div>All employment decisions shall be made in the Client’s sole and exclusive discretion. GetElectrician is strictly not liable for any employment decisions.</div>

    <h5>2.2. 	Use of the Services at your Own Risk; No Verification.</h5>

    YOU HEREBY ACKNOWLEDGE AND AGREE THAT WHILE WE MAY PROVIDE INFORMATION ABOUT SERVICE PROVIDERS OR CLIENTS WE ARE NOT RESPONSIBLE FOR THE ACCURACY OF SUCH INFORMATION. YOU FURTHER AGREE AND ACKNOWLEDGE THAT WE PROVIDE SUCH INFORMATION SOLELY FOR INFORMATIONAL PURPOSES ONLY, AND IT IS NOT TO BE CONSTRUED AS A PROFESSIONAL REFERENCE, RECOMMENDATION, OR ENDORSEMENT. User authentication on the Internet is difficult, and GetElectrician cannot, does not, and will not verify that each User is who he, she, or it claims to be. You assume all the risks inherent in the use of the Web site, including, but not limited to, the risks of bodily harm, the risk of dealing with strangers or persons underage, and any risks affiliated with foreign nationals and other users with whom you come into contact as a result of using the Web site or the Mobile App. It is your responsibility to evaluate the truth and accuracy, completeness, usefulness, or validity of all the opinions, advice, service, promotions, advertisements, awards, prizes, or other information that you receive as a result of your use of the Web site or the Mobile App. You are solely and uniquely responsible for conducting your own due diligence.
    <h5>2.3. 	Accuracy, Completeness and Timeliness of Information.</h5>

    We are not responsible if information made available on the Web site the Mobile App is not accurate, complete or current. You acknowledge that the Web site and the Mobile App are provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. You acknowledge further that any reliance on the Web site or the Mobile App is at your own risk.

    <h5>2.4.	Errors in Web site or the Mobile App.</h5>
     We do not warrant that any errors in the Web site and/or the Mobile App will be corrected.

    <h5>2.5. 	Modifications and Changes to Terms of Use.</h5>

    We may modify, add to, suspend or delete these Terms of Use or other agreements, in whole or in part, in our sole discretion at any time, with such modifications, additions or deletions being immediately effective upon their posting to the Web site and the Mobile App. Your use of the Web site and/or the Mobile App after modification, addition or deletion of these Terms of Use shall be deemed to constitute acceptance by you of the modification, addition or deletion.

    <h5>2.6. 	Modifications and Changes to the Web Site or the Mobile App.</h5>

    We may modify, add to, suspend, or delete any aspect of the Web site and/or the Mobile App offered by us, in whole or in part, at our sole discretion at any time, with such modifications, additions or deletions being immediately effective. Such modifications, additions or deletions may include but are not limited to content offered, hours of availability, and equipment needed for access or use.

    <h5>2.7. 	Access to Web site and the Mobile App.</h5>

    Though we try to make the Web site and the Mobile App available twenty-four (24) hours a day, seven (7) days a week, except for planned down-time for maintenance, we do not warrant that the Web site or the Mobile App will be at all times available.

    We do not warrant that your computer, tablet, and/or smartphone will be able to access and/or support the Web site or the Mobile App.

    <h5>2.8.	Right of Refusal, Limitation, Discontinuation, and Termination.</h5>

    We reserve the right to refuse to provide access to the Web site and/or the Mobile App for any reason at any time in our sole and exclusive discretion. We may, in our sole and exclusive discretion, limit or cancel a User Account for any reason whatsoever, including but not limited to a breach or violation by the User of any of the terms or provisions of the Terms of Use or any published GetElectrician policy or procedure; a discredit of GetElectrician by a User; misrepresentation of GetElectrician by making claims contrary to GetElectrician literature; ethical or legal violations that may cause GetElectrician to suffer damages; or any other material cause, in the sole discretion of GetElectrician.

    In the event that we make a change to or cancel an Account, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time your Account was created; however, the failure to do so shall not result in any liability, including liability for lost data deleted as a result of the Account termination. In the event we terminate your Account, you shall no longer be eligible to be a GetElectrician User at any time in the future, unless permission is granted to you, in writing, by us, in our sole and exclusive discretion.

    In addition, although the Web site and the Mobile App are intended to be accessible worldwide, the Web site may not be available to all persons in all geographic locations or jurisdictions. GetElectrician reserves the right to limit the availability of the Web site and/or the Mobile App to any person, geographic area or jurisdiction it so desires, in our sole discretion, and to limit the quantities of any such product or service that it provides. Any offer for any product or service made via the Web site or the Mobile App is void where prohibited. By choosing to access the Web site and/or the Mobile App from any location other than the United States, you accept full responsibility for compliance with all local laws. You are also subject to U.S. export controls and are responsible for any violations of U.S. embargoes or other federal rules and regulations restricting exports. The United States controls the export of products and information to certain countries including, Iraq, Libya, North Korea, Cuba (or a Cuban national or resident), Syria, and Iran, and to anyone on the Department of Commerce Table of Deny Orders and the Treasury Department's list of Specially Designated Nationals. You agree to comply with all such restrictions and not to export or re-export the any Material, as defined herein, to countries or persons prohibited under the export control laws. By downloading the Material, you are agreeing that you are not in a country where such export is prohibited or are a person or entity to which such export is prohibited. You are responsible for compliance with the laws of your local jurisdiction regarding the export, import, or re-export of and products or services.
    <h5>2.9. 	Prohibited Uses of Web site and the Mobile App.</h5>

    In addition to the other restrictions on use set forth herein, you agree and acknowledge that you shall not use the Web site or the Mobile App: (a) for any unlawful purpose; (b) to solicit Users to perform or participate in any unlawful acts or to engage in acts that are unrelated to the purpose(s) of the Web site and/or the Mobile App; (c) to violate any international, governmental, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Web site or the Mobile App; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Web site or the Mobile App. We reserve the right to terminate your use of the Web site and/or the Mobile App for violating any of the prohibited uses or for any other reason in our sole and exclusive decision.

    <h4>SECTION III: ACCOUNTS; USE OF THE WEB SITE, MOBILE APP</h4>
    <h5> 3.1. 	Online Accounts, Generally.</h5>

    Users shall be given the opportunity to register via an online registration form to create a User account, (your “Account,”) that will allow you to receive information from us and/or to participate in certain features of the Web site and/or the Mobile App. We will use the information you provide in accordance with our Privacy Policy. By registering with us, you represent and warrant that all information you provide on the registration form is current, complete and accurate to the best of your knowledge. You agree to maintain and promptly update your registration information so that it remains current, complete and accurate. During the registration process, you will be required to choose a password. You acknowledge and agree that we may rely on this password to identify you. You are responsible for all use of your Account, regardless of whether you authorized such access or use, and for ensuring that all use of your Account complies fully with the provisions of these Terms of Use. Accounts may be cancelled at any time via the Account settings. Users agree further and acknowledge that any Account profile they create may be edited, removed, modified, published, transmitted, and displayed by us, and they waive any rights they may have in having the material altered or changed in a manner not agreeable to them.

    <h5>3.2. 	Transfer Prohibited.</h5>

    You agree you shall not sell, trade or transfer that Account to any other person or entity except as provided for herein.

    <h5>3.3. 	Account Types.</h5>

    Use of the Web site, however, Service Providers wishing to offer their services via Web site may be required to pay a fee as set forth on the Web site at all times, including at the time of purchase, and which may be subject to change in our sole and exclusive discretion, without notice. Such services shall be governed by our Service Provider Terms of Service.

    <h5>3.4. 	Account Guidelines.</h5>

    The Web site and/or the Mobile App may contain the ability to communicate with other Users, share via social media; participate in any comments sections, discussion forums, Web sites, landing pages, social media outlets, sales channels, and/or other interactive features, (collectively "Interactive Areas,”) in which Users and third parties may communicate. By participating in Interactive Areas, you agree and acknowledge that you:

    <ul>
        <li>Shall not upload, distribute or otherwise publish to the Web site or the Mobile App any libelous, defamatory, obscene, pornographic, abusive, or otherwise illegal material; and</li>

        <li>Shall not threaten or verbally abuse other Users, use defamatory language, or deliberately disrupt discussions with repetitive messages, meaningless messages or "spam”; and</li>

        <li>Shall not to use language that abuses or discriminates on the basis of race, religion, nationality, gender, sexual preference, age, region, disability, etc. Hate speech of any kind is grounds for immediate and permanent suspension of access to the Web site; and</li>

        <li>Shall not personally attack another User. Personal attacks are a direct violation of these Terms of Use and are grounds for immediate and permanent suspension of access to the Web site; and</li>

        <li>Shall not use the Interactive Areas to distribute or otherwise publish any material containing any solicitation of funds, advertising or solicitation for goods or services except as envisioned by the express purpose of the Web site; and</li>

        <li>Shall not upload, post or otherwise transmit any content that violates any state, national or international law or engage in activity that would constitute a criminal offense or give rise to a civil liability; and</li>

        <li>Shall not post unauthorized commercial communications (such as spam); and</li>

        <li>Shall not upload, post or otherwise transmit any content that advocates or provides instruction on illegal activity or discuss illegal activities with the intent to commit them; and</li>

        <li>Shall not upload, post or otherwise transmit content that does not generally pertain to the designated topic or theme of any Interactive Area; and</li>

        <li>Shall not impersonate any person or entity, including, but not limited to, any of our employees, or falsely state or otherwise misrepresent your affiliation with any person or entity; and</li>

        <li>Shall not interfere with any other User's right to privacy, including by harvesting or collecting personally-identifiable information about other Users or distributing private information about another User or any third party; and</li>

        <li>Shall not engage in unlawful multi-level marketing, such as a pyramid scheme; and</li>

        <li>Shall not upload, post or otherwise transmit any “chain letters”; and</li>

        <li>Shall not upload, post or otherwise transmit any content, software or other materials which contain a virus, malicious code or other harmful, damaging, interfering, injuring or disruptive component; and</li>

        <li>Shall not post false or misleading indications of origin or statements of fact; and</li>

        <li>Shall not upload, post or otherwise transmit any content, software or other materials which restrict or inhibit any other User from using and enjoying the Web site or the Mobile App; and</li>

        <li>Shall not interfere with or disrupt the Web site, the Mobile App, the Interactive Areas or the servers or networks connected to the same, or disobey any requirements, procedures, policies or regulations of networks connected to the Web site, the Mobile App and/or the Interactive Areas; and</li>

        <li>Shall not intentionally supply incorrect information about yourself or a Client on any form, resume, listing, group or event; and</li>

        <li>Shall not violate, plagiarize or infringe the rights of third parties including, without limitation, copyright, trademark, patent, rights of privacy or publicity or any other proprietary right; and</li>

        <li>Shall not facilitate or encourage any violations of these Terms of Use or our policies.</li>
    </ul>
        <h5>3.5. 	Rights in Submissions.</h5>

    Should you submit, display, publish or otherwise post any content to an Interactive Area, (a “Submission,”) you agree to grant to us and our partners and affiliates a perpetual, irrevocable, transferable limited, non-exclusive, sub-licensable, worldwide, fully-paid, royalty-free license to use, modify, publish, edit, perform, publicly perform, publicly display, reproduce, and distribute such Submission in any and all forms, media, and technologies now known or hereinafter developed, alone or as part of other works, for hosting, indexing, caching, distributing, tagging, marketing, and for all other lawful purposes without the requirement to make payment to or seek permission from you or any third party. You acknowledge that Submissions are not confidential, and your communications may be read or intercepted by others. You acknowledge that by submitting Submissions to GetElectrician, no confidential, fiduciary, contractually implied, or other relationship is created between you and GetElectrician other than pursuant to this Agreement.

    You represent and warrant that you own or have a valid license to use any and all Submissions and otherwise have the right to grant the license set forth herein, and the displaying, publishing or posting of any Submissions does not and will not violate the privacy rights, publicity rights, copyrights, trademark rights, patents, contract rights or any other intellectual property rights or other rights of any person or entity.

    <h5>3.6. 	Right to Monitor.</h5>

    We shall have the right to monitor your Account in our sole and exclusive discretion.

    <h5>3.7.	Account Security.</h5>

    For security purposes, we strongly advise you to keep your User ID and password confidential and to change your Password if you think it may have been compromised. You are entirely responsible for maintaining the secrecy of this information. In addition, we recommend that you quit your entire browser application when you have completed your time on the Web site. This is particularly important when you are using the Web site at a public location, such as an Internet café, library, or workplace or on a public device. You agree to immediately notify GetElectrician in the event of any security breach or unauthorized use of your User ID, password, or any or all of your registration information. So that you can take advantage of the latest security technology, we recommend that you use the most recent version of your browser.

    <h5>3.8.	No Responsibility for User Submissions.</h5>

    GetElectrician is not liable for Submissions provided by any Users or third parties. Such Submissions do not represent the views of GetElectrician, its subsidiaries, or its partners.

    <h5>3.9.	User Communications.</h5>

    GetElectrician does not screen communications in advance and is not responsible for screening or monitoring material and content posted by users. If a User notifies GetElectrician of communications, which allegedly do not conform to these Terms, GetElectrician may investigate the allegation to determine, in good faith and its sole discretion, whether to remove or to request the removal of the communication. GetElectrician has no liability or responsibility to users for performance or nonperformance of such activities.

    <h5>3.10.	Termination.</h5>

    You may terminate your use of the Web site and/or the Mobile App at any time by writing to us at customerservice@getelectrician.com. In no event shall a refund be due or owing should a User elect to terminate use of the Web site or the Mobile App.

    <h4>SECTION IV: HIRING, FEES</h4>

    <h5>4.1.	Fees.</h5>

    At the present, the placing of a Listing on the Web site and/or the Mobile App is free; however, we may charge a listing or other fees to Clients in the future. In such an event, we shall update these Terms accordingly.

    <h5>4.2. 	Unauthorized Business.</h5>

    In addition to the prohibited uses set forth in these Terms, the Web site may not be used by an Client to cause us to advertise on his, her, or its behalf employment in any of the following service areas, (“Unauthorized Business:”) (a) sale of adult goods or services; (b) sale of products or services in the following sectors: gambling, securities, drugs, arms and weapons or other highly-regulated sectors; or (c) for sales of illegal goods or services, which shall include but not be limited to illegal software or illegal downloads such as music, films or games.

    <h5>4.3. 	Accuracy of Listing; Non-Disclosure of Client.</h5>

    Client shall be responsible for ensuring any and all Listings are complete and accurate at all times, including but not limited to the salary, qualifications required, benefits, and job location. We undertake no responsibility and shall have no liability for verifying or failing to verify any information provided to us from an Client when creating a Listing.

    <h5>4.4.	Compliance with Laws.</h5>

    In addition to the other requirements to comply with laws, regulations, and terms set forth herein, Clients who post Listings to the Web site agree to obey any and all local, state, and federal regulations and laws regarding offers of employment and pre-screening therefor.

    <h5>4.5.	Right to Revise.</h5>

    GetElectrician shall have the right to revise any Listing to correct any errors or omissions, in its sole and exclusive discretion, but shall not be obligated to do so. You agree and acknowledge that GetElectrician shall not be liable for any errors in revisions.

    <h5>4.6.  Payment for Services.</h5>

    Clients may pay for Services per the payment agreement with the Service Provider. Payment shall be made via the Web site, using one of our select payment processors, which include Stripe and PayPal.

    <h5>4.7. 	Refunds and Returns.</h5>

    Due to the nature of the Web site and the Mobile App, no refunds or returns shall be granted at any time, including but not limited to erroneous Listing information, lack of Listings, failure to locate a qualified Service Provider, failure to win a project, business closure, or cancellation.
    <h4>SECTION V: INTELLECTUAL PROPERTY; PRIVACY</h4>

    <h5>5.1.	Intellectual Property Rights Not Waived.</h5>

    This is an Agreement for access to and use of the Web site and the Mobile App, and you are not granted a license to any software or intellectual property by these Terms of Use aside from the limited right to use the Mobile App. The Web site and the Mobile App are protected by U.S. and, where applicable, international intellectual property laws. The Web site and the Mobile App belongs to us and are the property of us or our licensors (if any). We retain all ownership rights in the Web site and the Mobile App.

    Furthermore, all material displayed or transmitted on this Web site and the Mobile App, including but not limited to text, logos, icons, the HTML-based computer programs used to generate the Web site, photographs, images, illustrations, video clips, audio clips, and graphics, (“Materials,”) are owned by us and are protected by U.S. and international copyright, trademarks, service marks, and other proprietary rights, laws and treaties.

    Except as provided, you may not copy, reproduce, publish, transmit, transfer, sell, rent, modify, create derivative works from, distribute, repost, perform, display, or in any way commercially exploit the Materials, nor may you infringe upon any of the copyrights or other intellectual property rights contained in the Materials. You may not remove or alter, nor cause to be removed or altered, any copyright, trademark, or other proprietary notices or visual marks and logos from the Materials. You may not register any domain names that may confuse or contain any portion of the GetElectrician trade names or marks.

    You may make a single print copy of any Materials provided by us on the Web site for personal, non-commercial use only, provided that you do not remove nor cause to be removed any copyright, trademarks, or other proprietary notices or visual marks or logos from the Materials. You may not archive or retain any of the Materials accessed on the Web site without our express written permission. All requests for archiving, republication or retention of any part of the Materials must be in writing to us and must clearly state the purpose and manner in which the Material will be used. Requests for permission to archive, retain, or republish any part of the Materials may be submitted to legal@ getelectrician.com.

    GetElectrician makes no claims that the Materials are appropriate or may be downloaded outside the United States. Access to the Materials may not be legal by certain persons or in certain countries. If you access the Web site from outside the United States, you do so at your own risk and are responsible for compliance with the laws of your jurisdiction, including those relating to privacy.

    You acquire no rights or licenses whatsoever in the Materials other than the limited rights to use the Web site in accordance with these Terms of Use. Any of the Materials accessed or downloaded from this site must be accessed or downloaded in accordance with the Terms of Use specified in this Agreement. We reserve any rights not expressly granted under these Terms of Use.

    Any software that is made available for downloading from the Web site or the Mobile App, ("Software,") or any affiliate and/or partner Web site is protected by U.S. copyright and may be protected by other rights. The use of such Software is governed by the terms of the software End-User License Agreement or designated "Legal Notice," as may be in force.

    <h5>5.2.	Feedback.</h5>

    You may have the opportunity to provide reviews, suggestions, ideas, and feedback, (collectively, “Feedback.”) Should you so provide such Feedback you grant us sole ownership of the same, which includes, without limitation, the right for us or any third party we designate, to use, copy, transmit, excerpt, publish, distribute, publicly display, publicly perform, create derivative works of, host, index, cache, tag, encode, modify and adapt (including without limitation the right to adapt to streaming, downloading, broadcast, mobile, digital, thumbnail, scanning or other technologies) in any form or media now known or hereinafter developed. All such Feedback shall be treated as non-confidential.

    If it is determined that you retain moral rights (including rights of attribution or integrity) in the content submitted by you, you hereby declare that (a) you do not require that any personally-identifying information be used in connection with the content, or any derivative works of or upgrades or updates thereto; (b) you have no objection to the publication, use, modification, deletion and exploitation of the content by us or our licensees, successors and assigns; (c) you forever waive and agree not to claim or assert any entitlement to any and all moral rights of an author in any of the content; and (d) you forever release us, and our licensees, successors and assigns, from any claims that you could otherwise assert against us by virtue of any such moral rights. You also permit any other User to access, view, store or reproduce the content for that User's personal use.
    Notwithstanding the foregoing, you acknowledge that your Feedback may contain concepts, ideas, materials, proposals, suggestions and the like relating to GetElectrician or its initiatives, (your “Ideas.”) With respect to your Ideas you acknowledge that: (a) we receive numerous submissions from many parties and/or may have independently-developed and/or considered ideas similar to your Ideas, and that our review of your Ideas is not an admission of novelty, priority or originality; and (b) our use of any ideas similar to your Ideas, whether based on your Feedback or Submissions, provided to us by third parties, or independently-developed or considered by us, shall be without obligation to you.

    <h5>5.3.	Grant of License.</h5>

    You agree to grant us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, and database rights (but no other rights) you have in any content contained in any Listing or your Account profile, in any media now known or not currently known, with respect to the same. You agree to allow GetElectrician to store or re-format your content and display your content in any way as we so choose. GetElectrician will only use personal information in accordance with our Privacy Policy.

    <h5>5.4.	Confidential Information of Users.</h5>

    Users may obtain personal information from another User as a result of their use of the Web site and the Mobile App. Without obtaining prior permission from the User, this personal information shall only be used for the express purpose for which it is provided or for GetElectrician-related communications and shall be held in strict confidence in accordance with our Privacy Policy. We have not granted you a license to use the information for unsolicited commercial messages. Without limiting the foregoing, without express consent from the User, you are not licensed to add any contact, Client or other User to your email or physical mail list. For more information, see our Privacy Policy.

    <h4>SECTION VI: Third party Advertisements, Promotions, and Links</h4>

    <h5>6.1.	Third Party Advertisements and Promotions.</h5>

    We may, from time to time, run advertisements and promotions from third parties on the Web site and/or the Mobile App. Your dealings or correspondence with, or participation in promotions of, advertisers other than us, and any terms, conditions, warranties or representations with such dealings, are solely between you and such third party. We are not responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of third party advertisers on the Web site or the Mobile App.

    <h5>6.2. 	Use of Third Party Tools.</h5>

    We may provide you with access to third party tools over which we neither monitor nor have any control nor input.

    You acknowledge and agree that we provide access to such tools “as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third party tools.

    Any use by you of optional tools offered through the Web site and/or the Mobile App is entirely at your own risk and discretion, and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third party provider(s).

    We may also, in the future, offer new services and/or features through the Web site and/or the Mobile App, including but not limited to the release of new tools. Such new features and/or services shall also be subject to these Terms of Use.

    <h5>6.3. 	Third Party Links.</h5>

    Certain content, products and services available via our Web site and the Mobile App may include materials from third parties.

    Third party links on the Web site may direct you to third party Web sites and/or services that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy, and we do not warrant and will not have any liability or responsibility for any third party materials or Web sites and/or services, or for any other materials, products, or services of third parties.

    We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third party with whom you connect via the Web site and/or the Mobile App. Please review carefully the third party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third party transactions should be directed to the third party.

    <h4>SECTION VII: DISCLAIMERS; LIMITATIONS OF LIABILITY; INDEMNIFICATION</h4>

    <h5>7.1. 	Disclaimer of Warranty; Limitation of Liability.</h5>

    <ul>
        <li>(A) YOU AGREE THAT USE OF THE WEB SITE AND/OR THE MOBILE APP IS AT YOUR SOLE RISK. NEITHER US nor our AFFILIATES NOR ANY RESPECTIVE DIRECTORS, OFFICERS, USERS, EMPLOYEES, CONTRACTORS, PARENTS, SUBSIDIARIES, AGENTS, THIRD PARTY CONTENT PROVIDERS or LICENSORS, WARRANT THAT THE use of the Web site OR THE SERVICE PROVIDER SHALL BE UNINTERRUPTED OR ERROR FREE; NOR DO we MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE WEB SITE AND/OR THE MOBILE APP OR AS TO THE ACCURACY, RELIABILITY OR CONTENT OF ANY INFORMATION PROVIDED.

        <li>(B) ANY DOWNLOADABLE SOFTWARE, PRODUCTS OR OTHER MATERIALS, WITHOUT LIMITATION, IS PROVIDED ON AN "AS IS" AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE AGAINST INFRINGEMENT, OTHER THAN THOSE WARRANTIES WHICH ARE IMPLIED BY AND INCAPABLE OF EXCLUSION, RESTRICTION OR MODIFICATION UNDER THE LAWS APPLICABLE TO THESE TERMS OF USE.</li>

        <li>(C) ALTHOUGH ALL INFORMATION AND MATERIALS CARRIED ON THE WEB SITE ARE BELIEVED TO BE RELIABLE, WE MAKE NO REPRESENTATIONS, NEITHER EXPRESSLY NOR IMPLIEDLY, AS TO THE ACCURACY, COMPLETENESS, TIMELINESS OR RELIABILITY OF THE WEB SITE.</li>

        <li>(D) IN NO EVENT SHALL WE, our AFFILIATES NOR ANY RESPECTIVE DIRECTORS, OFFICERS, USERS, EMPLOYEES, CONTRACTORS, PARENTS, SUBSIDIARIES, AGENTS, THIRD PARTY CONTENT PROVIDERS or LICENSORS BE LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSS OR DAMAGES WHATSOEVER, INCLUDING BUT NOT LIMITED TO ANY DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL, INCIDENTAL, PUNITIVE, OR OTHER DAMAGES, INCLUDING BUT NOT LIMITED TO EXEMPLARY, RELIANCE, OR CONSEQUENTIAL DAMAGES, LOSS OF PROFITS, DAMAGES RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION, personal injury or death, property damage, EXEMPLARY REPUTATIONAL HARM, OR LOSS OF INFORMATION OR DATA, ARISING OUT OF OR RELATED TO THE USE OF OR INABILITY TO USE THE WEB SITE AND/OR THE MOBILE APP, ANY INTERACTIONS WITH A USER, AND/OR ANY employment and/or employee/Client relationships facilitated by or through the web site AND/OR THE MOBILE APP.</li>

        <li>(E) WE DISCLAIM ANY AND ALL LIABILITY OF ANY KIND FOR ANY UNAUTHORIZED ACCESS TO OR USE OF YOUR PERSONALLY-IDENTIFIABLE INFORMATION. BY utilizing THE WEB SITE AND/OR THE MOBILE APP YOU ACKNOWLEDGE AND AGREe TO OUR DISCLAIMER OF ANY SUCH LIABILITY. IF YOU DO NOT AGREE, YOU SHOULD NOT ACCESS OR OTHERWISE Utilize THE WEB SITE or the mobile app.</li>
        <li>(F) GetElectrician' LIABILITY, AND (AS APPLICABLE) THE LIABILITY OF our AFFILIATES AND ANY RESPECTIVE DIRECTORS, OFFICERS, USERS, EMPLOYEES, CONTRACTORS, PARENTS, SUBSIDIARIES, AGENTS, THIRD PARTY CONTENT PROVIDERS AND LICENSORS TO YOU OR ANY THIRD PARTIES IN ANY CIRCUMSTANCE IS LIMITED TO THE GREATER OF (A) THE AMOUNT OF FEES YOU PAY TO GetElectrician IN THE TWELVE (12) MONTHS PRIOR TO THE ACTION GIVING RISE TO LIABILITY, OR (B) ONE HUNDRED U.S. DOLLARS ($100.00,) WHICHEVER IS GREATER.</li>
    </ul>
    SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO CERTAIN USERS.

    The above limitations shall survive these Terms and inure to the benefit of us and our affiliates and respective directors, officers, Users, employees, contractors, parents, subsidiaries, agents, Third party content providers and licensors.

    <h5>7.2. 	INDEMNIFICATION.</h5>
    You agree to defend, indemnify and hold us harmless, as well as our AFFILIATES NOR ANY RESPECTIVE DIRECTORS, OFFICERS, USERS, EMPLOYEES, CONTRACTORS, PARENTS, SUBSIDIARIES, AGENTS, THIRD PARTY CONTENT PROVIDERS or LICENSORS, from and against all claims, SUITS, and expenses, including attorneys' fees, arising out of OR RELATED TO (a) YOUR USE OF THE WEB SITE AND/OR THE MOBILE APP; (B) your noncompliance with or breach of this Agreement; (C) your use of third party SERVICES, products, links, advertisements, and/or tools; (D) YOUR VIOLATIONS OF ANY THIRD PARTY RIGHTS, INCLUDING THIRD PARTY INTELLECTUAL PROPERTY RIGHTS; or (E) the unauthorized use of the Web site AND/OR THE MOBILE APP by any other person using your information. GetElectrician shall provide notice to you promptly of any such claim, suit, or proceeding and may assist you, at your expense, in defending any such claim, suit or proceeding.

    <h4>SECTION VII: GOVERNING LAW; ARBITRATION</h4>
    <h5>8.1. 	Governing Law.</h5>

    These Terms shall be governed and construed in accordance with the laws of the state of Michigan without regard to its conflicts of law provisions. You agree to submit to the personal jurisdiction of the courts located in New York County, New York, and any cause of action that relates to or arises from these Terms, Web site and/or the Mobile App must be filed therein unless subject to the binding arbitration provisions of Section 8.2, infra.

    <h5>8.2. 	Arbitration.</h5>

    The Parties agree that any dispute concerning, relating, or referring to these Terms, Web site, and/or the Mobile App shall be resolved exclusively by binding arbitration in accordance with the substantive laws of the state of New York and shall be brought for arbitration in New York County, New York, pursuant to the Commercial Arbitration Rules of the American Arbitration Association. The arbitrator and not any federal, state, or local court or agency shall have exclusive authority to resolve any dispute relating to the interpretation, applicability, enforceability, conscionability, or formation of this contract, including but not limited to any claim that all or any part of this contract is void or voidable. Nothing herein prevents either Party from seeking any interim injunction it deems necessary in order to preserve the status quo prior to the resolution of any dispute, in any jurisdiction. Either or both Parties may initiate the arbitration proceedings, and both shall share the expenses and fees of the arbitration equally.

    <h4>SECTION IX: MISCELLANEOUS</h4>
    <h5>10.1. 	Customer Service.</h5>

    Should you have any questions, comments or concerns regarding the Web site, customer service may be contacted at any time via email at customerservice@getelectrician.com. We strive to return all customer service inquires within forty-eight (48) business hours, between 8 a.m. and 8 p.m. EST/EDT.

    <h5>9.2. 	Affiliate Disclosure.</h5>

    We may have an affiliate relationship with third parties and affiliates to whose products and/or services we link and promote. Because of this relationship we may earn a commission on products purchased by you from a third party affiliate.

    <h5>9.3. 	Authority.</h5>

    Each Party represents and warrants to the other that it has full power and authority to enter into this Agreement and that it is binding upon such Party and enforceable in accordance with its Terms.

    <h5>9.4. 	Waiver.</h5>

    Any waiver of a right under these Terms of Use shall only be effective if agreed or declared in writing. No waiver of any term of this Agreement shall be deemed a further or continuing waiver of such term or any other term and shall not prevent a Party from exercising that right in the future. The rights and remedies herein provided are cumulative and not exclusive of any rights and remedies provided by law.

    <h5>9.5. 	Force Majeure.</h5>

    We shall not be bound to meet any obligation if prevented from doing so as a consequence of acts of god or force majeure, including but not limited to measures taken or imposed by any government or public authority or in case of any other event beyond our control, including but not limited to natural disasters (such as storm, hurricane, fire, flood, earthquake), war, civil unrest, terrorist activities, states of emergency, government sanctions, embargos, nationalizations, strikes and breakdowns of public utilities (such as of electricity or telecommunication services). We shall use all reasonable efforts to notify you of the circumstances causing the delay and to resume performance as soon as possible, both without undue delay.

    <h5>9.6. 	Assignment.</h5>

    We shall have the right to assign and/or transfer these Terms of Use and our rights and obligations hereunder to any third party after notifying you as provided for herein. You agree and acknowledge that you shall not assign or transfer its rights or sub-contract or delegate the performance of any of your obligations under these Terms of Use without our prior written consent in our sole and exclusive discretion or as provided for herein.

    <h5>9.7. 	Rights of Third Parties.</h5>

    These Terms do not give any right to any third party unless explicitly stated herein.

    <h5>9.8. 	Relationship of the Parties.</h5>

    The Parties are independent contractors under these Terms, and nothing herein shall be construed to create an employment agreement, partnership, joint venture, franchise relationship or agency relationship between them. Neither Party has authority to enter into Terms of any kind in the name of the other Party. You are an independent contractor responsible for controlling your own time and actions. GetElectrician is not to be considered a Client, and GetElectrician will not be responsible in any way for any employment decision made by any User and/or Client.

    <h5>9.9. 	Severability.</h5>

    If any part of this Agreement is determined to be invalid or unenforceable by applicable law, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of this Agreement will continue in effect.

    <h5>9.10.	Notices.</h5>

    Except as explicitly stated otherwise, any notices shall be given by postal mail to Xavior, Inc., d/b/a GetElectrician, a New York corporation with a principal place of business located at 40 First Avenue, #15C, New York, NY 10009, or, in the case of any User, to the email address you provide to us (either during the registration process or when your email address changes.) Notice shall be deemed given twenty-four (24) hours after email is sent, unless the sending party is notified that the email address is invalid.  Alternatively, we may give you notice by certified mail, postage prepaid and return receipt requested, to the address provided to us upon Account registration. In such case, notice shall be deemed given three (3) days after the date of mailing.

    <h5>9.11. 	Entire Agreement.</h5>

    This Agreement constitutes the entire Agreement between the Parties, and no other representations or promises of any kind shall be binding between the same. If any portion of this Agreement is held to be invalid, the balance of this Agreement shall remain in full force and effect.
`;