import { connect } from 'react-redux';
import { updateWorkHourValue } from '../../actions/register';
import { fetchSaveWorkHour, fetchRemoveWorkHour } from '../../actions/api';
import WorkHour from '../components/WorkHour';

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.ee.user,
        workHour: state.register.workHour,
        workHourList: state.register.workHourList,
        history: ownProps.history,
    }
};

const mapDispatchToProps = dispatch => ({
    handleChange: (name, value) => {
        dispatch(updateWorkHourValue(name, value));
    },
    handleAddWorkHour: (history) => {
        dispatch(fetchSaveWorkHour(history));
    },
    handleRemoveWorkHour: (day) => {
        dispatch(fetchRemoveWorkHour(day));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkHour);


