import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateReviewValue, updateProjectIdProviderId } from '../../actions/ee';
import WriteReview from '../components/WriteReview';
import { fetchSaveReview, fetchSelect } from '../../actions/api';
import Container from '@material-ui/core/Container';

const mapStateToProps = (state, ownProps) => {
    const projectId = (ownProps.match.params.projectId !== undefined) ? ownProps.match.params.projectId : null;
    const providerId = (ownProps.match.params.providerId !== undefined) ? ownProps.match.params.providerId : null;
    const review = {
        ...state.ee.review,
        projectId: projectId,
        providerId: providerId,
    }
    return {
        user: state.ee.user,
        review: review,
        history: ownProps.history,
    }
};

const mapDispatchToProps = dispatch => ({
    setProjectIdProviderId: (projectId, providerId) => {
        dispatch(updateProjectIdProviderId(
            projectId, 
            providerId
        ));
    },
    handleChange: (name, value, event) => {
        dispatch(updateReviewValue(
            name, 
            value
        ));
    },
    onSaveClick: (history) => {
        dispatch(fetchSaveReview(history));
    },
});


class WriteReviewContainer extends Component {
    componentDidMount() {
        this.props.setProjectIdProviderId(this.props.review.projectId, this.props.review.providerId);
    }

    componentWillReceiveProps(newProps) {
    }

    render() {
        return (
            <section id="gete-project" className="gete-project">
                <Container maxWidth="md">
                    <WriteReview 
                        review={this.props.review} 
                        user={this.props.user}
                        history={this.props.history} 
                        handleChange={this.props.handleChange}
                        onSaveClick={this.props.onSaveClick}
                    />
                </Container>
            </section>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WriteReviewContainer);
