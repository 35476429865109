import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import ImageUploader from 'react-images-upload';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%'
    },
    formBox: {
        padding: '0px 20px 10px 20px',
    },
    wide: {
        width: '100%',
        maxWidth: '340px',
    },
    flex: {        
        display: 'flex',
    },
    narrow: {
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    margin: {
        marginLeft: '4px',
        marginRight: '4px',
    },
    marginLeft: {
        marginLeft: '4px',
    },
    margin20: {
        marginBottom: '20px',
    },
    zIndex0: {
        zIndex: 0,
    }, 
    subLabel: {
        fontWeight: 600, 
    },
    minWidth: {
        minWidth: '450px',
    },
    profileImage: {
        width: '200px',
    }
});

const ProfileImage = (props) => {
    const {
        classes,
        // user,
        profileImage,
        history,
        handleChange,
        handleChangeTest,
        handleUpdateProfileImage,
        onDrop,
    } = props;

    const submitDisabled = profileImage.file === undefined;

    return (
        <Container maxWidth="lg">
            <div className={classes.root} >
                <div 
                    htmlFor="profileImage"
                    className={clsx(classes.subLabel, 'ee-color', classes.wide)}
                >
                    {profileImage && <div className={clsx(classes.profileImage)}>
                        <img class="profile-image" src={profileImage} />
                    </div>}
                    <ImageUploader
                        withIcon={true}
                        buttonText='Choose images'
                        onChange={onDrop}
                        imgExtension={['.jpg', 'jpeg', '.gif', '.png', '.gif']}
                        maxFileSize={5242880}
                    />
                </div>
                {/* <TextField
                    required
                    id="profileImage"
                    // label="Profile Image"
                    value={profileImage.file}
                    onChange={(event) => handleChange(event)}
                    className={clsx(classes.wide, classes.margin, classes.zIndex0, classes.flex)}
                    margin="normal"
                    variant="outlined"
                    type="file"
                /> */}
                <div className={clsx(classes.wide, classes.margin20, classes.flex)}>      
                    <Button
                        disabled={submitDisabled}
                        variant="contained" 
                        component="span" 
                        color="green"
                        onClick={() => handleUpdateProfileImage(history)}
                        className={submitDisabled ? clsx(classes.wide, classes.marginLeft) : clsx(classes.wide, 'ee-bg-color', classes.marginLeft)}
                    >
                        Save Profile Image
                    </Button>
                </div>
            </div>
        </Container>
    );
};

export default withStyles(styles)(ProfileImage);

