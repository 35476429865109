import * as types from '../constants/actionTypes';

const workZoneStates = ["NJ", "NY"]
const workZoneCounties = {
    NJ : ['Bergen', 'Burlington', 'Camden', 'Cape May', 'Cumberland', 'Essex', 'Gloucester', 'Hudson', 'Hunterdon', 'Mercer', 'Middlesex',
    'Monmouth', 'Morris', 'Ocean', 'Passaic', 'Salem', 'Somerset', 'Sussex' , 'Union'],
    NY: ['New York','Bronx', 'Kings', 'Queens', 'Richmond']
};

const states = ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY",
"LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND",
 "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"];

const initialCompany = {
    name: undefined,
    registeredAt: undefined,
    companyId: undefined,
};

const initialLicense = {
    name: undefined,
    no: undefined,
    issuedBy: undefined,
    issuedAt: undefined,
};

const initialWorkzone = {
    NJ: [],
    NY: [],
};

export const initialState = () => ({
    firstName: undefined,
    lastName: undefined,
    displayName: undefined,
    email: undefined,
    password: undefined,
    verifyPassword: undefined,
    type: 'customer',
    providerType: 'licensedCompany',
    error: {},
    agreement: {
        codeOfEthics: false,
        termsOfUse: false,
        termsOfUseProvider: false,
    },
    company: initialCompany,
    license: initialLicense,
    address: {},
    insurance: [],
    permit: [],
    registerStep: 0,
    workZoneStates: workZoneStates,
    workZoneCounties: workZoneCounties,
    workzone: initialWorkzone,
    workHour: {},
    workHourList: [],
    states: states,
    updateInsurance: false,
    updaetPremit: false,
    profileImage: {
        file: undefined,
        image: undefined,
    },
    rate: 0,
});

const processWorkZone = (workzones) => {
    let NJWorkZoneList = [];
    let NYWorkZoneList = [];
    workzones.map((workzone) => {
        if(workzone.state === 'NY') {
            NYWorkZoneList.push({
                value: workzone.county,
                label: workzone.county
            });
        }
        if(workzone.state === 'NJ') {
            NJWorkZoneList.push({
                value: workzone.county,
                label: workzone.county
            });
        }
    })
    return {
            NY: NYWorkZoneList,
            NJ: NJWorkZoneList,
        }
}

export default function register(prevState = initialState(), action) {
    const state = {
        ...prevState,
    };

    switch (action.type) {
        case types.UPDATE_REGISTER_VALUE:
            if(action.name === 'termsOfUse' || action.name === 'termsOfUseProvider' || action.name === 'codeOfEthics') {
                const value = state.agreement[action.name];
                return {
                    ...state,
                    agreement: {
                        ...state.agreement,
                        [action.name]: !value,
                    }
                }                    
            }
            return {
                ...state,
                [action.name]: action.value,
            }
        case types.UPDATE_STEP: {
            return {
                ...state,
                registerStep: action.step,
            }
        }
        case types.UPDATE_COMPANY_VALUE: {
            return {
                ...state, 
                company: {
                    ...state.company,
                    [action.name]: action.value,
                }
            }
        }
        case types.UPDATE_LICENSE_VALUE: {
            return {
                ...state, 
                license: {
                    ...state.license,
                    [action.name]: action.value,
                }
            }
        }
        case types.UPDATE_USER_COMPANY: {
            return {
                ...state, 
                company: {
                    ...state.company,
                    name: action.userCompany.name,
                    registeredAt: action.userCompany.registeredAt,
                    companyId: action.userCompany.companyId,
                }
            }
        }
        case types.UPDATE_USER_LICENSE: {
            return {
                ...state, 
                license: action.userLicense,
            }
        }
        case types.RECEIVED_WORKZONE: {
            return {
                ...state,
                workzone: processWorkZone(action.workzone),
            }    
        }
        case types.UPDATE_WORK_ZONE_COUNTY: {
            return {
                ...state,
                workzone: {
                    ...state.workzone,
                    [action.state]: action.workZoneCounties,
                }
            }
        }
        case types.RECEIVED_WORKHOUR: {
            return {
                ...state,
                workHourList: action.workHour
            }
        }
        case types.UPDATE_WORKHOUR_VALUE: {
            return {
                ...state,
                workHour: {
                    ...state.workHour,
                    [action.name]: action.value,
                }
            }            
        }
        case types.UPDATE_ADDRESS_VALUE: {
            return {
                ...state,
                address: {
                    ...state.address,
                    [action.name]: action.value,
                }
            }
        }
        case types.UPDATE_PHONE_VALUE: {
            return {
                ...state,
                phone: action.value,
            }
        }
        case types.UPDATE_PROFILE_IMAGE_FILE_VALUE: {
            return {
                ...state,
                profileImage: {
                    ...state.profileImage,
                    file: action.value,
                    image: action.value,
                }
            }
        }
        case types.RECEIVED_PROFILE: {
            // GET /v1/register/profile returns the user's whole profile in JSON,
            // and a part of the profile is a **list** of the user's licenses.
            // as of Jan 26th 2023, we assume a user has only one license.
            // no change made for the API but here we try to get only the first element of the array
            var userLicense = !Array.isArray(action.profile.license) ? action.profile.license : action.profile.license[0];

            const newProfile = {
                ...state,
                address: action.profile.address || {},
                phone: action.profile.phone,
                company: action.profile.company || initialCompany,
                insurance: action.profile.insurance,
                permit: action.profile.permit,
                license: userLicense || initialLicense,
                workHourList: action.profile.workHour || [],
                website: action.profile.website,
                workzone: processWorkZone(action.profile.workZone || []),
                rate: action.profile.rate,
                profileImage: 'data:image/png;base64,' + action.profile.profileImage,
            }
            return newProfile;
        }
        case types.RECEIVED_PHONE: {
            return {
                ...state,
                phone: action.phone,
            }
        }
        case types.UPDATE_HOURLY_RATE: {
            return {
                ...state,
                rate: action.value,
            }
        }
        default:
            return {
                ...state
            };
    }
}
