import React, { Component } from 'react';
import { connect } from 'react-redux';
import Link from '@material-ui/core/Link';

const mapStateToProps = (state, ownProps) => {
    return {
        user : state.view.user,
        history: ownProps.history,
    }
};

// const mapDispatchToProps = dispatch => ({
// });

class HowItWorksContainer extends Component {
    componentDidUpdate() {
        const { user } = this.props;
    }

    render() {
        return (
            <div className="container">
                <div className="flex">
                    <div className="container">
                        <h2>GetElectrician and COVID-19</h2>
                            <div className="">
                                <h4>Staying safe during coronavirus while hiring electrical providers</h4>
                                It is more important than ever to ensure safety during the worse pandemic that hit our nation and the globe.  Your home still requires improvements / fixes, and safety is our goal, and with safety in mind, we have been educating our electrical service providers on the best methods to ensure everyone’s safety while performing electrical related jobs.  
                                Listed below are steps we have taken to ensure safest experience for both customers and electrical providers. 
                                <l>
                                    We have created additional section on electrical providers’ profile allowing electrical providers to list specific steps and precautions they are taking to ensure safety.  This includes wearing protective gear, such as mouth and nose covering when around others, gloves, shoes coverings etc.  
                                </l>
                                <l>
                                    We have provided an ability for our customers to specify additional requirements they may have related to coronavirus pandemic such as contact – less interaction as possible with electrical provider, cashless payments using payment applications such as Wenmo, paypal, etc, 
                                </l>
                                <l>
                                    We encouraged customers and electrical providers to use technology to minimize contact such as provide additional pictures via our portal or via text video messaging to ensure most effective and smooth communication.  
                                </l>
                                <l>
                                    We have asked both customers and electrical providers to sanitize before and after work as much as possible in accordance with Center for Disease Control and Prevention (CDC Guidance).  
                                </l>
                                <l>We have asked both customers and electrical providers to monitor your health daily and be alert of symptoms.  Should any of the symptoms be present, we ask both customers and electrical providers to postpone the service appointment until later time.  
                                </l>
                                <l>
                                    We are constantly providing guidance to both electrical providers and customers related to best practices as listed on Centers for Disease Control and Prevention <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html?CDC_AA_refVal=https%3A%2F%2Fwww.cdc.gov%2Fcoronavirus%2F2019-ncov%2Fprepare%2Fprevention.html">CDC Guidance</a>).
                                </l>
                            </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    null,
)(HowItWorksContainer);
