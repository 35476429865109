import React from 'react';
import zxcvbn from 'zxcvbn';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%'
    },
    wide: {
        width: '100%',
        maxWidth: '340px'
    },
    margin: {
        marginBottom: '4px !important',
        marginTop: '4px !important',
    }
});

//Should check this in the onChange function
const validatePasswordStrong = value => {
    if (value.length <= this.thresholdLength) throw new Error("Password is short");
    if (zxcvbn(value).score < this.minStrength) throw new Error("Password is weak");
};


const PasswordField = (props) => {
    const {
        classes,
        user,
        register,
        history,
        minStrength = 3, 
        thresholdLength = 7,
        password,
        validatePasswordStrong,
        onChange,
        hasErrors = false,
        errors,
        required,
        fieldId,
        label,
        placeholder,
        description,
        showStrength = false,
    } = props;

    const newMinStrength = typeof minStrength === 'number' ? Math.max( Math.min(minStrength, 4), 0 ): 3;
    const newThresholdLength = typeof thresholdLength === 'number' ? Math.max(thresholdLength, 7) : 7;
    const strength = zxcvbn(password).score
    const passwordLength = password.length;
    const passwordStrong = strength >= newMinStrength;
    const passwordLong = passwordLength > newThresholdLength;

    const counterClass = ['badge badge-pill', passwordLong ? passwordStrong ? 'badge-success' : 'badge-warning' : 'badge-danger'].join(' ').trim();

    const strengthClass = ['strength-meter mt-2', passwordLength > 0 ? 'visible' : 'invisible'].join(' ').trim();

    return (
        <div>
            {description && 
                <span className="d-block form-hint">{description}</span> 
            }
            <div>
                <TextField
                    type="password"
                    error={hasErrors}
                    helperText={(hasErrors && errors[0]) ? errors[0] : undefined}
                    required={required}
                    id={fieldId}
                    label={label}
                    placeholder={placeholder}
                    value={password}
                    onChange={onChange}
                    className={clsx(classes.wide, classes.margin)}
                    margin="normal"
                    variant="outlined"
                />
                {showStrength && <div className="position-absolute password-count mx-3">
                    <span className={counterClass}>{ passwordLength ? passwordLong ? `${thresholdLength}+` : passwordLength : '' }</span>
                </div>}
                {showStrength && <div className={strengthClass}>
                    <div className="strength-meter-fill" data-strength={strength}></div>
                </div>}
            </div>

        </div>
    );
};

export default withStyles(styles)(PasswordField);