import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import SimpleList from '../../components/SimpleList';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%',
        overflowX: 'auto',
        padding: '0',
        font: '14px',
    },
    formBox: {
        padding: '0px 20px 10px 20px',
    },
    wide: {
        width: '100%',
        maxWidth: '340px'
    },
    narrow: {
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    margin: {
        marginLeft: '4px',
        marginRight: '4px',
    },
    margin20: {
        marginBottom: '20px',
    },
    zIndex0: {
        zIndex: 0,
    },
    lightGrey: {
        color: '#c2c2c2',
    },
    green: {
        color: '#01DF01',
    }, 
    publicTopDay: {
        float: 'right',
        padding: '1rem',
        bottom: '0',
        backgroundColor: '#F0F0F0',
    },
    publicTopLeft: {
        padding: '5px',
        margin: '5px',
    },    
    publicTopRight: {
        float: 'right',
        padding: '1rem',
        display: 'flex',
    },
    profileImage: {
        width: '200px',
        height: '200px',
    },
    top: {
        display: 'flex',
    }
});

const accountActivation = (props) => {
    const {
        classes,
        user,
        history,
        accountActivation,
        handleSaveAccountActivationAction,
    } = props;

    const {
        action,
        message,
        enabled,
    } = accountActivation;

    return (
        <Container maxWidth="lg">
            <div className={clsx(classes.root, "flex")}>
                <form className="form-signin" name="loginFrom" role="form">
                    <h2 className="form-signin-heading">Account Activation <span className={clsx(classes.smallFont, "ee-color")}>GetElectrician</span></h2>
                    {message && <div className="warm-red">{message}</div>}
                    <br />
                    <div name = "accountActivation">
                        <div className="form-actions" >
                            <Button
                                disabled={!enabled}
                                variant="contained" 
                                component="span" 
                                onClick={() => handleSaveAccountActivationAction(action)}
                                className={action == 'Activate' ? clsx(classes.wide, 'ee-bg-color', 'flex') :clsx(classes.wide, 'flex')}>
                                {action}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </Container>
    );
};

export default withStyles(styles)(accountActivation);
