import React, { Component } from 'react';
import { connect } from 'react-redux';
import Link from '@material-ui/core/Link';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%',
        overflowX: 'auto',
        // maxWidth: '425px',
        // paddingBottom: '30px'
        color: 'white',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#468847',
        borderColor: '#0063cc',
        width: '180px',
        marginRight: '16px',
    },
    wide: {
        width: '100%',
        maxWidth: '340px'
    },
    narrow: {
        width: 200,
    },
    margin20: {
        marginBottom: '20px',
    },
    smallText: {
        fontSize: '12px',
        lineHeight: '1.6',
    }
});

const mapStateToProps = (state, ownProps) => {
    return {
        user : state.view.user,
        history: ownProps.history,
    }
};

class AboutUsContainer extends Component {
    componentDidUpdate() {
        const { user } = this.props;
    }

    render() {
        const { user, history, classes } = this.props;
        return (
            <div className="container">
                <div className="flex">
                    <div className="container">
                        <h2>About US</h2>
                            <div className="">
                                <h4>Over Two Decades of Experience</h4>
                                Over two decades of experience, serving customers all over 5 boroughs of New York City, Long Island, New Jersey and Connecticut 

                                <h4>Service like no other</h4>
                                As Electrical Services Marketplace, with GetElectrician.com customers can post their electrical job on our portal, and with a click of a button receive proposals directly from Electrical providers servicing their areas.  Customers can then obtain more information by going back and forth using our state-of-the-art platform, select final electrical provider, have electrical provider complete the job and lastly provide ratings of service provided to help other customers in the future
                                <Link 
                                    variant="contained" 
                                    component="span" 
                                    href="howitworks"
                                    // onClick={() => onResetClick(history)}
                                    className={clsx(classes.wide, 'flex')}>
                                    HOW IT WORKS 
                                </Link>

                                <h4>Customer Satisfaction is Our Goal</h4>
                                Our focus is to ensure our customer’s satisfaction.  We welcome all feedback which we use to continuously improve our service 

                                <h4>Constant Improvements</h4>
                                At GetElectrician.com, we constantly make improvements to services we provide based on feedback received from our customers.  No matter how small the feedback, we always take it very seriously and welcome all feedback.  
                            <Link 
                                variant="contained" 
                                component="span" 
                                href="contact"
                                // onClick={() => onResetClick(history)}
                                className={clsx(classes.wide, 'flex')}>
                                Help and Support
                            </Link>

                            </div>
                        <footer>
                            <p className="pull-right"><a href="#howitworks">Back to top</a></p>
                        </footer>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    null,
)(withStyles(styles)(AboutUsContainer));
