import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import MessageIconSvg from '../../icons/MessageIconSvg';
import EditIconSvg from '../../icons/EditIconSvg';
import DeleteIconSvg from '../../icons/DeleteIconSvg';
import ProposalScopeListContainer from '../containers/ProposalScopeListContainer';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%',
        overflowX: 'auto',
        padding: '10px 16px 10px 16px',
        marginBottom: '6px',
    },
    table: {
        width: "100%",
        minWidth: 700,
    },
    th: {
        paddingLeft: '2px',
        paddingRight: '2px',
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    scope: {
        backgroundColor:'#F2F2F2',
        margin: theme.spacing.unit,
        fontSize: '12px',
        
    },
    tableActions: {
        display: 'flex',
    },
    tableIcon: {
        marginLeft: '4px',
        marginRight: '4px',
        padding: '0',
    },  
    heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  panelDetails: {
    padding: '0px 10px 10px 20px',
  },
  subLabel: {    
        fontWeight: 700,
        paddingRight: '6px',
        fontSize: '13px',
  },
  heavy: {
      fontWeight: 600,
  }, 
  boarder: {
    borderTop: 'solid 1px #3cb63c',
    borderBottom: 'solid 2px #3cb63c'
  }, 
  proposal: {
      fontSize: '18px',
      fontWeight: '600',
      color: '#3cb63c'
  },
  select: {
    display: 'inline-flex',
    fontSize: '18px',
    border: 'solid 1px #c2c2c2',
    borderRadius: '18px',
    backgroundColor: '#3cb63c',
    height: '45px',
    margin: '10px 50px'
  },
  selectContent: {
    padding: '6px 25px 6px 25px',
  },
  proposalTitle: {
      display: 'flex',
      justifyContent: 'space-between',
  },
});

const ProposalList = (props) => {
    const {
        classes,
        history,
        user,
        proposals,
        detailsTrs,
        isDashboardView,
        isSelect,
        projectStatus,
        handleClickMessageDialogOpen,
        handleProposalDeleteClick,
        handleSelectSave,
    } = props;

    const abledDelete = projectStatus !== 'SELECTING' && projectStatus !== 'CLOSED' 
                            && projectStatus !== 'SELECTED' && projectStatus !== 'ARCHIVED' && projectStatus !== 'COMPLETED' ;
    const abledEdit = (abledDelete && user.userType.includes('p')) ? true: false;
    const showSelect = (projectStatus == 'CREATED' || projectStatus == 'UPDATED' || projectStatus == 'REOPENED') && !user.userType.includes('p');
    const showWriteReview = (!user.userType.includes('p')) && (projectStatus === 'SELECTED' || projectStatus === 'COMPLETED') ;

    return (
        <div className={classes.root}>
            <label className={clsx(classes.heavy)}>Proposals</label>
            {proposals.map(row => (
                <div className={clsx(classes.row, 'pl-20', classes.boarder)} key={row.id}>
                    {isDashboardView &&
                        <div className="project" align="left">{row.project}
                            <IconButton aria-label="Message" className={classes.tableIcon} onClick={() => handleClickMessageDialogOpen(row.providerDisplayName, row.providerId, row.projectId)}>
                                <MessageIconSvg />
                            </IconButton>
                            {abledDelete && <IconButton aria-label="Delete" className={classes.tableIcon} onClick={() => handleProposalDeleteClick(row.id)}>
                                <DeleteIconSvg />
                            </IconButton>}
                        </div>
                    }
                    {!isDashboardView &&
                        <div className={classes.proposalTitle}>
                                <label className={classes.subLabel}>
                                    <span className={classes.proposal}>{row.providerDisplayName}</span> 
                                </label>
                                <ButtonGroup className="flex">
                                    {/*{abledEdit && <IconButton aria-label="Edit" className={classes.margin} fontSize="small" onClick={() => { history.push(`/editProposal/${row.projectId}`) }}>
                                        <EditIconSvg />
                                    </IconButton>}
                                    {abledDelete && <IconButton aria-label="Delete" className={classes.tableIcon} onClick={() => handleProposalDeleteClick(row.id)}>
                                        <DeleteIconSvg />
                                    </IconButton>} */}
                                    {!abledEdit && <Button
                                        onClick={() => handleClickMessageDialogOpen(row.providerDisplayName, row.providerId, row.projectId)}
                                        classes={{
                                            label: classes.label, 
                                        }}
                                    >
                                    Send Message
                                    </Button>}
                                    {showSelect && <Button
                                        onClick={() => { handleSelectSave(history, row.id, row.projectId, row.providerId, row.scopes)}}
                                        classes={{
                                            label: classes.label, 
                                        }}
                                    >
                                    Select
                                    </Button>}
                                    {showWriteReview && <Button
                                        onClick={() => { history.push(`/writeReview/${row.projectId}/${row.providerId}`)}}
                                        classes={{
                                            label: classes.label, 
                                        }}
                                    >
                                    Write Review
                                    </Button>}
                                    {abledEdit && <Button
                                        href={'/editProposal/' + row.projectId}
                                        classes={{
                                            label: classes.label, 
                                        }}
                                    >
                                    Edit
                                    </Button>}
                                    {abledDelete && <Button
                                        onClick={() => handleProposalDeleteClick(row.id)}
                                        classes={{
                                            label: classes.label, 
                                        }}
                                    >
                                    Delete
                                    </Button>}
                                </ButtonGroup>
                        </div>
                    }

                    <div className="" align="left">
                        {/* {row.scopes.map(addedScope => (
                            <div>
                                <Button variant="outlined" size="small" color="primary" className={classes.scope}>
                                    {JSON.stringify(addedScope)}
                                </Button>
                            </div>
                        ))} */}
                        <div className="" align="left"><label className={classes.subLabel}>Total cost</label> {row.total}</div>
                        <div className="" align="left"><label className={classes.subLabel}>Estimated Time</label> {row.estimatedTime}</div>
                        <div className="" align="left"><label className={classes.subLabel}>Start date</label> {row.startDate}</div>
                        <div className="" align="left"><label className={classes.subLabel}>Create Date</label> {row.createdDate} | <label className={classes.subLabel}>Update date </label> {row.updateDate}</div>
                        {!isDashboardView &&
                            <div className="" align="left"><label className={classes.subLabel}>Comment</label> {row.comments}</div>
                        }
                    </div>
                    {row.scopeDetailList && 
                        <ProposalScopeListContainer scopeDetailList={row.scopeDetailList} isEdit={false} />
                    }
                </div>
            ))}
        </div>
    );
};

export default withStyles(styles)(ProposalList);

