import { connect } from 'react-redux';
import { updateCompanyValue } from '../../actions/register';
import { fetchSaveCompany } from '../../actions/api';
import SaveCompany from '../components/SaveCompany';

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.ee.user,
        register: state.register,
        history: ownProps.history,
    }
};

const mapDispatchToProps = dispatch => ({
    handleChange: (name, value) => {
        dispatch(updateCompanyValue(name, value));
    },
    handleSaveCompany: (history) => {
        dispatch(fetchSaveCompany(history));
    },
    handleCancelhandleSaveCompany: () => {
        console.log('cancel company'); //TODO
    },
    handleRemoveCompany: () => {
        console.log('remove company'); //TODO
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SaveCompany);
