import { connect } from 'react-redux';
import { updateAddressValue } from '../actions/register';
import { fetchUpdateAddress } from '../actions/api';
import Address from '../components/Address';

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.ee.user,
        register: state.register,
        isUpdate: ownProps.isUpdate,
        history: ownProps.history,
    }
};

const mapDispatchToProps = dispatch => ({
    handleChange: (name, value) => {
        dispatch(updateAddressValue(name, value));
    },
    handleUpdateAddress: (history) => {
        dispatch(fetchUpdateAddress(history));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Address);
