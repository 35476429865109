import * as React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WrapperContainer from '../containers/WrapperContainer';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const Root = ({ store }) => {
    toast.configure({
        autoClose: 3000,
        draggable: false,
        hideProgressBar: true,
        closeOnClick: true,
        rtl: false,
        pauseOnHover: true,
        position: "top-center"
    });

    return (    
        <Provider store={store} >
            <section id="gete" className="gete">
                <WrapperContainer history={history} />
            </section>
        </Provider >
    );
};

Root.propTypes = {
    store: PropTypes.shape({
        view: PropTypes.exact({
            user: PropTypes.string,
            }),
    }).isRequired,
};

export default Root;
