import React, { Component } from 'react';
import { connect } from 'react-redux';
import  { Redirect } from 'react-router-dom'
import EditProject from '../components/EditProject';
import { getProject } from '../../selectors/ee';
import { updateProjectValue, addCustomScope } from '../../actions/ee';
import { updateCustomScopeField } from '../../actions/view'
import { fetchGetProject, fetchSaveProject, fetchAllScopes } from '../../actions/api';

const mapStateToProps = (state, ownProps) => {
    const projectId = (ownProps.match.params.projectId !== undefined) ? ownProps.match.params.projectId : null;
    console.log('EProject state', state);
    return {
        user: state.ee.user,
        projectId,
        project: getProject(state),
        scopes: state.view.scopes,
        customScopeFieldEnabled: state.view.project.customScopeFieldEnabled,
        history: ownProps.history,
    }
};

const mapDispatchToProps = dispatch => ({
    getProjectData: (projectId) => {
        dispatch(fetchGetProject(projectId));
    },
    getScopeData: (history) => {
        dispatch(fetchAllScopes(history));
    },
    handleChange: (name, event) => {
        dispatch(updateProjectValue(
            name, 
            event.target.value
        ));
    },
    handleWorkDayChange: (name, value) => {
        dispatch(updateProjectValue(
            name, 
            !value
        ));
    },
    handleScopeChange: (selectionList) => {
        let projectScopes = Array.isArray(selectionList) ? selectionList : [];
        projectScopes = projectScopes.map((selection) => {
            if(selection.label === 'other') {
                dispatch(updateCustomScopeField(true))
            }
            return selection.value;
        })
        projectScopes = projectScopes.filter((selection) => selection.name != 'other');
        dispatch(updateProjectValue(
            'scopes',
            projectScopes,
        ));
    },
    handleAddCustomScope: () => {
        dispatch(addCustomScope())
    },
    onSaveClick: (history) => {
        dispatch(fetchSaveProject(history));
    }
});

class EditProjectContainer extends Component {
    componentDidMount() {
        if (this.props.projectId && this.props.projectId !== 'new') {
            this.props.getProjectData(this.props.projectId);
        }
        if (!this.props.scopes || this.props.scopes.length === 0) {
            this.props.getScopeData(this.props.history);
        }
    }

    componentDidUpdate(newProps) {
        if (newProps.projectId !== this.props.projectId && this.props.projectId !== 'new') {
            this.props.getProjectData(newProps.projectId);
        }
        if (!this.props.scopes || this.props.scopes.length === 0) {
            this.props.getScopeData(this.props.history);
        }
    }

    render() {
        // TODO: behavior is weird. Fix this with different way
        // if (this.props.user.type !== 'c' && this.props.user.type !== 'a'
        // && this.props.user.userType !== 'c' && this.props.user.userType !== 'a') {
        //     return (
        //         <Redirect to='/dashboard' />
        //     );
        // }
        return (
            <section id="gete-project">
                <EditProject
                    project={this.props.project} 
                    scopes={this.props.scopes}
                    user={this.props.user}
                    history={this.props.history} 
                    customScopeFieldEnabled={this.props.customScopeFieldEnabled}
                    handleChange={this.props.handleChange}
                    handleWorkDayChange={this.props.handleWorkDayChange}
                    handleScopeChange={this.props.handleScopeChange}
                    handleAddCustomScope={this.props.handleAddCustomScope}
                    onSaveClick={() => {this.props.onSaveClick(this.props.history)}}
                />
            </section>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditProjectContainer);
