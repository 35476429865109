import { connect } from 'react-redux';
import { updateProfileImageFile } from '../../actions/register';
import { fetchSaveProfileImage } from '../../actions/api';
import ProfileImage from '../components/ProfileImage';

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.ee.user,
        profileImage: state.register.profileImage,
        history: ownProps.history,
    }
};

const mapDispatchToProps = dispatch => ({
    handleChange: (event) => {
        const value = event.target.value;
        dispatch(updateProfileImageFile(value));
    },
    handleUpdateProfileImage: () => {
        dispatch(fetchSaveProfileImage());
    },
    onDrop: (files) => {
        const value = files[0];
        dispatch(updateProfileImageFile(value));
    },
    onChange: (imageList, addUpdateIndex) => {
        dispatch(updateProfileImageFile(imageList[0]));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileImage);
