import { connect } from 'react-redux';
import { updatePhoneValue } from '../actions/register';
import Phone from '../components/Phone';
import { fetchUpdatePhone } from '../actions/api'

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.ee.user,
        register: state.register,
        history: ownProps.history,
        isUpdate: ownProps.isUpdate,
    }
};

const mapDispatchToProps = dispatch => ({
    handleChange: (value) => {
        dispatch(updatePhoneValue(value));
    },
    handleUpdatePhone: (history) => {
        dispatch(fetchUpdatePhone(history));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Phone);
