import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateRegisterValue } from '../../actions/register';
import { fetchSaveRegisterProfile, fetchProfile } from '../../actions/api';
import Profile from '../components/Profile';

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.ee.user,
        register: state.register,
        history: ownProps.history,
        isUpdate: ownProps.isRegister ? false : true,
    }
};

const mapDispatchToProps = dispatch => ({
    getProfile: (history) => {
        dispatch(fetchProfile(history));
    },
    handleChange: (name, value) => {
        dispatch(updateRegisterValue(name, value));
    },
    handleRegister: (history) => {
        dispatch(fetchSaveRegisterProfile(history));
    },
    handleCancelRegister: (history) => {
        history.push('/');
    },
    handleTermsOfUse: () => {
        dispatch(updateRegisterValue('termsOfUse'));
    },
    handleTermsOfUseProvider: () => {
        dispatch(updateRegisterValue('termsOfUseProvider'));
    },
    handleCodeOfEthics: () => {
        dispatch(updateRegisterValue('codeOfEthics'));
    }
});

class ProfileContainer extends Component {
    componentDidMount() {
        this.props.getProfile(this.props.history);
    }
    
    render() {
        return (
            <section id="gete-profile" className="gete-profile">
                <Profile 
                    user={this.props.user}
                    register={this.props.register}
                    history={this.props.history}
                    isUpdate={this.props.isUpdate} 
                    handleChange={this.props.handleChange}
                    handleRegister={this.props.handleRegister}
                    handleCancelRegister={this.props.handleCancelRegister}
                    handleTermsOfUse={this.props.handleTermsOfUse}
                    handleTermsOfUseProvider={this.props.handleTermsOfUseProvider}
                    handleCodeOfEthics={this.props.handleCodeOfEthics}
                />
            </section>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileContainer);
