import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Select from 'react-select'
import moment from 'moment';
import Container from '@material-ui/core/Container';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%',
    },
    formBox: {
        padding: '0px 20px 10px 20px',
    },
    wide: {
        width: '100%',
        maxWidth: '340px',
    },
    narrow: {
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    margin: {
        marginLeft: '4px',
        marginRight: '4px',
    },
    margin20: {
        marginBottom: '20px',
    },
    paddingBottom: {
        paddingBottom: '16px',
    },
    flex: {        
        display: 'flex',
    },
    marginLeft: {
        marginLeft: '4px',
    },
    subLabel: {
        fontWeight: 600, 
    }
});

const SaveWorkZone = (props) => {
    const {
        classes,
        user,
        register,
        history,
        isUpdate,
        handleWorkZoneState,
        handleWorkZoneCounty,
        handleSaveWorkZone,
    } = props;

    const workzone = register.workzone;
    
    const workzoneNY = register.workzone.NY;
    const workzoneNJ = register.workzone.NJ;

    // const workzoneNY = register.workzone.NY.map(county => ({ 
    //     value: county,
    //     label: county
    // }));
    // const workzoneNJ = register.workzone.NJ.map(county => ({ 
    //     value: county,
    //     label: county
    // }));

    const workZoneNYCountyOptions = register.workZoneCounties['NY'].map(county => ({ 
        value: county,
        label: county
    }));
    const workZoneNJCountyOptions = register.workZoneCounties['NJ'].map(county => ({ 
        value: county,
        label: county
    }));


    const submitDisabled = workzone == undefined || (workzoneNY && workzoneNY.length < 1 && workzoneNJ < 1);
    const defaultExpectStartDate = moment().format("YYYY-MM-DD")

    return (
        <Container maxWidth="lg">
            <div className={classes.root} >
                <InputLabel 
                    htmlFor="phone"
                    className={clsx(classes.subLabel, 'ee-color')}
                >Work Zones</InputLabel>    
                <div className={clsx(classes.margin20)}>
                    <div className={classes.paddingBottom}>
                        <InputLabel htmlFor="workhour-county">Please select New York county(s)</InputLabel>
                        <Select
                            required
                            isMulti
                            isClearable={false}
                            name="workhour-county-ny"
                            defaultValue={[workZoneNYCountyOptions[0]]}
                            value={workzoneNY}
                            options={workZoneNYCountyOptions}
                            classNamePrefix="select"
                            className={classes.wide}
                            onChange={(selection) => {
                                handleWorkZoneCounty('NY', selection);
                            }}
                        />
                    </div>
                    <div className={classes.paddingBottom}>
                        <InputLabel htmlFor="workhour-county">Please select New Jersey county(s)</InputLabel>
                        <div>
                            <Select
                                required
                                isMulti
                                isClearable={false}
                                name="workhour-county-nj"
                                defaultValue={[workZoneNJCountyOptions[0]]}
                                value={workzoneNJ}
                                options={workZoneNJCountyOptions}
                                className={classes.wide}
                                classNamePrefix="select"
                                onChange={(selection) => {
                                    handleWorkZoneCounty('NJ', selection);
                                }}
                            />
                        </div>
                    </div>
                    <Button
                        disabled={submitDisabled}
                        variant="contained" 
                        component="span" 
                        color="green"
                        onClick={() => handleSaveWorkZone(history)}
                        className={submitDisabled ? clsx(classes.wide, classes.marginLeft) : clsx(classes.wide, 'ee-bg-color', classes.marginLeft)}>
                        Save work zone
                    </Button>                    
                </div>
            </div>
        </Container>
    );
};

export default withStyles(styles)(SaveWorkZone);

