import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Container from '@material-ui/core/Container';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%'
    },
    flex: {        
        display: 'flex',
    },
    formBox: {
        padding: '0px 20px 10px 20px',
    },
    wide: {
        width: '100%',
        maxWidth: '340px'
    },
    narrow: {
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    margin: {
        marginLeft: '4px',
        marginRight: '4px',
    },
    marginLeft: {
        marginLeft: '4px',
    },
    margin20: {
        marginBottom: '20px',
    },
    zIndex0: {
        zIndex: 0,
    }, 
    subLabel: {
        fontWeight: 600, 
    }
});

const Phone = (props) => {
    const {
        classes,
        user,
        register,
        isUpdate,
        history,
        handleChange,
        handleUpdatePhone,
    } = props;

    const registerError = register.error;
    const phone = register.phone;
    const submitDisabled = phone == undefined;

    return (
        <Container maxWidth="lg">
            <div className={classes.root} >
                <InputLabel 
                    htmlFor="phone"
                    className={clsx(classes.subLabel, 'ee-color')}
                >Phone</InputLabel>
                <div className={clsx(classes.margin20, classes.zIndex0)}>
                    <TextField
                        required
                        id="phone"
                        label="Phone"
                        value={register.phone}
                        onChange={(event) => handleChange(event.target.value)}
                        className={clsx(classes.wide, classes.margin, classes.zIndex0, classes.flex)}
                        margin="normal"
                        variant="outlined"
                    />
                </div>
                {isUpdate && 
                    <div className={clsx(classes.wide, classes.margin20)}>      
                        <Button
                            disabled={submitDisabled}
                            variant="contained" 
                            component="span" 
                            color="green"
                            onClick={() => handleUpdatePhone(history)}
                            className={submitDisabled ? clsx(classes.wide, classes.marginLeft) : clsx(classes.wide, 'ee-bg-color', classes.marginLeft)}
                        >
                            Save phone
                        </Button>
                    </div>
                }
            </div>
        </Container>
    );
};

export default withStyles(styles)(Phone);

