import * as types from '../constants/actionTypes';

export const updateRegisterValue = (name, value) => ({
    type: types.UPDATE_REGISTER_VALUE,
    name,
    value,
});

export const updateAccountActivation = (accountActivation) => ({
    type: types.UPDATE_ACCOUNT_ACTIVATION_STATUS,
    accountActivation,
})

export const updatePasswordValue = (name, value) => ({
    type: types.UPDATE_PASSWORD_VALUE,
    name,
    value,
})

export const updateTokenEmail = (token, queryStr) => ({
    type: types.UPDATE_TOKEN_EMAIL,
    token,
    queryStr,
})